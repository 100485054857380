import React, { Component } from 'react'
import './style.css'
import MapHeader from '../../components/mapHeader'
import Timeline from '../../components/timeline'
import LeftSide from '../../components/leftSide'
import RightSide from '../../components/rightSide'
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl'
import {
    mapRequirements,
    mapStyles,
    timeLineOptions,
    typesNumbers,
} from '../../shared/mockData'
import { HandleToken, LogIn } from '../../helpers'
import * as vis from 'vis-timeline'
import ArticleDataService from '../../service/ArticleDataService'
import {
    getCategoriesData,
    getFilteredCategoriesData,
    getIsTimeLineLoading, getLampData,
    getMapStylesData,
    getMapZoomData,
    getNextLessonIDData,
    getPrevLessonIDData,
    getSelectedArticleData,
    getSelectedLessonId,
    getSelectedMapRequirements,
    getSelectedMapStyle,
    getTimeLineGroupsData,
    getTimeLineItemsData,
} from '../../store/selectors'
import {
    getCategoriesFromAPI,
    getFilteredCategoriesFromAPI,
    updateCategoriesToggle,
} from '../../store/actions/categories'
import { connect, useDispatch } from 'react-redux'
import {
    _filterByFeatureId,
    addAndPositionLayer,
    addArticlePoint,
    addPoint,
    addSymbol,
    determinateURLParams,
    determineCatIds,
    extractId,
    formatServerResponseTime,
    generateQueryString,
    generateTimeLineQueryString,
    getData,
    getSelectedYear,
    getShowByType,
    isPoint,
    isSymbol,
    mapUtilities,
    subCatFilterLogic,
} from './utils'
import { getLampInfo } from '../../store/actions/lamp'
import { setMapZoom } from '../../store/actions/zoom'
import { setTimeLineExpend } from '../../store/actions/timeLineExpend'
import {
    getMapChangeRequest,
    mapLessonAPIRequest,
    mapLiveAPIRequest,
    mapPreviewAPIRequest,
    mapPreviewTimeAPIRequest,
} from './utils/mapPreviewMode'
import { getArticleFromAPI } from '../../store/actions/articles'
import {
    setNextLessonID,
    setPrevLessonID,
    setSelectedLesson,
} from '../../store/actions/handleLesson'
import {
    setTimeLineLessonData,
    setTimeLineTopicsData,
    updateTimeLineGroupToggle,
} from '../../store/actions/timeLine'
import lampIcon from "../../assets/imgs/lamp-icon.svg";

mapboxgl.accessToken = process.env.REACT_APP_MAP_TOKEN
const queryParams = new URLSearchParams(window.location.search)

const zeroingItems = {
    toggle: true,
    menuExpanded: true,
    bounds: true,
    activeCatId: true,
    activeSubCatId: true,
    categories: true,
    allCategories: true,
    zoom: true,
    articlePopup: true,
    articleCollaps: true,
    timeLineSelectedDataItems: true,
    timeLineSelectedDataGroup: true,
}
let initialState
const debounce = (fn, wait) => {
    let timeoutID
    return (...arg) => {
        if (timeoutID) clearTimeout(timeoutID)
        timeoutID = setTimeout(() => fn(...arg), wait)
    }
}
const debeounced2000 = debounce((fn) => fn(), 1000)

class Map extends Component {
    constructor(props) {
        super(props)
        this.state = {
            globalLoading: true,
            url: {
                lessonID: queryParams.get('lessonID'),
                topicID: queryParams.get('topicID'),
                gradeID: queryParams.get('gradeID'),
                subjectID: queryParams.get('subjectID'),
                articleID: queryParams.get('articleID'),
                preview: queryParams.get('preview'),
                type: queryParams.get('type'),
            },
            bounds: {},
            toggleMapMode: 'all',
            // Map
            firstLayer: null,
            mapData: null,
            geoJsonData: null,
            loadFirst: true,
            defaultLayers: [],
            addedLayers: [],
            addedFeautures: [],
            firstGeoJsonPath: null,
            selectedStyleId: 1,
        }
        this.map = React.createRef()
        this.mapContainer = React.createRef()
        this.timeLine = React.createRef()
        this.mapUtilities = mapUtilities(this.map, this.timeLine)
    }
    async componentDidMount() {
        await LogIn()
        if (this.props.categories.length === 0) {
            this.props.getCategoriesFromAPI()
        }
        this.setupMapFirstView()
        if (this.state.url.preview) {
            if (this.state.url.type === 'article') {
                this.processArticlePreview()
            } else {
                this.processMapPreviewMode()
            }
        } else {
            await HandleToken()
            const { id, type } = determinateURLParams(this.state.url)
            console.log (id, type,'id, type')

            if (type === 'article') {
                window.location.href = `/`
            }
            this.mapLiveView(id, type)
        }
    }
    async componentDidUpdate() {
        if (
            !this.state.timeLineLoading &&
            !this.state.articleLoading &&
            !this.state.loadFirst &&
            this.state.globalLoading &&
            !initialState &&
            Object.keys(this.state.bounds).length > 0
        ) {
            if (!!this.state.url.lessonID) {
                if (
                    this.state.timeLineSelectedDataItems.length > 0 &&
                    this.state.timeLineSelectedDataGroup.length > 0
                )
                    // this.setState( {initialState:this.handleSaveIntial(this.state)})
                    initialState = JSON.parse(JSON.stringify(this.state))
                this.setState({ globalLoading: false })

                this.timeLine.current.on("mouseDown", (event) => {
                  if (event.y > 130 || (this.state.timelineExtend && event.y > 36)) {
                      this.timeLine.current.removeCustomTime('t1')
                      this.timeLine.current.addCustomTime(event.time, 't1')
                    this.handleTimeChange(event.time, true);
                  } else {
                    this.selectLesson(event);
                  }
                });

                console.log('initialState REND')
            } else {
                this.setState({ globalLoading: false })
            }
        }
    }

    processMapPreviewMode = () => {
        const { newObj, type } = generateQueryString(this.state.url)
        if (Object.keys(newObj).length !== 0) {
            const queryString = Object.keys(newObj)
                .map((key) => key + '=' + newObj[key])
                .join('&')

            mapPreviewAPIRequest(queryString).then(async (resData) => {
                const catIds = determineCatIds(resData, type)
                this.props.getFilteredCategoriesFromAPI(resData, catIds, type)
                this.props.getLampInfo(resData, type)
                const { startFrom, endTo } = formatServerResponseTime(resData)
                this.setState({ mapData: resData })
                if (this.state.toggleMapMode === 'all') {
                    let body = {
                        id: resData.id,
                        geoJsonPath: resData.geoJsonPath,
                    }
                    await this.addNotCountriesLayers(body)
                }
                this.processTimeLineRendering(resData, type, false)
                this.handleTimeChange(startFrom)
            })
        } else {
            window.location.href = `/map`
        }
    }

    processArticlePreview = () => {
        const { newObj, type } = generateQueryString(this.state.url)
        if (Object.keys(newObj).length !== 0) {
            const queryString = Object.keys(newObj)
                .map((key) => key + '=' + newObj[key])
                .join('&')

            mapPreviewAPIRequest(queryString).then(async (resData) => {
                this.setState({
                    mapData: resData,
                    geoJsonPath: resData.cordinates[0].path,
                })
                this.props.getArticleFromAPI(resData.id)
                this.props.setTimeLineExpend(false)
                const { startFrom, endTo } = formatServerResponseTime(resData)
                this.setState({ mapData: resData })
                this.handleTimeChange(startFrom)

                this.setCurrentOnTimeline({ start: startFrom })
                this.reactToTimeChange()
                this.timeLine.current.setOptions({
                    minHeight: '80px',
                })

                this.setTimeLineOptions({
                    start: startFrom,
                    end: endTo,
                })

                this.setState({
                    loadFirst: true,
                })
                this.addArticleLayer(resData)
                this.timeLine.current.redraw()

            })
        } else {
            window.location.href = `/map`
        }
    }

    mapLiveView = (id, type) => {
        mapLiveAPIRequest(id, type).then(async (resData) => {
            const catIds = determineCatIds(resData, type)
            this.props.getFilteredCategoriesFromAPI(resData, catIds, type)
            this.props.getLampInfo(resData, type)
            const { startFrom, endTo } = formatServerResponseTime(resData)
            this.setState({ mapData: resData })
            if (this.state.toggleMapMode === 'all') {
                let body = {
                    id: resData.id,
                    geoJsonPath: resData.geoJsonPath,
                }
                await this.addNotCountriesLayers(body)
            }
            this.processTimeLineRendering(resData, type, false)
            this.handleTimeChange(startFrom)
        })
    }

    handleTimeChange = (time) => {
        const year = getSelectedYear(time, this.timeLine)
        const newObj = generateTimeLineQueryString(
            year,
            this.state.url,
            this.props.selectedLesson,
            this.state.mapData.id
        )
        const _doesExist = (item) => this.map.current.getLayer(item.id)
        const _removeLayersFromMap = (item) => {
            this.map.current.removeLayer(item.id)
            if (document.getElementById(item.id)) {
                document.getElementById(item.id).style.visibility = 'hidden'
            }
        }

        if (Object.keys(newObj).length !== 0) {
            const queryString = Object.keys(newObj)
                .map((key) => key + '=' + newObj[key])
                .join('&')

            getMapChangeRequest(queryString, this.state.url.preview).then(
                async (response) => {
                    const resData = response.data.data[0]
                    if (!response.data.accepted || !resData) {
                        this.state.addedLayers
                            .filter(_doesExist)
                            .forEach(_removeLayersFromMap)
                    }
                    let body = {
                        id: this.state.mapData.id,
                        geoJsonPath: resData,
                    }
                    if (
                        this.state.url.type === 'article' &&
                        !this.state.loadFirst
                    ) {
                        this.state.geoJsonData.layers
                            .filter(_doesExist)
                            .forEach(_removeLayersFromMap)
                    }
                    this.addCountriesLayers(body)
                }
            )
        }
    }

    // Start
    // TimeLine rendering
    processTimeLineRendering = async (data, type, fromTopic) => {
        if (type === 'lesson') {
            if (fromTopic === false && this.props.selectedLesson === null) {
                this.props.setSelectedLesson(this.state.url.lessonID)
            }
            this.props.setTimeLineLessonData(
                this.state.mapData.id,
                data.id,
                data.articles,
                fromTopic
            )
        }
        if (type === 'topic') {
            this.props.setTimeLineTopicsData(data)
        }
    }

    processSelectedWhateverView = (data) => {
        this.timeLine.current.on('click', this.handleTimeLineClickAction)
        this.setCurrentOnTimeline(data)
        this.setTimeLineOptions(data)
        this.reactToTimeChange()
    }

    setTimeLineOptions = (data) => {
        this.timeLine.current.setOptions({
            start: data.start,
            end: data.end,
            min: data.start,
            max: data.end,
        })
    }

    reactToTimeChange = () => {
        this.timeLine.current.redraw()
        this.timeLine.current.on('timechanged', (properties) => {
            let time = properties.time
            this.handleTimeChange(time)
        })
    }

    setCurrentOnTimeline = (data) => {
        const customDate = data.start
        const customBar = document.querySelector('.t1')
        customBar === null
            ? this.timeLine.current.addCustomTime(customDate, 't1')
            : this.timeLine.current.setCustomTime(customDate, 't1')
    }
    // End

    // Start
    // Process layers to the map
    addArticleLayer = (resData) => {
        getData(resData.cordinates[0].path).then((data) => {
            this.setState({ defaultLayers: data.layers })
            this.map.current.addSource(resData.id, {
                type: 'geojson',
                data: resData.cordinates[0].path,
            })
            const _addLayer = (layer) => this.map.current.addLayer(layer)

            const _addSymbol = addSymbol(
                this.props.categories,
                this.map.current,
                this.props.getArticleFromAPI
            )

            data.layers.forEach(_addLayer)
            data.layers.forEach(_addSymbol)

            if (data.features[0].geometry.type === 'Point') {
                addArticlePoint(this.map.current, data)
            }
            const center = data?.center
                ? data?.center[0] !== 'NaN'
                    ? data?.center
                    : [0, 0]
                : [0, 0]
            let zoom = this.state.mapData?.zoom
                ? this.state.mapData?.zoom
                : null
            this.map.current.flyTo({
                center: center,
                zoom: zoom,
            })
        })
    }

    addNotCountriesLayers = async (sources) => {
        getData(sources.geoJsonPath).then(async (data) => {
            if (data.layers.length > 0) {
                this.setState({
                    firstGeoJsonPath: sources.geoJsonPath,
                    addedFeautures: data.features,
                })
                await this.addSourcesToMap(sources)

                const firstLayerID = data.layers.find(
                    (x) => !x.id.includes('country')
                )
                this.setState({ firstLayer: firstLayerID?.id })

                const _doesNotExistCountry = (item) =>
                    !item.id.includes('country')
                const _doesNotExistIn = (layers) => (item) => {
                    let findDataInMap = layers?.find((x) => x.id === item.id)
                    return findDataInMap === undefined
                }

                const addToMap = data.layers
                    .filter(_doesNotExistCountry)
                    .filter(_doesNotExistIn(this.state.geoJsonData?.layers))

                const removeFromMap =
                    this.state.geoJsonData !== null
                        ? this.state.geoJsonData.layers.filter(
                              _doesNotExistIn(data.layers)
                          )
                        : []

                if (removeFromMap.length > 0) {
                    this.removeLayerFromMap(removeFromMap)
                }

                if (addToMap.length > 0) {
                    this.setState({ defaultLayers: addToMap })
                    this.addLayerToMap(addToMap, data)
                }

                if (this.state.url.type !== 'article') {
                    setTimeout(() => {
                        const bounds = new mapboxgl.LngLatBounds(
                            data.centers[0],
                            data.centers[0]
                        )
                        for (const cord of data.centers) {
                            bounds.extend(cord)
                        }
                        this.map.current.fitBounds(bounds, {
                            padding: 250,
                        })
                    }, 500)
                }
                this.setState({
                    geoJsonData: data,
                    loadFirst: false,
                })
            }
        })
    }

    addCountriesLayers = async (sources) => {
        if (this.map.current.getSource(sources.id)) {
            this.map.current.getSource(sources.id).setData(sources.geoJsonPath)
        } else {
            this.map.current.addSource(sources.id, {
                type: 'geojson',
                data: sources.geoJsonPath,
            })
        }
        getData(sources.geoJsonPath).then(async (data) => {
            if (data.layers.length > 0) {
                const includesCountry = (item) => item.id.includes('country')
                const doesNotExistIn = (layers) => (oldItem) => {
                    let findDataInMap = layers.find((x) => x.id === oldItem.id)
                    return findDataInMap === undefined
                }

                const addToMap = data.layers.filter(includesCountry)

                const removeFromMap =
                    this.state.geoJsonData !== null
                        ? this.state.geoJsonData?.layers
                              .filter(includesCountry)
                              .filter(doesNotExistIn(data.layers))
                        : []

                if (removeFromMap.length > 0) {
                    this.removeLayerFromMap(removeFromMap)
                }

                if (addToMap.length > 0) {
                    this.setState({ addedLayers: addToMap })
                    this.addLayerToMap(addToMap, data)
                }
            }
        })
    }

    addSourcesToMap = async (sources) => {
        if (this.map.current.getSource(sources.id)) {
            this.map.current.getSource(sources.id).setData(sources.geoJsonPath)
        } else {
            this.map.current.addSource(sources.id, {
                type: 'geojson',
                data: sources.geoJsonPath,
            })
        }
    }

    removeLayerFromMap = (data) => {
        data.forEach((item) => {
            if (this.map.current.getLayer(item.id)) {
                this.map.current.removeLayer(item.id)
            }
            if (document.getElementById(item.id)) {
                document.getElementById(item.id).remove()
            }
        })
    }

    addLayerToMap = async (data, response) => {
        const _addPoint = addPoint(
            this.map.current,
            this.props.getArticleFromAPI,
            response,
            this.props.lampData
        )
        const _addSymbol = addSymbol(
            this.props.categories,
            this.map.current,
            this.props.getArticleFromAPI
        )
        const _addAndPositionLayer = addAndPositionLayer(
            this.map.current,
            this.state.loadFirst,
            this.state.firstLayer
        )
        const _getExistingLayers = (item) => document.getElementById(item.id)

        data.forEach(_addAndPositionLayer)
        data.filter(isSymbol).forEach(_addSymbol)
        data.filter(isPoint).forEach(_addPoint)

        this.map.current.on('zoom', () => {
            const currentZoom = this.map.current.getZoom()
            debeounced2000(() => {
                this.props.setMapZoom(currentZoom)
            })
            data.filter(_getExistingLayers).forEach((item) => {
                if (currentZoom < item.minzoom) {
                    document.getElementById(item.id).style.visibility = 'hidden'
                } else {
                    document.getElementById(item.id).style.visibility =
                        this.map.current.getLayer(item.id)?.visibility
                }
            })
        })
    }
    // End

    // Start
    // Functions where component did update
    componentDidUpdate(prevProps) {
        // console.log('nextProps', prevProps)
        // console.log('this', this.props)
        if (
            prevProps.selectedMapStyle &&
            prevProps.selectedMapStyle !== this.props.selectedMapStyle
        ) {
            this.handleMapStyleChange()
        }
        if (
            prevProps.selectedArticle &&
            prevProps.selectedArticle !== this.props.selectedArticle
        ) {
            this.startPinAnimation(`article_${this.props.selectedArticle.id}`)
            this.handleFlyToObject()
        }
        if (prevProps.mapZoom !== this.props.mapZoom) {
            this.changeMapZoom(this.props.mapZoom)
        }
        if (
            prevProps.selectedMapRequirement &&
            prevProps.selectedMapRequirement !==
                this.props.selectedMapRequirement
        ) {
            this.handleRequirements()
        }
        if (
            prevProps.timeLineLoading !== this.props.timeLineLoading &&
            this.props.timeLineItems.length > 0
        ) {
            this.processSelectedWhateverView(this.props.timeLineItems[0])
        }
        if (
            prevProps.selectedLesson === null &&
            this.props.selectedLesson !== null
        ) {
            this.processLessonMode(this.props.selectedLesson)
        }
    }

    handleRequirements = () => {
        const typeID = this.props.selectedMapRequirement
        let type = null
        if (typeID === 'all') {
            type = 'all'
        }
        if (typeID === 0) {
            type = 'Mandatory'
        }
        if (typeID === 1) {
            type = 'Additional'
        }
        if (this.state.geoJsonData) {
            let data = this.state.geoJsonData
            if (data.layers.length > 0) {
                data.layers.forEach((layer) => {
                    if (layer.ArticleType === type) {
                        this.map.current.setLayoutProperty(
                            layer.id,
                            'visibility',
                            'visible'
                        )
                        if (document.getElementById(layer.id)) {
                            document.getElementById(layer.id).style.visibility =
                                'visible'
                        }
                    } else if (type === 'all') {
                        if (this.map.current.getLayer(layer.id)) {
                            this.map.current.setLayoutProperty(
                                layer.id,
                                'visibility',
                                'visible'
                            )
                        }
                        if (document.getElementById(layer.id)) {
                            document.getElementById(layer.id).style.visibility =
                                'visible'
                        }
                    } else {
                        this.map.current.setLayoutProperty(
                            layer.id,
                            'visibility',
                            'none'
                        )
                        if (document.getElementById(layer.id)) {
                            document.getElementById(layer.id).style.visibility =
                                'hidden'
                        }
                    }
                })
            }
        }
    }

    handleFlyToObject = () => {
        let fly = true
        const flyingObject = this.props.selectedArticle
        if (flyingObject.type === 5) {
            let features = this.map.current.querySourceFeatures(
                this.state.mapData.id,
                {
                    filter: ['==', 'articleId', flyingObject.id],
                }
            )
            if (!features || features.length === 0) {
                fly = false
            }
        }
        if (fly) {
            const feature = this.state.addedFeautures.find(
                (x) => x.properties['articleId'] === flyingObject.id
            )
            if (feature !== undefined) {
                if (flyingObject.type === 0) {
                    this.map.current.flyTo({
                        center: feature.geometry.coordinates,
                    })
                }
                if (flyingObject.type !== 0) {
                    let centers = []
                    centers.push(
                        feature.geometry.type === 'Polygon'
                            ? feature.geometry.coordinates[0]
                            : feature.geometry.coordinates
                    )
                    const bounds = new mapboxgl.LngLatBounds(
                        centers[0][0],
                        centers[0][0]
                    )

                    for (const cord of centers[0]) {
                        bounds.extend(cord)
                    }
                    this.map.current.fitBounds(bounds, {
                        padding: 250,
                    })
                }
            }
        }
    }

    handleMapStyleChange = () => {
        const style = this.props.mapStyles.find((style) => style.active).style
        this.map.current.setStyle(style)
        this.map.current.on('style.load', () => {
            const { defaultLayers, addedLayers } = this.state
            if (defaultLayers.length > 0) {
                const body = {
                    id: this.state.mapData.id,
                    geoJsonPath: this.state.firstGeoJsonPath,
                }
                this.addSourcesToMap(body)
                defaultLayers.forEach((item) => {
                    if (!this.map.current.getLayer(item.id)) {
                        this.map.current.addLayer(item)
                        if (
                            !this.state.loadFirst &&
                            item.id.includes('country')
                        ) {
                            if (this.map.current.getLayer(item.id)) {
                                this.map.current.moveLayer(
                                    item.id,
                                    this.state.firstLayer
                                )
                            }
                        }
                    }
                })
            }
            addedLayers.length > 0 &&
                addedLayers.forEach((item) => {
                    if (!this.map.current.getLayer(item.id)) {
                        this.map.current.addLayer(item)
                        if (
                            !this.state.loadFirst &&
                            item.id.includes('country')
                        ) {
                            if (this.map.current.getLayer(item.id)) {
                                this.map.current.moveLayer(
                                    item.id,
                                    this.state.firstLayer
                                )
                            }
                        }
                    }
                })
        })
    }
    // End

    handleTimeLineClickAction = (e) => {
        if (e.y > 130 || this.state.timelineExtend && e.y > 36) {
            this.timeLine.current.setCustomTime (e.time, 't1')
            this.handleTimeChange (e.time)
        } else {
            if (e.item !== null) {
                let data = this.props.timeLineItems.find((x) => x.id === e.item)
                if (data !== 'undefined' || data !== null) {
                    this.props.setSelectedLesson(Number(e.item.split('-')[1]))
                    this.props.setNextLessonID(e.item)
                    this.props.setPrevLessonID(e.item)
                }
            }
        }
    }

    processLessonMode = (id) => {
        mapLessonAPIRequest(id, this.state.url.preview).then((resData) => {
            const type = 'lesson'
            const catIds = determineCatIds(resData, type)
            this.props.getFilteredCategoriesFromAPI(resData, catIds, type)
            this.props.getLampInfo(resData, type)
            const { startFrom, endTo } = formatServerResponseTime(resData)
            this.setState({ mapData: resData })
            if (this.state.toggleMapMode === 'all') {
                let body = {
                    id: resData.id,
                    geoJsonPath: resData.geoJsonPath,
                }
                this.addNotCountriesLayers(body)
            }
            this.processTimeLineRendering(resData, type, false)
            this.handleTimeChange(startFrom)
        })
    }

    goToTopics = async () => {
        window.location.reload()
        // // this.timeLine.current.destroy()
        // this.props.setSelectedLesson(null)
        // this.props.setTimeLineTopicsData([])
        // this.props.setTimeLineLessonData([])
        // if (this.props.categories.length === 0) {
        //     this.props.getCategoriesFromAPI()
        // }
        // if (this.state.url.preview) {
        //     this.processMapPreviewMode()
        // } else {
        //     const { id, type } = determinateURLParams(this.state.url)
        //     this.mapLiveView(id, type)
        // }
    }

    // Start
    // Toggle Map items from category and subcategory (hide or show)
    toggleItemsFromMapByID = (categoryId, subCategoryId, type) => {
        this.props.updateCategoriesToggle(categoryId, subCategoryId, type)
        this.props.updateTimeLineGroupToggle(categoryId)
        const isShow = getShowByType(
            this.props.filteredCategories,
            categoryId,
            subCategoryId,
            type
        )
        this.toggleItemFromMap(categoryId, type, isShow)
    }

    toggleItemFromMap = (id, type, isShow) => {
        const data = this.state.geoJsonData
        const categories = this.props.filteredCategories
        if (!data || !data.features.length) {
            return
        }
        const _subCatFilterIfNeeded = (feature) => {
            if (type === 'category') {
                return true
            } else {
                return subCatFilterLogic(categories, feature, id, isShow)
            }
        }
        const getFilteredLayerIds = (feature) => {
            return data.layers
                .filter(_filterByFeatureId(feature))
                .map(extractId)
        }
        const layerIds = data.features
            .filter((feature) => feature.properties.categoryId === id)
            .filter(_subCatFilterIfNeeded)
            .map(getFilteredLayerIds)
            .flat()
        this.makeMapChanges(layerIds, isShow)
    }

    makeMapChanges = (layerID, show) => {
        layerID.forEach((item) => {
            this.map.current.setLayoutProperty(
                item,
                'visibility',
                show ? 'visible' : 'none'
            )
            if (document.getElementById(item)) {
                document.getElementById(item).style.visibility = show
                    ? 'visible'
                    : 'hidden'
            }
        })
    }
    // End

    // not movable functions
    startPinAnimation(id) {
        console.log('id', id)
        let mapMarkers = document.getElementsByClassName(
            'mapboxgl-marker-anchor-center'
        )

        for (let i = 0; i < mapMarkers.length; i++) {
            if (mapMarkers[i].getAttribute('class').includes(id)) {
                mapMarkers[i].classList.add('tic_animation')
                mapMarkers[i].style.zIndex = '99'
                continue
            }
            mapMarkers[i].classList.remove('tic_animation')
            mapMarkers[i].style.zIndex = '1'
        }
    }


    setupMapFirstView = async () => {
        this.map.current = await new mapboxgl.Map({
            container: this.mapContainer.current,
            style: this.props.mapStyles.find((x) => x.active).style,
            center: [0, 0],
            zoom: this.props.mapZoom,
            maxPitch:50,
        })
        this.map.current.on('idle', () => {
            setTimeout(() => {
                this.map.current.resize()
            }, 10)
        })
    }

    moveLeft = () => this.move(0.2)

    moveRight = () => this.move(-0.2)
    changeMapZoom = (zoom) => {
        this.map.current.zoomTo(zoom, { duration: 1000 })
    }

    // Timeline
    move = (percentage) => {
        let range = this.timeLine.current.getWindow()
        let interval = range.end - range.start

        this.timeLine.current.setWindow({
            start: range.start.valueOf() - interval * percentage,
            end: range.end.valueOf() - interval * percentage,
        })
    }
    handleChangeToInitialState = () => {
        Object.keys(initialState).forEach((e) => {
            if (zeroingItems[e]) {
                if (
                    JSON.stringify(this.state[e]) !==
                    JSON.stringify(initialState[e])
                ) {
                    this.setState({
                        [e]: JSON.parse(JSON.stringify(initialState[e])),
                    })
                }
            }
        })
        this.handleShowAllCategory()
        if (this.state.selectedStyleId !== this.state.mapStyles[0].id)
            this.handleMapStyleChange(initialState.mapStyles[0].id)
        if (initialState.zoom !== this.state.zoom) {
            this.map.current.fitBounds(this.state.bounds, {
                padding: 250,
            })
            this.map.current?.transform?.resetCompass ()
        }
        this.handleMapRequirements(this.state.toggleMapMode)
        this.handleTimeChange(this.state.timeLineSelectedDataItems[0].start)
        this.timeLine.current.removeCustomTime('t1')
        this.timeLine.current.addCustomTime(
            this.state.timeLineSelectedDataItems[0].start,
            't1'
        )
        this.timeLine.current.redraw()
        this.timeLine.current.setOptions({
            start: new Date(
                this.state.timeLineSelectedDataItems[0].startFrom.setFullYear(
                    this.state.timeLineSelectedDataItems[0].startFrom.getFullYear() -
                        0
                )
            ),
            end: new Date(
                this.state.timeLineSelectedDataItems[0].endTo.setFullYear(
                    this.state.timeLineSelectedDataItems[0].endTo.getFullYear() +
                        0
                )
            ),
            min: new Date(
                this.state.timeLineSelectedDataItems[0].startFrom.setFullYear(
                    this.state.timeLineSelectedDataItems[0].startFrom.getFullYear() -
                        0
                )
            ),
            max: new Date(
                this.state.timeLineSelectedDataItems[0].endTo.setFullYear(
                    this.state.timeLineSelectedDataItems[0].endTo.getFullYear() +
                        0
                )
            ),
        })
    }
    render() {
        return (
            <div className="map-boby">
                <MapHeader />
                <div ref={this.mapContainer} className="map" id={'map'}/>
                <main className="main">
                    <LeftSide
                        toggleItemsFromMap={(categoryID, subCategoryID, type) =>
                            this.toggleItemsFromMapByID(
                                categoryID,
                                subCategoryID,
                                type
                            )
                        }
                    />
                    <RightSide
                        mapState={this.map.current}
                    />
                </main>
                <Timeline
                    dataType={this.state.url.type}
                    timeLine={this.timeLine}
                    goToTopics={() => this.goToTopics()}
                    moveLeft={() => this.moveLeft()}
                    moveRight={() => this.moveRight()}
                    toggleItemsFromMap={(categoryID, subCategoryID, type) =>
                        this.toggleItemsFromMapByID(
                            categoryID,
                            subCategoryID,
                            type
                        )
                    }
                />
            </div>
        )
    }
}

const mapStateTopProps = (state) => ({
    selectedMapStyle: getSelectedMapStyle(state),
    mapStyles: getMapStylesData(state),
    categories: getCategoriesData(state),
    filteredCategories: getFilteredCategoriesData(state),
    selectedArticle: getSelectedArticleData(state),
    mapZoom: getMapZoomData(state),
    selectedMapRequirement: getSelectedMapRequirements(state),
    prevLessonID: getPrevLessonIDData(state),
    nextLessonID: getNextLessonIDData(state),
    timeLineItems: getTimeLineItemsData(state),
    timeLineGroups: getTimeLineGroupsData(state),
    timeLineLoading: getIsTimeLineLoading(state),
    selectedLesson: getSelectedLessonId(state),
    lampData: getLampData(state),

})

const mapDispatchToProps = {
    getCategoriesFromAPI,
    getLampInfo,
    getFilteredCategoriesFromAPI,
    setMapZoom,
    setTimeLineExpend,
    updateCategoriesToggle,
    getArticleFromAPI,
    setNextLessonID,
    setTimeLineTopicsData,
    setTimeLineLessonData,
    setPrevLessonID,
    setSelectedLesson,
    updateTimeLineGroupToggle,
}

export default connect(mapStateTopProps, mapDispatchToProps)(Map)
