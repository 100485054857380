import axios from 'axios'
import { formatLessonTime, formatServerResponseTime } from '../utils'

export const processTimeLineLessonItemsRendering = async (
    dataID,
    lessonId,
    data,
    fromTopic,
    timeLineSelectedDataGroup,
    categories
) => {
    const timeLineSelectedDataItem = []
    let lessonID = lessonId
    let nextLesson = null
    let prevLesson = null
    if (fromTopic) {
        lessonID = dataID
        nextLesson = null
    }

    await axios
        .get(
            `${process.env.REACT_APP_HEADING_URL}/api/Lesson/${lessonID}/Language/1`
        )
        .then(async (response) => {
            if (response.data.accepted) {
                let lessonData = response.data.data[0]
                await axios
                    .get(
                        `${process.env.REACT_APP_HEADING_URL}/api/Topic/${lessonData.topicId}/Language/1`
                    )
                    .then((res) => {
                        if (res.data.accepted) {
                            let topicData = res.data.data[0]
                            const { startFrom, endTo } =
                                formatServerResponseTime(lessonData)

                            timeLineSelectedDataItem.push({
                                id: 0,
                                lessonID: lessonData.id,
                                content: `Դաս: ${lessonData.title} - Թեմա։ ${topicData.title}`,
                                nextLessonID: nextLesson,
                                prevLessonID: prevLesson,
                                startFrom: startFrom,
                                endTo: endTo,
                                start: startFrom,
                                end: endTo,
                                className: 'isGroup',
                                group: 0,
                                color: lessonData.color,
                            })
                        }
                    })
            }
        })
    data.sort(function (a, b) {
        return a.order - b.order
    })
    data.forEach((item) => {
        const { lessonStartFrom, lessonEndTo } = formatLessonTime(item)

        let cat = categories.find((x) => x.id === item.category[0].categoryId)
        timeLineSelectedDataItem.push({
            id: item.id,
            articleId: item.id,
            catId: item.category[0].categoryId,
            catIcon: cat?.iconPath,
            content:
                item.content[0].shortTitle !== null &&
                item.content[0].shortTitle.length > 0
                    ? item.content[0].shortTitle
                    : item.content[0].mainTitle,
            start: lessonStartFrom,
            end: lessonEndTo,
            group: item?.isLamp
                ? 111
                : timeLineSelectedDataGroup?.find(
                    (x) => x.catId === item.category[0].categoryId
                )?.id,
            className: 'isInner',
            color: cat?.color,
            show: true,
            isLamp: item.isLamp,
        })
    })

    return timeLineSelectedDataItem
}
