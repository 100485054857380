import { CHANGE_MAP_REQUIREMENTS } from '../constants'
import { getMapRequirements } from '../../service/mapRequirements'

const initialState = {
    data: [
        {
            id: 1,
            value: 'all',
            title: 'Բոլորը',
            active: true,
        },
        {
            id: 2,
            value: 0,
            title: 'Հիմնական',
            active: false,
        },
        {
            id: 3,
            value: 1,
            title: 'Հավելյալ',
            active: false,
        },
    ],
}

export const mapRequirementsReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_MAP_REQUIREMENTS:
            return {
                ...state,
                data: getMapRequirements(action.payload, initialState.data),
            }
        default:
            return state
    }
}
