import { SET_TIMELINE_OPTIONS } from '../constants'
import ReactDOM from 'react-dom'
import GroupTemplate from '../../components/GroupTemplate'
import ItemTemplate from '../../components/ItemTemplate'

const initialState = {
    data: {
        autoResize: false,
        stack: true,
        showCurrentTime: false,
        horizontalScroll: true,
        verticalScroll: true,
        zoomKey: 'ctrlKey',
        maxHeight: '230px',
        minHeight: '230px',
        zoomMin: 315569520000,
        // groupHeightMode: 'fixed',
        format: {
            minorLabels: function (a) {
                if (a.format('YYYY').includes('-')) {
                    return 'Ք.ա. ' + a.format('y').replace('-', '')
                }
                return a.format('y')
            },
        },
        orientation: {
            item: 'top',
            axis: 'bottom',
        },
        order: function (a, b) {
            return a.order - b.order
        },
        groupTemplate: function (group, element) {
            return ReactDOM.createPortal(
                ReactDOM.render(<GroupTemplate group={group} />, element),
                element
            )
        },
        template: function (item, element) {
            return ReactDOM.createPortal(
                ReactDOM.render(<ItemTemplate item={item} />, element),
                element
            )
        },
    },
}

export const timeLineOptionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TIMELINE_OPTIONS:
            return {
                ...state,
                data: action.payload,
            }
        default:
            return state
    }
}
