import {
    SET_ARTICLES,
    LOADING_ARTICLES,
    SET_SELECTED_ARTICLE,
} from '../constants'

const initialState = {
    data: [],
    selectedArticleData: null,
    isLoading: false,
}

export const articlesReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING_ARTICLES: {
            return { ...state, isLoading: true }
        }
        case SET_ARTICLES:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            }
        case SET_SELECTED_ARTICLE:
            return {
                ...state,
                selectedArticleData: action.payload,
            }
        default:
            return state
    }
}
