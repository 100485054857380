import {Component} from "react";

class ItemTemplate extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.item?.className === 'isGroup') {
            return (
                <div
                    className={`group-content ${this.props.item?.active ? 'active' : ''}`}
                >
                    { this.props.item.content }
                </div>
            )
        } else if (this.props.item?.className === 'isInner') {
            return (
                <div className="lesson__content-item"
                     style={{
                         backgroundColor: this.props.item.color ? this.props.item.color : '',
                         opacity: this.props.item.show ? 1 : 0.5
                     }}
                >
                    <span className="lesson__content-name">
                         <img src={this.props.item?.catIcon} style={{
                             width: 20,
                             height: 20,
                             marginRight: 10
                         }}/>
                        { this.props.item.content }
                    </span>
                </div>
            )
        } else {
            return (
                <button
                    type="button"
                    className={`item-content ${this.props.item?.active ? 'active' : ''}`}
                >
                    { this.props.item.content }
                </button>
            )
        }
    }
}
export default ItemTemplate
