import axios from "axios";

const extractItems = (res) => {
  const { data } = res;
  if (data.accepted) {
    if (data.data && data.data[0]) {
      return data.data[0].items;
    }
  }
  return [];
};

const sanitizeSelectedResult = (items) =>
  items.map((value) => ({
    ...value,
    ...{ show: true },
    subCategories:
      value.subCategories !== null &&
      value.subCategories.map((elem) => ({
        ...elem,
        ...{
          show: true,
          articles: [],
        },
      })),
  }));

export const getCategoriesAPI = (url, body) => {
  return axios
    .post(url, body)
    .then((response) => extractItems(response))
    .then((items) => sanitizeSelectedResult(items))
    .catch(console.log);
};

export const filterCategories = (data, categories) => {
  const filteredCategories = [];
  data.forEach((item) => {
    let findCat = categories.find((x) => x.id === item.catID);
    if (findCat?.subCategories.length > 0) {
      findCat?.subCategories?.forEach((sub) => {
        sub.articles = [];
        filteredCategories.push(findCat);
      });
    }
  });
  const uniqueIds = [];
  return new Promise((resolve, reject) =>
    resolve(
      filteredCategories
        .filter((element) => {
          const isDuplicate = uniqueIds.includes(element.id);
          if (isDuplicate) {
            return false;
          }
          if (isDuplicate === false) {
            uniqueIds.push(element.id);
            return true;
          }
        })
        .sort((a, b) => (a.id < b.id ? 1 : -1))
    )
  );
};

export const addArticlesToCategories = (categories, articles) => {
  articles.forEach((article) => {
    !article.isLamp && article.subCategory.forEach((item) => {
      const findCat = categories.find(
        (category) => category.id === article.subCategory[0]?.categoryId
      );
      if (findCat) {
        const subCategory = findCat.subCategories.find(
          (subCat) => subCat.id === item.subCategoryId
        );
        let art = undefined;
        if (subCategory) {
          art = subCategory.articles.find((x) => x.id === article.id);
        }
        if (art === undefined) {
          subCategory?.articles?.push({
            id: article.id,
            title: article.content[0].mainTitle,
          });
        }
      }
    });
  });
  categories.forEach((category) => {
    let subCategories = [];
    category?.subCategories.forEach((subCat) => {
      if (subCat.articles.length > 0) {
        subCategories.push(subCat);
      }
    });
    category.subCategories = subCategories;
  });
  return new Promise((resolve, reject) => resolve(categories));
};
