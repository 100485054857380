export const checkAccountValidationErrors = (fields) => {
  let errors = {};
  let hasError = false;
  //firstName
  if (!fields["firstName"]) {
    hasError = true;
    errors["firstName"] = "Այս դաշտը պարտադիր է։ Խնդրում ենք լրացնել։";
  }

  //lastName
  if (!fields["lastName"]) {
    hasError = true;
    errors["lastName"] = "Այս դաշտը պարտադիր է։ Խնդրում ենք լրացնել։";
  }

  //firstName
  if (!fields["phone"]) {
    hasError = true;
    errors["phone"] =
      "Հեռախոսահամարը սխալ է։ Խնդրում ենք մուտքագրել ճշգրիտ հեռախոսահամար։";
  }

  if (fields["phone"]?.search(/[0-9]/) == -1) {
    hasError = true;
    errors["phone"] =
      "Հեռախոսահամարը սխալ է։ Խնդրում ենք մուտքագրել ճշգրիտ հեռախոսահամար։";
  }

  if (fields["phone"]?.length > 11) {
    hasError = true;
    errors["phone"] =
      "Հեռախոսահամարը սխալ է։ Խնդրում ենք մուտքագրել ճշգրիտ հեռախոսահամար։";
  }

  return hasError ? errors : null;
};

export const checkResetPassValidationErrors = (fields) => {
  let errors = {};
  let hasError = false;
  if (!fields["oldPassword"]) {
    hasError = true;
    errors["oldPassword"] = "Այս դաշտը պարտադիր է։ Խնդրում ենք լրացնել։";
  }

  //Password
  if (!fields["password"]) {
    hasError = true;
    errors["password"] = "Այս դաշտը պարտադիր է։ Խնդրում ենք լրացնել։";
  }

  if (fields["password"] && fields["password"].length < 6) {
    hasError = true;
    errors["password"] =
      "Խնդրում ենք մուտքագրել վավեր գաղտնաբառ։ Ձեր գաղտնաբառը պետք է լինի առնվազն 6 նիշ" +
      " երկարությամբ:";
  }

  //CPassword
  if (!fields["cPassword"]) {
    hasError = true;
    errors["cPassword"] = "Այս դաշտը պարտադիր է։ Խնդրում ենք լրացնել։";
  }

  if (fields["password"] != fields["cPassword"]) {
    hasError = true;
    errors["cPassword"] = "Խնդրում ենք մուտքագրել վավեր գաղտնաբառ։";
  }

  return hasError ? errors : null;
};

export const checkAdditionalValidationErrors = (fields) => {
  let errors = {};
  let hasError = false;
  //lastName
  if (!fields["gender"]?.value) {
    hasError = true;
    errors["gender"] = "Այս դաշտը պարտադիր է։ Խնդրում ենք լրացնել։";
  }

  //Email
  if (!fields["birthDate"]) {
    hasError = true;
    errors["birthDate"] = "Այս դաշտը պարտադիր է։ Խնդրում ենք լրացնել։";
  }

  if (!fields["country"]?.value) {
    hasError = true;
    errors["country"] = "Այս դաշտը պարտադիր է։ Խնդրում ենք լրացնել։";
  }

  if (!fields["region"]?.value) {
    hasError = true;
    errors["region"] = "Այս դաշտը պարտադիր է։ Խնդրում ենք լրացնել։";
  }
  if (!fields["state"]?.value) {
    hasError = true;
    errors["state"] = "Այս դաշտը պարտադիր է։ Խնդրում ենք լրացնել։";
  }
  if (!fields["school"]?.value) {
    hasError = true;
    errors["school"] = "Այս դաշտը պարտադիր է։ Խնդրում ենք լրացնել։";
  }
  if (fields.speciality.text === "Student" && !fields["grade"]?.value) {
    hasError = true;
    errors["grade"] = "Այս դաշտը պարտադիր է։ Խնդրում ենք լրացնել։";
  }
  if (fields.speciality.text === "Teacher" && fields["subject"]?.length === 0) {
    hasError = true;
    errors["subject"] = "Այս դաշտը պարտադիր է։ Խնդրում ենք լրացնել։";
  }

  return hasError ? errors : null;
};
