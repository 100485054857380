import { CHANGE_MAP_ZOOM } from '../constants'

export const changeMapZoom = (zoom) => ({
    type: CHANGE_MAP_ZOOM,
    payload: zoom,
})

export const setMapZoomIn = () => (dispatch, getState) => {
    const state = getState()
    const initialZoom = state.mapZoom.data
    dispatch(changeMapZoom(Number(initialZoom) + 1))
}

export const setMapZoomOut = () => (dispatch, getState) => {
    const state = getState()
    const initialZoom = state.mapZoom.data
    dispatch(changeMapZoom(Number(initialZoom) - 1))
}

export const setMapZoom = (zoom) => (dispatch) => {
    dispatch(changeMapZoom(zoom))
}
