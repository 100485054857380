import React from 'react'
import './style.css'
import { getAboutData, getMapStyles, getMenuData } from '../../store/selectors'
import { setMenu } from '../../store/actions/menu'
import { connect } from 'react-redux'
import Close from '../UI/Close'
import { setAbout } from '../../store/actions/about'
import About from '../../pages/about'

export const leftNavigation = (props) => {
    return (
        <>
            <button
                className={`header__burger ${props.menu ? 'active' : ''}`}
                type="button"
                onClick={() => props.setMenu(!props.menu)}
            >
                Ա․
            </button>
            <div className={`header__nav ${props.menu ? 'active' : ''}`}>
                <h1 className="header__title title">աշխարհացույց</h1>

                <div className="header__content">
                    <nav>
                        <ul className="header__nav-items">
                            <li className="header__nav-item">
                                <a className="header__nav-link" href="/login">
                                    <span>Իմ Հաշիվը</span>
                                </a>
                            </li>
                            <li className="header__nav-item">
                                <a
                                    className="header__nav-link"
                                    href="#"
                                    onClick={() => {
                                        props.setAbout(true)
                                    }}
                                >
                                    <span>Մեր Մասին</span>
                                </a>
                            </li>
                            <li className="header__nav-item">
                                <a
                                    className="header__nav-donate"
                                    href="https://visualarmenia.org/#donate"
                                    target={'_blank'}
                                    rel="noreferrer"
                                >
                                    Նվիրաբերել
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <div className="header__nav-copy">
                        <p>ԱՇԽԱՐՀԱՑՈՒՅՑ © {new Date().getFullYear()}</p>
                        <p>Բոլոր իրավունքները պաշտպանված են։</p>
                    </div>
                </div>
            </div>
            {props.about && <About />}
        </>
    )
}

const mapStateTopProps = (state) => ({
    menu: getMenuData(state),
    about: getAboutData(state),
})

const mapDispatchToProps = {
    setMenu: setMenu,
    setAbout: setAbout,
}

export default connect(mapStateTopProps, mapDispatchToProps)(leftNavigation)
