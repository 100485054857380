import React, { Component } from "react"
import { Button } from "../../../components/UI/Button"
import {
  dropDownCustomAccountStyles,
  genderOptions,
  updateUserInfoURI,
} from "../../../shared/mockData"
import { checkAdditionalValidationErrors } from "../form-validation"
import { SelectBox } from "../../../components/UI/SelectBox"
import { MyDatePicker } from "../../../components/UI/MyDatePicker"
import axios from "axios"
import {
  constructSelectedItemApiParams,
  extractItems,
  getUserUpdateApiParams,
  santizeSelectedResult,
  sortItemsByAlphabetical,
  updateUserAccount,
} from "../utils"
import { getErrorMessage } from "../../../helpers"
import {
  getCountriesData,
  getGradesData,
  getRegionsData,
  getSchoolsData,
  getStatesData,
  getSubjectsData,
  getUserData,
} from "../../../store/selectors"
import { getUserFromAPI } from "../../../store/actions/user"
import { connect } from "react-redux"

class RightSideBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editAll: false,
      loading: false,
      fields: props.user,
      errors: {},
      apiErrorMsg: "",
      showError: false,
      uploadedFile: {
        id: null,
        url: null,
      },
    }
  }

  handleSelectOptions = (type) => {
    const { url, body } = constructSelectedItemApiParams(
      type,
      this.state.fields
    )

    const handleItemsLength = (items) => {
      if (type === "state" && items.length === 1) {
        let fields = this.state.fields
        fields["state"] = {
          value: items[0].value,
          label: items[0].label,
        }
        this.setState({ fields })
        this.handleSelectOptions("school")
      }
      return items
    }

    axios
      .post(url, body)
      .then((response) => extractItems(response))
      .then((items) => santizeSelectedResult(items))
      .then((items) => handleItemsLength(items))
      .then((items) => sortItemsByAlphabetical(items))
      .then((items) =>
        this.setState({
          [`${type}s`]: items,
        })
      )
  }

  additionalSubmit(e) {
    this.setState({ loading: true })
    e.preventDefault()
    const errors = checkAdditionalValidationErrors(this.state.fields)
    if (errors) {
      this.setState({ loading: false, errors })
      console.log("Form has errors.")
    } else {
      this.updateAccount()
    }
  }
  updateAccount = () => {
    const { fields, uploadedFile } = this.state
    const body = getUserUpdateApiParams(fields,this.props.user, uploadedFile)
    const errorState = {
      loading: false,
      editAll: false,
      showError: false,
      uploadedFile: {
        id: null,
        url: null,
      },
    }
    updateUserAccount(updateUserInfoURI, body)
      .then((res) => {
        if (res.data.accepted === false) {
          return Promise.reject(res.data.errorMessages)
        }
        return res.data
      })
      .then(this.getData)
      .catch((error) => {
        errorState.showError = true
        errorState.apiErrorMsg = getErrorMessage(error)
        this.setState(errorState)
      })
      this.setState({ editAll: false })
  }

  render() {
    const { editAll, fields, errors } = this.state
    const { additionalSubmit, handleSelectOptions } = this
    const { user, grades, countries, states, subjects, regions, schools } =
      this.props

    if (editAll) {
      return (
        <>
          <div className="user-right-header">
            <p className="user-additional">Այլ տվյալներ</p>
          </div>
          <div className="user-additional-content">
            <form>
              <div className="user-detail">
                <span>Սեռը</span>
                <SelectBox
                  hasError={errors["gender"]}
                  placeholder="Սեռը *"
                  contentClassName={``}
                  dropDownStyles={dropDownCustomAccountStyles}
                  className={`user-input select`}
                  options={genderOptions}
                  onChange={(e) => {
                    fields["gender"] = e
                    this.setState({ fields: fields })
                  }}
                  value={fields["gender"] || ""}
                />
              </div>
              <div className="user-detail">
                <span>Ծննդյան տարեթիվ</span>
                <MyDatePicker
                  contentClassName={`user-input`}
                  className={""}
                  hasError={errors["birthDate"]}
                  placeholder="Ծննդյան տարեթիվ *"
                  onChange={(e) => {
                    fields["birthDate"] = e
                    this.setState({ fields })
                  }}
                  value={new Date(fields["birthDate"]) || null}
                />
              </div>
              <div className="user-detail">
                <span>Երկիրը</span>
                <SelectBox
                  hasError={errors["country"]}
                  placeholder="Երկիրը *"
                  contentClassName={``}
                  dropDownStyles={dropDownCustomAccountStyles}
                  className={`user-input select`}
                  options={countries}
                  onChange={(e) => {
                    fields["country"] = e
                    this.setState({ fields: fields })
                    handleSelectOptions("region")
                  }}
                  value={fields["country"] || ""}
                />
              </div>
              <div className="user-detail">
                <span>Մարզ</span>
                <SelectBox
                  hasError={errors["region"]}
                  placeholder="Մարզ *"
                  contentClassName={``}
                  dropDownStyles={dropDownCustomAccountStyles}
                  className={`user-input select`}
                  options={regions}
                  onChange={(e) => {
                    fields["region"] = e
                    this.setState({ fields: fields })
                    handleSelectOptions("state")
                  }}
                  value={fields["region"] || ""}
                />
              </div>
              {states.length > 1 ? (
                <div className="user-detail">
                  <span>Համայնք</span>
                  <SelectBox
                    hasError={errors["state"]}
                    placeholder="Համայնք *"
                    contentClassName={``}
                    dropDownStyles={dropDownCustomAccountStyles}
                    className={`user-input select`}
                    options={states}
                    onChange={(e) => {
                      fields["state"] = e
                      this.setState({ fields: fields })
                      handleSelectOptions("school")
                    }}
                    value={fields["state"] || ""}
                  />
                </div>
              ) : null}
              <div className="user-detail">
                <span>Դպրոց</span>
                <SelectBox
                  hasError={errors["school"]}
                  placeholder="Դպրոց *"
                  contentClassName={``}
                  dropDownStyles={dropDownCustomAccountStyles}
                  className={`user-input select`}
                  options={schools}
                  onChange={(e) => {
                    fields["school"] = e
                    this.setState({ fields: fields })
                  }}
                  value={fields["school"] || ""}
                />
              </div>
              {user.speciality.text === "Student" && (
                <div className="user-detail">
                  <span>Դասարան</span>
                  <SelectBox
                    hasError={errors["grade"]}
                    placeholder="Դասարան *"
                    contentClassName={``}
                    dropDownStyles={dropDownCustomAccountStyles}
                    className={`user-input select`}
                    options={grades}
                    onChange={(e) => {
                      fields["grade"] = e
                      this.setState({ fields: fields })
                    }}
                    value={fields["grade"] || ""}
                  />
                </div>
              )}
              {user.speciality.text === "Teacher" && (
                <div className="user-detail">
                  <span>Առարկա</span>
                  <SelectBox
                    hasError={errors["subject"]}
                    placeholder="Առարկա *"
                    contentClassName={``}
                    dropDownStyles={dropDownCustomAccountStyles}
                    className={`user-input select`}
                    options={subjects}
                    isMulti
                    onChange={(e) => {
                      fields["subject"] = e
                      this.setState({ fields: fields })
                    }}
                    value={fields["subject"] || ""}
                  />
                </div>
              )}

              <div className="user-form-btns">
                <Button
                  className="user-form-btn link"
                  action={() => {
                    this.setState({ editAll: false })
                  }}
                  text="Չեղարկել"
                />
                <Button
                  className="user-form-btn save"
                  action={additionalSubmit.bind(this)}
                  text="Պահպանել"
                />
              </div>
            </form>
          </div>
        </>
      )
    }

    return (
      <>
        <div className="user-right-header">
          <p className="user-additional">Այլ տվյալներ</p>
        </div>
        <div className="user-additional-content">
          <div className="user-detail">
            <span>Սեռը</span>
            <div className="user-input border-none">
              <p>{fields["gender"]?.label}</p>
            </div>
          </div>
          <div className="user-detail">
            <span>Ծննդյան տարեթիվ</span>
            <div className="user-input border-none">
              <p>{new Date(user.birthDate).toLocaleDateString()}</p>
            </div>
          </div>
          <div className="user-detail">
            <span>Երկիրը</span>
            <div className="user-input border-none">
              <p>{fields["country"]?.label}</p>
            </div>
          </div>
          <div className="user-detail">
            <span>Մարզ</span>
            <div className="user-input border-none">
              <p>{fields["region"]?.label}</p>
            </div>
          </div>
          {states.length > 1 && (
            <div className="user-detail">
              <span>Համայնք</span>
              <div className="user-input border-none">
                <p>{fields["state"]?.label}</p>
              </div>
            </div>
          )}

          <div className="user-detail">
            <span>Դպրոց</span>
            <div className="user-input border-none">
              <p>{fields["school"]?.label}</p>
            </div>
          </div>
          {user.speciality.text === "Teacher" ? (
            <div className="user-detail">
              <span>Առարկա</span>
              <div className="user-input border-none">
                <p>
                  {fields["subject"]?.map((item, index) => {
                    return (
                        <span key={index}>
                          {item.label}{" "}
                          {index < fields["subject"].length - 1 ? ", " : null}
                        </span>
                    )
                  })}
                </p>
              </div>
            </div>
          ) : user.speciality.text === "Student" ? (
            <div className="user-detail">
              <span>Դասարան</span>
              <div className="user-input border-none">
                <p>{fields["grade"]?.label}</p>
              </div>
            </div>
          ) : null}
          <div className="user-form-btns">
            <Button
              className="user-edit-btn link"
              action={() => this.setState({ editAll: true })}
              text="Խմբագրել"
            />
          </div>
        </div>
      </>
    )
  }
}
const mapStateTopProps = (state) => ({
  user: getUserData(state),
  subjects: getSubjectsData(state),
  grades: getGradesData(state),
  countries: getCountriesData(state),
  states: getStatesData(state),
  regions: getRegionsData(state),
  schools: getSchoolsData(state),
})

const mapDispatchToProps = {
  getUserFromAPI,
}

export default connect(mapStateTopProps, mapDispatchToProps)(RightSideBar)
