import {
    SET_ARTICLES,
    LOADING_ARTICLES,
    SET_SELECTED_ARTICLE,
} from '../constants'
import { getArticleAPI } from '../../service/article'
import { setSearch } from './search'

export const loadingArticles = () => ({
    type: LOADING_ARTICLES,
})

export const setArticles = (articles) => ({
    type: SET_ARTICLES,
    payload: articles,
})

export const setSelectedArticle = (article) => ({
    type: SET_SELECTED_ARTICLE,
    payload: article,
})

export const getArticleFromAPI = (id) => (dispatch, getState) => {
    dispatch(loadingArticles())

    getArticleAPI(id).then((article) => {
        let articles = getState().articles.data
        const openedArticles = articles.filter(x => x.show)
        if (articles.find(x => x.id == id) !== undefined || typeof articles.find(x => x.id == id) !== 'undefined' ) {
            const indexArticle = articles.findIndex(x => x.id === article.id)
            articles[indexArticle].show = true
        } else {
            if (openedArticles.length > 0) {
                openedArticles.forEach(article => {
                    const indexArticle = articles.findIndex(x => x.id === article.id)
                    articles.splice(indexArticle, 1)
                })
            }
            articles.push(article)
        }

        dispatch(
            setSelectedArticle({
                id: id,
                type: article.articleData.type,
            })
        )

        dispatch(setSearch(false))
        dispatch(setArticles(articles))
    })
}

export const collapsArticlePopup = (id) => (dispatch, getState) => {
    dispatch(loadingArticles())
    const articles = getState().articles.data
    const foundIndex = articles.findIndex((article) => article.id === id)
    articles[foundIndex].show = !articles[foundIndex].show
    dispatch(setArticles(articles))
}

export const closeArticlePopup = (id) => (dispatch, getState) => {
    dispatch(loadingArticles())
    const articles = getState().articles.data
    const foundIndex = articles.findIndex((article) => article.id === id)
    articles.splice(foundIndex, 1)
    dispatch(setArticles(articles))
}
