import React, { Component } from "react";
import './style.css';

class ErrorAlert extends Component {
    render() {
        return (
                <div className="error-alert">
                    <p>{this.props.text}</p>
                    <div
                        style={{
                            display: 'flex',
                            width: '20px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}
                        onClick={() => this.props.close()}
                    >X</div>
                </div>
        );
    }
}

export default ErrorAlert;
