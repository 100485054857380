import mapboxgl from 'mapbox-gl'
import lampIcon from "../../assets/imgs/lamp-icon.svg";

export const mapUtilities = (map, timeLine) => {
    const setData = (items, groups) => {
        timeLine.current.setData({ items, groups })
    }

    return {
        setData,
    }
}

export const getData = (url) => {
    return fetch(url).then((response) => response.json())
}

export const isSymbol = (item) => item.type === 'symbol'
export const isPoint = (item) => item.id.split('-')[2] === 'point'

export const addSymbol = (categories, current, getArticleFromAPI) => (item) => {
    const popup = new mapboxgl.Popup()

    current.on('click', item.id, (e) => {
        getArticleFromAPI(item.ArticleId)
    })
    current.on('mouseenter', item.id, (e) => {
        current.getCanvas().style.cursor = 'pointer'
        let catId = e.features[0].properties.categoryId
        let cat = categories.find((x) => x.id === catId)
        popup
            .setLngLat(e.features[0].geometry.coordinates.slice())
            .setHTML(
                `
                                <div style="background-color: ${
                                    cat ? cat.color : '#fff'
                                };padding: 10px 20px 10px !important;box-shadow: 0 1px 2px rgb(0 0 0 / 10%);    border-radius: 200px !important;" onClick={getArticleFromAPI(item.ArticleId)}>${
                    item?.ArticleShortTitle
                        ? item?.ArticleShortTitle
                        : item.ArticleTitle
                }</div>
                                <div class="mapboxgl-popup-tip" style="border-top-color: ${
                                    cat ? cat.color : '#fff'
                                };margin: 0 auto;"></div>`
            )
            .addTo(current)
    })
    current.on('mouseleave', item.id, () => {
        current.getCanvas().style.cursor = ''
        popup.remove()
    })
}

export const addArticlePoint = (current, data) => {
    let color = data?.layers[0]?.paint['icon-color']

    const el = document.createElement('div')
    const width = 35
    const height = 35
    el.style.width = `${width}px`
    el.style.height = `${height}px`
    el.style.backgroundColor = color
    el.style.padding = '10px'
    el.style.top = '-30px'
    el.style.boxShadow = '0 1px 2px rgb(0 0 0 / 10%)'
    el.style.borderRadius = '200px'
    el.style.padding = '10px !important'
    el.style.overflow = 'hidden'
    const elInner = document.createElement('div')
    elInner.style.height = '100%'
    elInner.style.width = '100%'
    elInner.style.overflow = 'hidden'
    const elImg = document.createElement('img')
    elImg.style.height = '100%'
    elImg.style.width = 'auto'
    elImg.style.filter = 'brightness(10)'
    elImg.setAttribute('src', data?.images[Object.keys(data?.images)[0]])
    elInner.appendChild(elImg)
    el.appendChild(elInner)
    setTimeout(() => {
        new mapboxgl.Marker(el).setLngLat(data?.center).addTo(current)
    }, 1000)
}

export const addPoint = (current, getArticleFromAPI, response, lampData) => (item) => {
    if (document.getElementById(item.id)) {
        document.getElementById(item.id).remove()
    }
    let catImg = null
    let catColor = '#fff'
    const layerPars = Object.entries(item.layout)
    let cats = layerPars.find((x) => x[0] === 'icon-image')
    const PaintPars = Object.entries(item.paint)
    let paint = PaintPars.find((x) => x[0] === 'icon-color')
    if (paint) {
        catColor = paint[1]
    }
    if (cats) {
        let catImgs = Object.entries(response.images).find(
            (x) => x[0] === cats[1]
        )
        if (catImgs) {
            catImg = catImgs[1]
        }
    }
    let featuresId = null
    let pinCenter = [0, 0]
    if (item.filter[0] === 'all') {
        featuresId = item.filter[1][2]
    } else {
        featuresId = item.filter[2]
    }
    if (featuresId !== null) {
        const cordinates = response.features.find(
            (x) => x.properties.id === featuresId
        )
        if (cordinates) {
            pinCenter = cordinates.geometry.coordinates
        }
    }
    const el = document.createElement('div')
    el.classList.add(`article_${item.ArticleId}`)
    const width = 35
    const height = 35
    el.onclick = () => getArticleFromAPI(item.ArticleId)
    el.id = item.id
    el.style.cursor = 'pointer'
    el.style.width = `${width}px`
    el.style.height = `${height}px`
    el.style.backgroundColor = catColor
    el.style.padding = '10px'
    el.style.visibility = 'visible'
    el.style.top = '-30px'
    el.style.boxShadow = '0 1px 2px rgb(0 0 0 / 10%)'
    el.style.borderRadius = '200px'
    el.style.padding = '10px !important'
    el.style.overflow = 'hidden'
    const elInner = document.createElement("div");
    elInner.style.height = "100%";
    elInner.style.width = "100%";
    elInner.style.overflow = "hidden";
    elInner.style.display = "flex";
    elInner.style.justifyContent = "center";
    const elImg = document.createElement("img");
    elImg.style.height = "100%";
    elImg.style.width = "auto";
    const getFromLamp = lampData.find(
        (x) => x.id === item.ArticleId
    );
    if (getFromLamp !== undefined) {
        elImg.setAttribute("src", lampIcon);
    } else {
        elImg.setAttribute("src", catImg);
    }

    elInner.appendChild(elImg);
    el.appendChild(elInner);

    new mapboxgl.Marker(el).setLngLat(pinCenter).addTo(current)
}

export const addAndPositionLayer =
    (current, loadFirst, firstLayer) => (item) => {
        if (!current.getLayer(item.id)) {
            current.addLayer(item)
            if (!loadFirst && item.id.includes('country')) {
                if (current.getLayer(item.id)) {
                    current.moveLayer(item.id, firstLayer)
                }
            }
        }
    }

export const formatServerResponseTime = ({
    endDay,
    endYear,
    endMonth,
    endTimeIsBC,
    startDay,
    startYear,
    startMonth,
    startTimeIsBC,
}) => {
    const startMonthF = `${startMonth !== null ? endMonth : 1}`.padStart(2, '0')
    const startDatF = `${startDay !== null ? endDay : 1}`.padStart(2, '0')
    const startYearF = `${
        startTimeIsBC
            ? `-${String(startYear).padStart(4, '0')}`
            : String(startYear).padStart(4, '0')
    }`

    const endMonthF = `${endMonth !== null ? endMonth : 12}`.padStart(2, '0')
    const endDayF = `${endDay !== null ? endDay : 1}`.padStart(2, '0')
    const endYearF = endTimeIsBC
        ? `-${String(endYear).padStart(4, '0')}`
        : String(endYear).padStart(4, '0')

    const startFrom = startTimeIsBC
        ? new Date(startYearF, startMonthF, startDatF)
        : new Date(`${startYearF}-${startMonthF}-${startDatF}`)

    const endTo = endTimeIsBC
        ? new Date(endYearF, endMonthF, endDayF)
        : new Date(`${endYearF}-${endMonthF}-${endDayF}`)

    return { startFrom, endTo }
}

export const formatLessonTime = ({
    endDay,
    endYear,
    endMonth,
    endTimeIsBC,
    startDay,
    startYear,
    startMonth,
    startTimeIsBC,
}) => {
    const startMonthF = `${startMonth !== null ? endMonth : 1}`.padStart(2, '0')
    const startDatF = `${startDay !== null ? endDay : 1}`.padStart(2, '0')
    const startYearF = `${
        startTimeIsBC
            ? `-${String(startYear).padStart(4, '0')}`
            : String(startYear).padStart(4, '0')
    }`

    const endMonthF = `${endMonth !== null ? endMonth : 12}`.padStart(2, '0')
    const endDayF = `${endDay !== null ? endDay : 1}`.padStart(2, '0')
    const endYearF = endTimeIsBC
        ? `-${String(endYear).padStart(4, '0')}`
        : String(endYear).padStart(4, '0')

    const lessonStartFrom = startTimeIsBC
        ? new Date(startYearF, startMonthF, startDatF)
        : new Date(`${startYearF}-${startMonthF}-${startDatF}`)

    const lessonEndTo = endTimeIsBC
        ? new Date(endYearF, endMonthF, endDayF)
        : new Date(`${endYearF}-${endMonthF}-${endDayF}`)
    return { lessonStartFrom, lessonEndTo }
}

export const generateQueryString = (url) => {
    let newObj = {}
    let type = url.type
    if (url.lessonID) {
        newObj.lessonid = url.lessonID
    }
    if (url.subjectID) {
        newObj.subjectId = url.subjectID
    }
    if (url.topicID) {
        newObj.topicId = url.topicID
    }
    if (url.gradeID) {
        newObj.gradeId = url.gradeID
    }
    if (url.articleID) {
        newObj.articleId = url.articleID
    }
    return { newObj, type }
}

export const generateTimeLineQueryString = (
    fullYear,
    url,
    selectedLesson,
    id
) => {
    let newObj = {
        Year: fullYear < 0 ? (fullYear + 1) * -1 : fullYear + 1,
    }
    if (fullYear < 0) {
        newObj.IsBC = true
    } else {
        newObj.IsBC = false
    }
    let type = selectedLesson !== null ? 'lesson' : url.type
    if (type === 'lesson') {
        newObj.LessonId = selectedLesson !== null ? selectedLesson : id
    }
    if (type === 'topic') {
        newObj.TopicId = id
    }
    if (type === 'article') {
        newObj.ArticleId = id
    }
    return newObj
}

export const determinateURLParams = (url) => {
    let newObj = {
        type: url.type,
    }
    if (url.lessonID) {
        newObj.id = url.lessonID
    }
    if (url.topicID) {
        newObj.id = url.topicID
    }
    return newObj
}

export const determineCatIds = (resData, type) => {
    const catIds = []
    if (type === 'lesson') {
        resData.articles.forEach((item) => {
            catIds.push({
                catID: item.subCategory[0]?.categoryId,
                subCatID: item.subCategory[0]?.subCategoryId,
            })
        })
    } else if (type === 'topic') {
        resData.lessons.forEach((item) => {
            item.articles.forEach((obj) => {
                catIds.push({
                    catID: obj.subCategory[0]?.categoryId,
                    subCatID: obj.subCategory[0]?.subCategoryId,
                })
            })
        })
    } else if (type === 'grade') {
        resData?.topics.forEach((topic) => {
            topic?.lessons.forEach((item) => {
                item?.articles.forEach((obj) => {
                    catIds.push({
                        catID: obj.subCategory[0]?.categoryId,
                        subCatID: obj.subCategory[0]?.subCategoryId,
                    })
                })
            })
        })
    } else if (type === 'article') {
        catIds.push({
            catID: resData.subCategory[0]?.categoryId,
            subCatID: resData.subCategory[0]?.subCategoryId,
        })
    }

    return catIds
}

export const extractId = (layer) => layer.id
export const _filterByFeatureId = (feature) => (layer) => {
    const featuresID = feature.properties.id
    if (layer.filter[0] === 'all' && layer.filter[1][2] === featuresID) {
        return true
    } else {
        if (layer.filter[2] === featuresID) {
            return true
        }
    }
}

export const getShowByType = (categories, categoryId, subCategoryId, type) => {
    const categoryIndex = categories.findIndex((x) => x.id === categoryId)
    let subCategoryIndex = null
    let isShow = categories[categoryIndex].show
    if (type === 'subCategory') {
        subCategoryIndex = categories[categoryIndex].subCategories.findIndex(
            (x) => x.id === subCategoryId
        )
        isShow = categories[categoryIndex].subCategories[subCategoryIndex].show
    }
    return isShow
}

export const subCatFilterLogic = (categories, feature, id, isShow) => {
    let catIndex = categories.findIndex((x) => x.id === id)
    let subsDisabledFromCat = categories[catIndex]?.subCategories?.filter(
        (x) => x.show === isShow
    )

    if (
        subsDisabledFromCat.length ===
        categories[catIndex]?.subCategories.length
    ) {
        return true
    } else {
        const isContain = []
        feature.properties.subCategories.forEach((subs) => {
            let subID = subs.SubCategoryId
            if (subsDisabledFromCat.find((x) => x.id === subID) !== undefined) {
                isContain.push(true)
            }
        })
        if (isContain.length === feature.properties.subCategories.length) {
            return true
        }
    }
}

export const getSelectedYear = (time, timeLine) => {
    let fullYear = new Date(time).getFullYear()
    const maxDate = timeLine.current.range.options.max
        ? new Date(timeLine.current.range.options.max).getFullYear()
        : null
    if (maxDate) {
        let fYear = fullYear + 1
        if (fullYear < 0) {
            fYear = (fullYear + 1) * -1
            if (fYear < (maxDate < 0 ? maxDate * -1 : maxDate)) {
                fullYear = maxDate
                timeLine.current.removeCustomTime('t1')
                let newYear = new Date(
                    `${
                        fullYear < 0
                            ? `-${String(fullYear * -1).padStart(4, '0')}`
                            : String(fullYear).padStart(4, '0')
                    }`,
                    `01`,
                    `01`
                )
                timeLine.current.addCustomTime(newYear, 't1')
            }
        } else {
            if (fYear > maxDate) {
                fullYear = maxDate
                timeLine.current.removeCustomTime('t1')
                let newYear = new Date(
                    `${
                        fullYear < 0
                            ? `-${String(fullYear * -1).padStart(4, '0')}`
                            : String(fullYear).padStart(4, '0')
                    }`,
                    `01`,
                    `01`
                )
                timeLine.current.addCustomTime(newYear, 't1')
            }
        }
    }
    return fullYear
}
