import React from "react"
import { Route, Routes } from "react-router"
import Map from "../pages/map"
import Home from "../pages/home"
import Login from "../pages/login"
import Signup from "../pages/signup"
import Forgot from "../pages/forgot"
import ResetPassword from "../pages/resetPass"
import EmailCheck from "../pages/emailCheck"
import AccountInfo from "../pages/accountInfo"
import AccountBoards from "../pages/accountBoards"
import AccountPage from "../pages/accountPage"
import About from "../pages/about"
import EmailSend from "../pages/emailSend"
import ConfirmEmail from "../pages/confirmEmail"

const Navigation = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/map" element={<Map />} />
      <Route path="/about" element={<About />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/forgot" element={<Forgot />} />
      <Route path="/resetPass" element={<ResetPassword />} />
      <Route path="/email-check" element={<EmailCheck />} />
      <Route path="/email-send" element={<EmailSend />} />
      <Route path="/account-info" element={<AccountInfo />} />
      <Route path="/account-boards" element={<AccountBoards />} />
      <Route path="/account-page" element={<AccountPage />} />
      <Route path="/confirm-email" element={<ConfirmEmail />} />
    </Routes>
  )
}

export default Navigation
