import ErrorMsg from "../errorMsg";
import React from "react";
import { months, years } from "../../shared/mockData";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "./CalendarIcon";
import DatePicker, { registerLocale } from "react-datepicker";
import hy from "date-fns/locale/hy";
registerLocale("hy", hy);

export const MyDatePicker = ({
  contentClassName = "input-wrapper",
  className = "account-input",
  placeholder,
  onChange,
  value,
  hasError,
}) => {
  return (
    <div className={`${contentClassName} ${hasError ? "error" : ""}`}>
      <div className={className}>
        <DatePicker
          selected={value}
          locale="hy"
          dateFormat="dd.MM.yyyy"
          renderCustomHeader={({ date, changeYear, changeMonth }) => (
            <div
              style={{
                margin: 10,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <select
                value={months[months.find((x) => x === date)]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <select
                value={years.find((x) => x === date)}
                onChange={({ target: { value } }) => changeYear(value)}
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          )}
          minDate={new Date("01-01-1900")}
          maxDate={new Date()}
          placeholderText={placeholder}
          className={"date-picker"}
          onChange={onChange}
        />
      </div>
      {hasError && (
        <>
          <button className="error-btn" type="button" />
          <ErrorMsg text={hasError} />
        </>
      )}
      <CalendarIcon />
    </div>
  );
};
