import React from "react";

const Search = () => {
  return (
    <svg
      className="icon"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        d="M9.5,19A9.5,9.5,0,1,1,19,9.5,9.5,9.5,0,0,1,9.5,19Z"
        transform="translate(2 2)"
        fill="none"
      />
      <path d="M2,2,0,0" transform="translate(20 20)" fill="none" />
      <path d="M24,0V24H0V0Z" fill="none" opacity="0" />
    </svg>
  );
};
export default Search;
