import React from 'react'
import '@splidejs/splide/dist/css/splide.min.css'
import { connect } from 'react-redux'
import Plus from '../UI/Plus'
import Minus from '../UI/Minus'
import { setMapZoomIn, setMapZoomOut } from '../../store/actions/zoom'
import ArticlePopup from '../articlePopup'
import Compass from "../UI/Compass"
import {getMapZoomData} from "../../store/selectors";

const RightSide = (props) => {
    const { setMapZoomIn, setMapZoomOut } = props
    return (
        <>
            <div className="main__right right-side">
                <div className="zoom-btns">
                    <div className="main__tab">
                        <button
                            className="zoom-btn"
                            type="button"
                            onClick={setMapZoomOut}
                        >
                            <span className="main__tab-icon icon-wrapper">
                                <Minus />
                            </span>
                        </button>
                    </div>
                    <div className="main__tab">
                        <button
                            className="zoom-btn"
                            type="button"
                            onClick={setMapZoomIn}
                        >
                            <span className="main__tab-icon icon-wrapper">
                                <Plus />
                            </span>
                        </button>
                    </div>
                </div>
                <div className="empty-line" style={{height:'5vh'}}></div>
                <div className="main__tab" style={{"backgroundColor": "#0000"}}>
                    <Compass mapState={props.mapState} zoom={props.mapZoom}/>
                </div>
            </div>
            <ArticlePopup />
        </>
    )
}

const mapStateTopProps = (state) => ({
    mapZoom: getMapZoomData(state),
})

const mapDispatchToProps = {

    setMapZoomIn,
    setMapZoomOut,
}

export default connect(mapStateTopProps, mapDispatchToProps)(RightSide)
