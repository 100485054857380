import { formatLessonTime, formatServerResponseTime } from '../utils'

export const processTimeLineTopicItemsRendering = (data) => {
    const timeLineDataItem = []

    const { startFrom, endTo } = formatServerResponseTime(data)

    timeLineDataItem.push({
        id: data.id,
        order: 1,
        content: data.title,
        start: startFrom,
        end: endTo,
        className: 'isGroup',
        color: '#2c476c',
        startFrom: new Date(startFrom.setFullYear(startFrom.getFullYear() + 1)),
        endTo: new Date(endTo.setFullYear(endTo.getFullYear() + 1)),
    })
    // console.log(timeLineDataItem)
    data.lessons.forEach((lesson, index) => {
        const { lessonStartFrom, lessonEndTo } = formatLessonTime(lesson)
        timeLineDataItem.push({
            id: `${data.id}-${lesson.id}`,
            order: index + 2,
            content: lesson.title,
            nextLessonID:
                index + 1 === data.lessons.length
                    ? null
                    : data.lessons[index + 1].id,
            prevLessonID: index === 0 ? null : data.lessons[index - 1].id,
            start: lessonStartFrom,
            end: lessonEndTo,
            color: '#2c476c',
            groupId: data.id,
        })
    })
    return timeLineDataItem
}
