import axios from 'axios'

const extractData = (res) => {
    const { data } = res
    if (data.accepted && data.data[0]) {
        if (data.data && data.data[0]) {
            return data.data[0]
        }
    }
    return []
}

const sanitizeFilteredSelectedResult = (data) => {
    return {
        id: data.id,
        show: true,
        articleData: data,
    }
}

const selectAPIRequest = (id) => {
    return axios
        .get(
            `${process.env.REACT_APP_INDEXING_URL}/api/Map/Preview?articleId=${id}`
        )
        .then(extractData)
}

export const getArticleAPI = (id) => {
    return selectAPIRequest(id)
        .then(sanitizeFilteredSelectedResult)
        .catch(console.error)
}
