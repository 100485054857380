export const checkValidationErrors = (fields) => {
  let errors = {}
  let hasError = false

  //firstName
  if (!fields["firstName"]) {
    hasError = true
    errors["firstName"] = "Այս դաշտը պարտադիր է։ Խնդրում ենք լրացնել։"
  }

  //lastName
  if (!fields["lastName"]) {
    hasError = true
    errors["lastName"] = "Այս դաշտը պարտադիր է։ Խնդրում ենք լրացնել։"
  }

  //Email
  if (!fields["email"]) {
    hasError = true
    errors["email"] = "Այս դաշտը պարտադիր է։ Խնդրում ենք լրացնել։"
  }

  if (typeof fields["email"] !== "undefined") {
    let lastAtPos = fields["email"].lastIndexOf("@")
    let lastDotPos = fields["email"].lastIndexOf(".")

    if (
      !(
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        fields["email"].indexOf("@@") == -1 &&
        lastDotPos > 2 &&
        fields["email"].length - lastDotPos > 2
      )
    ) {
      hasError = true
      errors["email"] =
        "Ձեր էլ․ հասցեն վավեր չէ։ Խնդրում ենք մուտքագրել վավեր էլ․ հասցե։"
    }
  }

  //speciality
  if (!fields["speciality"]?.value) {
    hasError = true
    errors["speciality"] = "Այս դաշտը պարտադիր է։ Խնդրում ենք լրացնել։"
  }

  //Password
  if (!fields["password"]) {
    hasError = true
    errors["password"] = "Այս դաշտը պարտադիր է։ Խնդրում ենք լրացնել։"
  }

  if (fields["password"]?.length < 6) {
    hasError = true
    errors["password"] =
      "Խնդրում ենք մուտքագրել վավեր գաղտնաբառ։ Ձեր գաղտնաբառը պետք է լինի առնվազն 6 նիշ" +
      " երկարությամբ:"
  }

  //CPassword
  if (!fields["cPassword"]) {
    hasError = true
    errors["cPassword"] = "Այս դաշտը պարտադիր է։ Խնդրում ենք լրացնել։"
  }

  if (fields["password"] !== fields["cPassword"]) {
    hasError = true
    errors["cPassword"] = "Խնդրում ենք մուտքագրել վավեր գաղտնաբառ։"
  }

  return hasError ? errors : null
}
