import {
    SET_TIMELINE_ITEMS,
    SET_TIMELINE_GROUPS,
    LOADING_TIMELINE,
} from '../constants'

const initialState = {
    data: [],
    isLoading: true,
}

export const handleTimeLineItemsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TIMELINE_ITEMS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            }
        case LOADING_TIMELINE: {
            return { ...state, isLoading: true }
        }
        default:
            return state
    }
}

export const handleTimeLineGroupsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TIMELINE_GROUPS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            }
        case LOADING_TIMELINE: {
            return { ...state, isLoading: true }
        }
        default:
            return state
    }
}
