export const processTimeLineLessonGroupRendering = (data, categories) => {
    let timeLineSelectedDataGroup = []

    data.forEach((item, index) => {
        let cat = categories.find((x) => x.id === item.category[0].categoryId)
        timeLineSelectedDataGroup.push({
            id: item.isLamp ? 111 : index + 1,
            catId: item.isLamp ? 222 : item?.category[0]?.categoryId,
            catIcon: cat?.iconPath,
            content: cat?.title,
            className: 'isLesson',
            color: cat?.color,
            show: true,
            isLamp: item.isLamp,
        })
    })
    const uniqueIds = []

    return timeLineSelectedDataGroup
        .filter((element) => {
            const isDuplicate = uniqueIds.includes(element.catId)

            if (!isDuplicate) {
                uniqueIds.push(element.catId)
                return true
            }
        })
        .sort((a, b) => (a.catId < b.catId ? 1 : -1))
}
