export const checkValidationErrors = (fields) => {
  let errors = {};
  let hasError = false;

  if (!fields["email"]) {
    hasError = true;
    errors["email"] = "Այս դաշտը պարտադիր է։ Խնդրում ենք լրացնել։";
  }

  if (typeof fields["email"] !== "undefined") {
    const lastAtPos = fields["email"].lastIndexOf("@");
    const lastDotPos = fields["email"].lastIndexOf(".");

    if (
      !(
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        fields["email"].indexOf("@@") == -1 &&
        lastDotPos > 2 &&
        fields["email"].length - lastDotPos > 2
      )
    ) {
      hasError = true;
      errors["email"] =
        "Ձեր էլ․ հասցեն վավեր չէ։ Խնդրում ենք մուտքագրել վավեր էլ․ հասցե։";
    }
  }

  //Name
  if (!fields["password"]) {
    errors["password"] = "Այս դաշտը պարտադիր է։ Խնդրում ենք լրացնել։";
  }

  return hasError ? errors : null;
};
