import { SET_LAMP, LOADING_LAMP } from '../constants'

export const loadingLamp = () => ({
    type: LOADING_LAMP,
})

export const setLamp = (articles) => ({
    type: SET_LAMP,
    payload: articles,
})

const parseLampData = (data, type) => {
    const lamp = []
    if (type === 'lesson') {
        let articles = data.articles
        articles.forEach((article) => {
            if (article.isLamp) {
                lamp.push(article)
            }
        })
    }
    if (type === 'topic') {
        let lesson = data.lessons
        lesson.forEach((item) => {
            item.articles.forEach((article) => {
                if (article.isLamp) {
                    lamp.push(article)
                }
            })
        })
    }
    if (type === 'article') {
        if (data.isLamp) {
            lamp.push(data)
        }
    }
    return lamp
}

export const getLampInfo = (data, type) => (dispatch) => {
    dispatch(loadingLamp())

    const lampData = parseLampData(data, type)
    dispatch(setLamp(lampData))
}
