import React, { Component, useEffect, useState } from 'react'
import './style.css'
// import { VisTimeline } from "vis-timeline";
import 'vis-timeline/styles/vis-timeline-graph2d.css'
import * as vis from 'vis-timeline'
import TimeLineExtend from '../UI/TimeLineExtend'
import ArrowPrev from '../UI/ArrowPrev'
import ArrowNext from '../UI/ArrowNext'
import Eye from '../UI/Eye'
import PageLoader from '../UI/PageLoader'
import {
    getIsTimeLineLoading,
    getNextLessonIDData,
    getPrevLessonIDData,
    getSelectedLessonId,
    getTimeLineExpendData,
    getTimeLineGroupsData,
    getTimeLineItemsData,
    getTimeLineOptionsData,
} from '../../store/selectors'
import Reset from '../UI/Reset'
import { connect } from 'react-redux'
import { setTimeLineExpend } from '../../store/actions/timeLineExpend'
import { getArticleFromAPI } from '../../store/actions/articles'
import { setSelectedLesson } from '../../store/actions/handleLesson'
import Lamp from "../UI/Lamp";
import LampIcon from "../../assets/imgs/lamp-icon.svg";
import IconEyeShow from "../../assets/imgs/icon-eye-show.svg";
import IconEyeHide from "../../assets/imgs/icon-eye-hide.svg";

const Timeline = (props) => {
    const timeLineContainer = React.createRef()

    useEffect(() => {
        if (props.timeLine.current) return
        props.timeLine.current = new vis.Timeline(
            timeLineContainer.current,
            props.timeLineItems,
            props.timeLineOptions
        )
    }, [])

    useEffect(() => {
        if (props.timeLineItems.length > 0) {
            setTimeout(() => {
                props.timeLine.current.setData({
                    items: props.timeLineItems,
                })
            }, 0)
        }
    }, [props.timeLineLoading])

    useEffect(() => {
        if (props.selectedLesson !== null) {
            props.timeLine.current.setData({
                items: [],
                groups: [],
            })
        }
    }, [props.selectedLesson])

    const changeURIParams = (id) => {
        let url = new URL(window.location.href)
        let params = new window.URLSearchParams(window.location.search)
        params.set('lessonID', id)
        url.search = params
        url = url.toString()
        window.history.replaceState({ url: url }, null, url)
        window.location.reload()
    }

    const toggleMapTimeline = () => {
        props.setTimeLineExpend(!props.timelineExtend)
        props.timeLine.current.setOptions({
            minHeight: !props.timelineExtend
                ? "230px"
                : "90px",
        });
        props.timeLine.current.redraw()
    }

    const {
        selectedLesson,
        getArticleFromAPI,
        goToTopics,
        moveLeft,
        moveRight,
        timelineExtend,
        dataType,
        timeLineLoading,
        timeLineItems,
        timeLineGroups,
        prevLesson,
        setSelectedLesson,
        nextLesson,
        toggleItemsFromMap,
    } = props

    return (
        <footer
            className={`footer timeline ${timelineExtend ? 'expand' : ''} ${
                selectedLesson !== null ? 'lesson-timeline' : ''
            }`}
            style={{
                backgroundColor:
                    selectedLesson !== null ? '#f3f3f3' : '#ffffff',
            }}
        >
            <div className="timeline__btns">
                <button
                    className="timeline__btn timeline__expand"
                    type="button"
                    onClick={() => toggleMapTimeline()}
                >
                    {<TimeLineExtend show={timelineExtend} />}
                </button>
                {props.selectedLesson !== null &&
                    props.dataType === 'lesson' && (
                        <button
                            className="timeline__btn"
                            type="button"
                            onClick={props.handleChangeToInitialState}
                        >
                            {<Reset />}
                        </button>
                    )}
            </div>
            {selectedLesson !== null && dataType !== 'lesson' && (
                <>
                    <button
                        className={`lesson-back ${
                            timeLineLoading ? 'disable-timeline' : ''
                        }`}
                        type="button"
                        onClick={() => goToTopics()}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="7.725"
                            height="15.321"
                            viewBox="0 0 7.725 15.321"
                        >
                            <g transform="translate(13.561 17.661) rotate(180)">
                                <path
                                    d="M5.915,13.2.481,7.767a1.655,1.655,0,0,1,0-2.333L5.915,0"
                                    transform="translate(6.585 3.4)"
                                    fill="none"
                                    stroke="#292d32"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeMiterlimit="10"
                                    strokeWidth="1.5"
                                    strokeDasharray="0 0"
                                />
                            </g>
                        </svg>
                    </button>
                </>
            )}
            {selectedLesson !== null && (
                <>
                    {prevLesson !== null ? (
                        <button
                            className="timeline__arrow timeline__lesson_prev"
                            type="button"
                            style={{ bottom: timelineExtend ? 195 : 50 }}
                            onClick={() => setSelectedLesson(prevLesson)}
                        >
                            <ArrowPrev />
                        </button>
                    ) : null}
                    {nextLesson !== null ? (
                        <button
                            className="timeline__arrow timeline__lesson_next"
                            type="button"
                            onClick={() => setSelectedLesson(nextLesson)}
                            style={{
                                right: 0,
                                bottom: timelineExtend ? 195 : 50,
                            }}
                        >
                            <ArrowNext />
                        </button>
                    ) : null}
                    <div
                        className={`lesson ${
                            timeLineLoading ? 'disable-timeline' : ''
                        }`}
                        style={{
                            height: !timelineExtend ? '35px' : '100%',
                        }}
                    >
                        <div className="lesson__names">
                            <p className="lesson__name">
                                {
                                    timeLineItems.find(
                                        (x) => x.className === 'isGroup'
                                    )?.content
                                }
                            </p>
                        </div>
                        <div
                            className="lesson__content"
                            style={{
                                visibility: timelineExtend
                                    ? 'visible'
                                    : 'hidden',
                            }}
                        >
                            <div className="lesson__content-catwrap custom-scroll">
                                <div className="lesson__content-categories">
                                    {timeLineGroups.map((item, index) => {
                                        return (
                                            <div
                                                className="lesson__content-cat"
                                                key={item.id}
                                            >
                                                <label className="main__subitem-label">
                                                    <button
                                                        onClick={() =>
                                                            toggleItemsFromMap(
                                                                item.catId,
                                                                null,
                                                                'category'
                                                            )
                                                        }
                                                    >
                                                        <input
                                                            className="main__subitem-checkbox show-checkbox"
                                                            type="checkbox"
                                                            defaultChecked={
                                                                item?.show
                                                            }
                                                        />
                                                        <Eye
                                                            show={item?.show}
                                                        />
                                                    </button>
                                                    <span
                                                        className="lesson__content-svg"
                                                        style={{
                                                            opacity: item?.show
                                                                ? 1
                                                                : 0.5,
                                                        }}
                                                    >

                              {!item?.isLamp ? (
                                  <img
                                      src={item?.catIcon}
                                      style={{
                                          width: 25,
                                          height: 25,
                                      }}
                                  />
                              ) : (
                                  <img
                                      src={LampIcon}
                                      style={{
                                          width: "auto",
                                          height: 25,
                                      }}
                                  />
                              )}
                                                    </span>
                                                </label>
                                                <div className="lesson__content-items persons custom-scroll2">
                                                    {timeLineItems
                                                        .filter(
                                                            (x) =>
                                                                (item.isLamp && x.isLamp) ||
                                                                (!x.isLamp &&
                                                                    x.catId === item.catId &&
                                                                    x.className === "isInner")
                                                        )
                                                        .map((article) => {
                                                            return (
                                                                <div
                                                                    className="lesson__content-item"
                                                                    key={
                                                                        article.id
                                                                    }
                                                                    style={{
                                                                        backgroundColor:
                                                                            article.color
                                                                                ? article.color
                                                                                : '',
                                                                        opacity:
                                                                            article.show
                                                                                ? 1
                                                                                : 0.5,
                                                                    }}
                                                                    onClick={() =>
                                                                        getArticleFromAPI(
                                                                            article.articleId
                                                                        )
                                                                    }
                                                                >
                                                                    <span className="lesson__content-name">
                                                                        {article.isLamp ? (
                                                                            <img
                                                                                src={LampIcon}
                                                                                style={{
                                                                                    width: 15,
                                                                                    height: 20,
                                                                                    marginRight: 10,
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <img
                                                                                src={article?.catIcon}
                                                                                style={{
                                                                                    width: 20,
                                                                                    height: 20,
                                                                                    marginRight: 10,
                                                                                }}
                                                                            />
                                                                        )}
                                                                        {
                                                                            article.content
                                                                        }
                                                                    </span>
                                                                </div>
                                                            )
                                                        })}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <div
                ref={timeLineContainer}
                className={`timeline__content ${
                    selectedLesson !== null ? 'inner' : ''
                } ${timeLineLoading ? 'disable-timeline' : ''}`}
                style={{
                    height: timelineExtend
                        ? "230px"
                        : "90px",
                    opacity: timeLineLoading ? 0 : 1,
                }}
            />
            {timeLineLoading ? (
                <div
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <PageLoader />
                </div>
            ) : null}
            {selectedLesson === null && (
                <>
                    <button
                        className={`timeline__arrow timeline__prev ${
                            timeLineLoading ? 'disable-timeline' : ''
                        }`}
                        type="button"
                        style={{ bottom: 0 }}
                        onClick={() => moveLeft()}
                        disabled={timeLineLoading}
                    >
                        <ArrowPrev />
                    </button>
                    <button
                        className={`timeline__arrow timeline__next ${
                            timeLineLoading ? 'disable-timeline' : ''
                        }`}
                        type="button"
                        onClick={() => moveRight()}
                        style={{ right: 0, bottom: 0 }}
                        disabled={timeLineLoading}
                    >
                        <ArrowNext />
                    </button>
                </>
            )}
        </footer>
    )
}

const mapStateTopProps = (state) => ({
    timelineExtend: getTimeLineExpendData(state),
    timeLineOptions: getTimeLineOptionsData(state),
    prevLesson: getPrevLessonIDData(state),
    nextLesson: getNextLessonIDData(state),
    timeLineItems: getTimeLineItemsData(state),
    timeLineGroups: getTimeLineGroupsData(state),
    timeLineLoading: getIsTimeLineLoading(state),
    selectedLesson: getSelectedLessonId(state),
})

const mapDispatchToProps = {
    setTimeLineExpend,
    getArticleFromAPI,
    setSelectedLesson,
}

export default connect(mapStateTopProps, mapDispatchToProps)(Timeline)
