import './style.css'
import {memo, useCallback, useEffect, useRef, useState} from "react";
import {findDOMNode} from "react-dom";
import mapboxgl from "mapbox-gl";
import login from "../../../pages/login";
let repeat = 0
let repeatScale = 0
let change = false
const ringInitialDefault = {
    active:true,
    animate:false,
    style:{"transform": "rotate(0rad)"},
    dragFinish:0,
    drag:0,
    startDeg:false
}
const Compass = ({mapState,zoom}) => {
    const tiltedRef  = useRef()
    const ringRef  = useRef()
    //map drag and drop functions
        function handleDragMapRing (e){
            if(e?.originalEvent?.which === 3){
                if(ringRef.current.getAttribute("data-active") === 'true') {
                    ringRef.current.setAttribute ("data-active", 'false')
                }
                let newAngle = parseInt(((e.target.transform.angle/3.14*180) + (e.target.transform.angle > 0 ? 0 : 360) ))
                if(newAngle > 180){
                    newAngle -= 360
                }
                ringRef.current.setAttribute("data-drag",newAngle)
                ringRef.current.setAttribute ("data-start-deg", newAngle)
                ringRef.current.style.transform = `rotate(${newAngle}deg)`
            }
        }
        function  handleDragMapTilt(e){
            if(zoom < 4 && e?.originalEvent?.which !== 3) return
            const max = 50
            const min = 4
            if(!change){
                change = true
            }
            if (tiltedRef.current.getAttribute ("data-active") === 'true') {
                tiltedRef.current.setAttribute ("data-active", 'false')
            }
            let newPitch = e.target.transform.pitch

            if((newPitch >= 0)
                && (newPitch <=max)
                && repeat !== newPitch){
                repeat = newPitch
                tiltedRef.current.style.transform = `rotateX(${newPitch}deg)`
            }

            if(newPitch <= min){
                change = false
                tiltedRef.current.setAttribute("data-active", 'true')
                handleTilt3D()

            }else if(newPitch > min){
                handleTilt2D()
            }
        }
        //tilt drag and drop functions

        function dragMouseDown(e) {
            if(zoom<4) return
            //subscribe events mouseup and  mousemove on document
            e = e || window.event;
            e.preventDefault();
            // call a function whenever the mouse up:
            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves:
            document.onmousemove = elementDrag;
            tiltedRef.current.setAttribute("data-animate", 'false')
            tiltedRef.current.onmouseup = handlePosTilt
        }
        function  handleTilt2D (){
            tiltedRef.current.innerHTML = "2D"
            tiltedRef.current.style.backgroundColor = "#196dff"
            tiltedRef.current.style.color = "#fff"
        }
        function  handleTilt3D (){
            tiltedRef.current.style.transform  = `rotateX(0deg)`
            tiltedRef.current.innerHTML = "3D"
            tiltedRef.current.style.backgroundColor = "#fff"
            tiltedRef.current.style.color = "#2C476C"
        }
        function handlePosTilt(){
            tiltedRef.current.setAttribute("data-animate", 'true')
                //if attribute data is false set css with 2d
                if(tiltedRef.current.getAttribute("data-active") === 'true')
                {
                    change = true
                    handleTilt2D()
                    tiltedRef.current.style.transform  = `rotateX(50deg)`
                    tiltedRef.current.setAttribute("data-active", 'false')
                    mapState.flyTo({
                        pitch: 50,
                        essential: true
                    })
                    return
                }
                //set data attribute true for next call
                tiltedRef.current.setAttribute("data-active", 'true')
                //if attribute data is true set css with 3d
                change = false
                handleTilt3D()
                mapState.flyTo({
                    pitch: 0,
                    essential: true
                })
        }
        function elementDrag(e) {
            e = e || window.event;
            e.preventDefault();
            if(!change){
                change = true
            }
            //if data attribute active set false value for first click in compass  return 3d styles
            if(tiltedRef.current.getAttribute("data-active") === 'true'){
                tiltedRef.current.setAttribute("data-active", 'false')
            }
            // get element cordinates
            let cordinates = tiltedRef.current.getBoundingClientRect()
            // calculate the new cursor position:
            let dragY = e.pageY - (cordinates.y + cordinates.width/2);
            if(window.screen.width>=900 && window.screen.width <= 1800){
                dragY += cordinates.y*30/100
            }
            if(tiltedRef.current.onmouseup !== null){
                tiltedRef.current.onmouseup = null
            }
            // set the element's new position:
            if( dragY > 0 ) dragY = -0.1
            let newAngle = Math.atan2(-dragY,15)
            newAngle=((newAngle/3.14*100) + (newAngle > 0 ? 0 : 50))

            const max = 50
            const min = 4
            if( dragY!== 0
                && (newAngle >= 0)
                && (newAngle <=max)
                && repeat !== newAngle){
                repeat = newAngle
                tiltedRef.current.style.transform  = `rotateX(${newAngle}deg)`
                mapState.setPitch(newAngle)
            }

            if(newAngle <= min){
                change = false
                tiltedRef.current.setAttribute("data-active", 'true')
                handleTilt3D()

            }else if(newAngle > min){
                handleTilt2D()
            }
        }

        //ring drag and drop functions
        function dragRingMouseDown(e) {
            //subscribe events mouseup and  mousemove on document
            e = e || window.event;
            e.preventDefault();
            // call a function whenever the mouse up:
            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves:
            document.onmousemove = handleRingDrag;
            // data animate is transition here we are closa transition for floating rotate with drag
            ringRef.current.setAttribute("data-animate", 'false')
            //if we are don't drag  this function call click evenet and set fix cordinates
            ringRef.current.onmouseup = handleSetFixCordinateRing

        }
        function handleRingDrag(e) {
            e.preventDefault();
            let dragFinish = ringRef.current.getAttribute("data-drag-finish")
            let cordinates = ringRef.current.getBoundingClientRect()
            let dragX =  e.pageX - (cordinates.x + cordinates.width/2),
                dragY = e.pageY - (cordinates.y + cordinates.height/2) ;
            if(window.screen.width>=900 && window.screen.width <= 1800){
                dragX +=  cordinates.x*30/100
                dragY += cordinates.y*30/100
            }
            let newAngle = Math.atan2(  dragY,   dragX)
            newAngle=parseInt(((newAngle/3.14*180) + (newAngle > 0 ? 0 : 360) ))
            if(ringRef.current.getAttribute ("data-start-deg") === 'false') {
               ringRef.current.setAttribute ("data-start-deg", newAngle)
            }
            if(ringRef.current.getAttribute("data-active") === 'true') {
                ringRef.current.setAttribute ("data-active", 'false')
            }
            if(ringRef.current.onmouseup !== null){
                ringRef.current.onmouseup = null

            }
            let startAt = ringRef.current.getAttribute ("data-start-deg")
            let res = (newAngle-startAt) + parseFloat(dragFinish)
            if(res > 359){
                res -= 359
            }
            mapState.rotateTo(`${-res}.0`,{duration:0})

            ringRef.current.setAttribute("data-drag",res)
            ringRef.current.style.transform = `rotate(${res}deg)`
        }
        function handleSetFixCordinateRing(){
            //set animation transition for floating auto rotate
            ringRef.current.setAttribute("data-animate", 'true')
            //set fix cordinates
            if(ringRef.current.getAttribute("data-active") === 'true')
            {
                ringRef.current.setAttribute("data-drag", -45)
                ringRef.current.style.transform  = `rotate(-45deg)`
                ringRef.current.setAttribute("data-active", 'false')
                mapState.rotateTo(`${45}.0`,{duration:500})
            }else if(ringRef.current.getAttribute("data-active") === 'false'){
                ringRef.current.setAttribute("data-active", 'true')
                if(ringRef.current.getAttribute("data-drag") > 180){
                    mapState.rotateTo(`${360}.0`,{duration:500})
                    ringRef.current.setAttribute("data-drag", 360)
                    return ringRef.current.style.transform  = `rotate(360deg)`

                }
                mapState.rotateTo(`0`,{duration:500})
                ringRef.current.setAttribute("data-drag", 0)
                    return ringRef.current.style.transform  = `rotate(0deg)`
            }

    }

        //close all subscriptions
        function closeDragElement() {
        /* stop moving when mouse button is released:*/
            document.onmouseup = null;
            document.onmousemove = null;
            tiltedRef.current.onmouseup = null
            ringRef.current.onmouseup = null
            let drag = ringRef.current.getAttribute("data-drag")
            ringRef.current.setAttribute ("data-drag-finish", drag)
            ringRef.current.setAttribute ("data-start-deg", 'false')
        }

        const handleChangeZoom = (zoomNow) => {
            if(!change) return
            if(zoomNow < 4) {
                change = false
                setTimeout(()=>{
                    tiltedRef.current.setAttribute("data-active", 'true')
                    mapState.flyTo({
                        pitch: 0,
                        speed:0.3,
                        essential: true
                    })
                    mapState.scrollZoom.disable()
                    setTimeout(()=>{
                        mapState.scrollZoom.enable()
                    },400)
                    handleTilt3D()

                },500)
            }
        }

        const handleRingToInitialDefault =  ()=>{
            const element = ringRef.current
                element.style = ringInitialDefault.style
                element.setAttribute("data-active", ringInitialDefault.active)
                element.setAttribute("data-animate", true)
                element.setAttribute("data-drag-finish", ringInitialDefault.dragFinish)
                element.setAttribute("data-drag", ringInitialDefault.drag)
                element.setAttribute("data-start-deg", ringInitialDefault.startDeg)
                setTimeout(()=>{
                    element.setAttribute("data-animate", ringInitialDefault.animate)
                },500)
        }

        useEffect(()=>{
            if(mapState){
                mapState.on('zoom', (e) => {
                    handleChangeZoom(e.target.transform._zoom)
                    if(e.target.transform._zoom > 4) {
                        tiltedRef.current.setAttribute("data-get-start", 'true')
                        mapState.dragRotate._mousePitch.enable()
                    }else {
                        tiltedRef.current.setAttribute("data-get-start", 'false')
                        mapState.dragRotate._mousePitch.disable()
                    }
                })

            }
        },[mapState])
        useEffect(()=>{
            if(mapState && typeof mapState?.transform?.resetCompass !== 'function') {
                mapState.transform.resetCompass = handleRingToInitialDefault
            }
        },[mapState])
        useEffect(()=>{
            if(mapState !== null){
                mapState.on('mousedown',()=>{
                    ringRef.current.setAttribute("data-animate", 'false')
                    tiltedRef.current.setAttribute("data-animate", 'false')
                    mapState.on('rotate',handleDragMapRing)
                    mapState.on('pitch',handleDragMapTilt)
                })
                mapState.on('mouseup',()=> {
                    let drag = ringRef.current.getAttribute("data-drag")
                    console.log (drag,'sdf')
                    ringRef.current.setAttribute ("data-drag-finish", +drag)
                    ringRef.current.setAttribute ("data-start-deg", 'false')
                    mapState.off('rotate',handleDragMapRing)
                    mapState.off('pitch',handleDragMapTilt)
                })
            }
        },[mapState])

    return (
        <div className="map-controls-view__control-group">
            <div className="map-controls-view__tilt-rotate-control">
                <div className="map-tilt-rotate-control" aria-hidden="true">
                    <div className="map-tilt-rotate-control__tilt-ring">
                        <div aria-hidden="true" className="map-tilt-rotate-control__ring"
                             data-active={ringInitialDefault.active}
                             data-animate={ringInitialDefault.animate}
                             style={ringInitialDefault.style}
                             onMouseDown={dragRingMouseDown}
                             onDragStart={()=>dragRingMouseDown()}
                             draggable="true"
                             data-drag-finish={ringInitialDefault.dragFinish}
                             data-drag={ringInitialDefault.drag}
                             data-start-deg={ringInitialDefault.startDeg}
                             ref={ringRef}
                        ></div>
                    </div>
                    <div className="map-tilt-rotate-control__tilt-wrapper" >
                        <div aria-hidden="true" className="map-tilt-rotate-control__tilt _tilted"
                             data-active="true"
                             onDragStart={dragMouseDown}
                             onMouseDown={dragMouseDown}
                             data-animate="false"
                             ref={tiltedRef}
                             draggable="true"
                             style={{"transform": "rotateX(0deg)"}}
                             data-get-start='false'
                        >

                            3D
                        </div>
                    </div>
                </div>
                <div data-chunk="tooltip" data-ssr-uid="97">
                    <div></div>
                </div>
            </div>
        </div>
    )
}
export default memo(Compass);
