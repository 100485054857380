import React, { useState } from 'react'
import { getArticlesData, getIsArticlesLoading } from '../../store/selectors'
import { connect } from 'react-redux'
import ArticleItem from '../UI/ArticleItem'
import Gallery from '../gallery'

const ArticlePopup = (props) => {
    const [showGallery, setShowGallery] = useState(false)
    const [clickedMediaImage, setClickedMediaImage] = useState(null)

    const toggleGallery = (id) => {
        setShowGallery(!showGallery)
        setClickedMediaImage(id)
    }
    const { articleData, articleLoading } = props

    return (
        <>
            {showGallery && (
                <Gallery
                    title={articleData?.find((x) => x.show === true)?.articleData
                        ?.content[0].shortTitle}
                    gallery={showGallery}
                    toggleGallery={() => toggleGallery()}
                    clickedMediaImage={clickedMediaImage}
                    feauturedImage={
                        articleData?.find((x) => x.show === true)?.articleData
                            ?.content[0].featuredImage
                    }
                    galleryItems={
                        articleData?.find((x) => x.show === true)?.articleData
                            ?.gallery.sort((a, b) => a.order - b.order)?.filter((x) => x.type !== 4)
                    }
                />
            )}

            <div className="articles-grid">
                {articleData.map((item) =>
                    articleLoading ? (
                        <div>Loading...</div>
                    ) : (
                        <ArticleItem
                            key={item.id}
                            article={item}
                            toggleGallery={(id) => toggleGallery(id)}
                        />
                    )
                )}
            </div>
        </>
    )
}
const mapStateTopProps = (state) => ({
    articleData: getArticlesData(state),
    articleLoading: getIsArticlesLoading(state),
})

const mapDispatchToProps = {}

export default connect(mapStateTopProps, mapDispatchToProps)(ArticlePopup)
