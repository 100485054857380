import React, { Component } from "react";
import "./style.css";
import About from "../about";
import { Link } from "react-router-dom";

class Account extends Component {
  constructor() {
    super();
    this.state = {
      about: false,
    };
  }

  render() {
    const { onlyBG, children } = this.props;
    const { about } = this.state;
    const { setState } = this;
    return (
      <>
        <div className="account">
          {!onlyBG && (
            <header className="account-header">
              <h1 className="account-title">աշխարհացույց</h1>
              <button
                className="account-about"
                type="button"
                onClick={() => setState({ about: !about })}
              >
                նախագծի մասին
              </button>
            </header>
          )}
          {children}
          {!onlyBG && (
            <footer className="account-footer">
              <p className="account-powered">powered by brainograph</p>
            </footer>
          )}
        </div>
        {about && <About handleAbout={() => setState({ about: !about })} />}
      </>
    );
  }
}

export default Account;
