import ReactDOM from "react-dom";
import GroupTemplate from "../components/GroupTemplate";
import ItemTemplate from "../components/ItemTemplate";

export const loginURI = `${process.env.REACT_APP_IDENTITY_URL}/api/User/SignIn`;
export const uploadURI = `${process.env.REACT_APP_MEDIA_CONTENT_URL}/api/Upload`;
export const userInfoURI = `${process.env.REACT_APP_IDENTITY_URL}/api/User`;
export const updateUserInfoURI = `${process.env.REACT_APP_IDENTITY_URL}/api/User`;
export const changeUserPasswordURI = `${process.env.REACT_APP_IDENTITY_URL}/api/User/ChangePassword`;

export const getCategoriesAPIParams = () => {
  return {
    url: `${process.env.REACT_APP_ARTICLE_URL}/api/Category/DataProvider/1`,
    body: {
      page: 1,
      perPage: 50,
      includeLanguage: true,
      includeSubCategory: true,
      includeAttribute: true,
    },
  };
};

export const mapRequirements = [
    {
        id: 1,
        value: 'all',
        title: 'Բոլորը',
        active: true
    },
    {
        id: 2,
        value: 0,
        title: 'Հիմնական',
        active: false
    },
    {
        id: 3,
        value: 1,
        title: 'Հավելյալ',
        active: false
    }
]

export const mapStyles = [
  {
    id: 1,
    value: "physical",
    title: "Ֆիզիկական",
    style: "mapbox://styles/brainograph/ckyypimuc004114s0jxn3nytt",
    active: true,
  },
  {
    id: 2,
    value: "satellite",
    title: "Արբանյակային",
    style: "mapbox://styles/brainograph/ckyypqrvd000j15lb9osv5hda",
    active: false,
  },
  {
    id: 3,
    value: "politics",
    title: "Քաղաքական",
    style: "mapbox://styles/brainograph/ckyypjczl001w15qrx5woidl5",
    active: false,
  },
  {
    id: 4,
    value: "terrain",
    title: "Տեղանքային",
    style: "mapbox://styles/brainograph/cl3bx9ey8003b14rxe6nnha51",
    active: false,
  },
];

export const typesNumbers = [
  "Pin",
  "River",
  "Lake",
  "Sea",
  "Ocean",
  "Country",
  "Other",
];

export const timeLineDataItems = [];

export const timeLineDataGroup = [];

export const timeLineSelectedDataItems = [];

export const timeLineSelectedDataGroup = [];

export const specialityOptions = [
  {
    value: 1,
    text: "Teacher",
    label: "Ուսուցիչ",
  },
  {
    value: 3,
    text: "Student",
    label: "Աշակերտ",
  },
];

export const genderOptions = [
  {
    value: "Female",
    label: "Իգական",
  },
  {
    value: "Male",
    label: "Արական",
  },
];

export const timeLineOptions = {
    autoResize: false,
    stack: true,
    showCurrentTime: false,
    horizontalScroll: true,
    verticalScroll: true,
    zoomKey: "ctrlKey",
    maxHeight: '230px',
    minHeight:window.innerWidth > 800 && window.innerWidth < 1800 ? '160px'  :  "230px",
  zoomMin: 315569520000,
    // groupHeightMode: 'fixed',
    format: {
        minorLabels: function (a) {
            if (a.format('YYYY').includes('-')) {
                return 'Ք.ա. ' + a.format('y').replace('-', '')
            }
            return  a.format('y')
        }
    },
    orientation: {
      item: "top",
      axis: "bottom",
    },
    order: function (a, b) {
      return a.order - b.order;
    },
    groupTemplate: function (group, element) {
      return ReactDOM.createPortal(
        ReactDOM.render(<GroupTemplate group={group} />, element),
        element
      );
    },
    template: function (item, element) {
      return ReactDOM.createPortal(
        ReactDOM.render(<ItemTemplate item={item} />, element),
        element
      );
    },
};

export const dropDownCustomAccountStyles = {
  control: (base, state) => ({
    ...base,
    width: "100%",
    background: "transparent",
    // match with the menu
    // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    borderColor: "#707070",
    borderRadius: 20,
    color: "#2c476c",
    marginBottom: "5px",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: "#707070",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#2c476c" : "black",
    background: state.isSelected ? "#f3bfda" : "transparent",
    "&:hover": {
      // Overwrittes the different states of border
      background: "#f3bfda",
    },
  }),
  menu: (base) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 5,
    overflow: "hidden",
    background: "rgba(255, 255, 255,)",
    color: "white",
    // kill the gap
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),
  singleValue: (base) => ({
    ...base,
    paddingLeft: 10,
    color: "#2c476c",
  }),
  placeholder: (base, state) => ({
    ...base,
    paddingLeft: 10,
    color: state.isSelected ? "#2c476c" : "#2c476c",
  }),
};

export const dropDownCustomStyles = {
  control: (base, state) => ({
    ...base,
    background: "transparent",
    // match with the menu
    // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    borderColor: "transparent",
    color: "white",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: "transparent",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "white" : "black",
    background: state.isSelected ? "#f3bfda" : "transparent",
    "&:hover": {
      // Overwrittes the different states of border
      background: "#f3bfda",
    },
  }),
  menu: (base) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 5,
    overflow: "hidden",
    background: "rgba(255, 255, 255,)",
    color: "white",
    // kill the gap
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),
  singleValue: (base) => ({
    ...base,
    paddingLeft: 40,
    color: "white",
  }),
  placeholder: (base, state) => ({
    ...base,
    paddingLeft: 40,
    color: state.isSelected ? "white" : "white",
  }),
};

export const years = [...Array(new Date().getFullYear() - 1989).keys()].map(
  (e) => e + 1990
);
export const months = [
  "հունվար",
  "փետրվար",
  "մարտ",
  "ապրիլ",
  "մայիս",
  "հունիս",
  "հուլիս",
  "օգոստոս",
  "սեպտեմբեր",
  "հոկտեմբեր",
  "նոյեմբեր",
  "դեկտեմբեր",
];
