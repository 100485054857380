import React, { Component } from "react"
import Enter from "../UI/Enter"
import "./style.css"

const Loading = () => "..."

const NotFound = () => (
  <div
    style={{
      width: "100%",
      padding: "30px",
      textAlign: "center",
    }}
  >
    Ոչինչ չի գտնվել
  </div>
)

class AccountDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      type,
      width,
      data,
      isLoading,
      heading,
      color,
      goToMap,
      searchFilter,
      selectItem,
      selectedItem,
    } = this.props

    return (
      <div className="account-dashboard" style={{ width: width }}>
        <div className="dashboard-topic" style={{ backgroundColor: color }}>
          <p className="dashboard-heading">{heading}</p>
        </div>
        <div className="dashboard-content">
          <div className="dashboard-filter">
            <input
              type="text"
              placeholder="Փնտրել"
              onChange={(e) => searchFilter(e.target.value)}
            />
          </div>
          <ul className="dashboard-list">
            {isLoading && <Loading />}

            {isLoading === false && data && data.length === 0 && <NotFound />}

            {isLoading === false &&
              data &&
              data.map((item) => (
                <li
                  key={item.value}
                  className={`dashboard-item ${
                    selectedItem === item.id ? "active" : null
                  }`}
                  onClick={() => selectItem(item.id)}
                >
                  <button className="dashboard-main-btn" type="button">
                    {item.title}
                  </button>
                  <p
                    className="current-title"
                    style={{ backgroundColor: color }}
                  >
                    {item.title}
                  </p>
                  <div className="dashboard-item-icons">
                    {type === "topic" || type === "lesson" ? (
                      <button
                        className="main__subitem-label"
                        onClick={() => goToMap(type, item.id)}
                      >
                        <Enter show={true} />
                      </button>
                    ) : null}

                    {type !== "lesson" ? (
                      <button className="dashboard-arrow" type="button">
                        <svg
                          version="1.1"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g
                            fill="none"
                            fillRule="evenodd"
                            strokeDasharray="0,0"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <g
                              transform="translate(10 10) rotate(180) translate(-10 -10)"
                              fillRule="nonzero"
                              stroke="#000"
                              strokeWidth="1.5"
                            >
                              <path d="m12.5 16.6-5.4333-5.4333c-0.64167-0.64167-0.64167-1.6917 0-2.3333l5.4333-5.4333" />
                            </g>
                          </g>
                        </svg>
                      </button>
                    ) : null}
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    )
  }
}

export default AccountDashboard
