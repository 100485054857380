import React, { Component } from "react";
import Account from "../account";
import "./style.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../../components/UI/Loader";
import ErrorAlert from "../../components/errorAlert";
import { TextInput } from "../../components/UI/TextInput";
import { SelectBox } from "../../components/UI/SelectBox";
import { Button } from "../../components/UI/Button";
import { MyDatePicker } from "../../components/UI/MyDatePicker";
import { genderOptions } from "../../shared/mockData";
import { checkValidationErrors } from "./form-validation";
import { getErrorMessage } from "../../helpers";
import {
  santizeSelectedResult,
  sortItemsByAlphabetical,
  constructSelectedItemApiParams,
  extractItems,
} from "./utils";

export const withNavigation = (Component: Component) => {
  return (props) => {
    return (
      <Component {...props} params={useParams()} navigate={useNavigate()} />
    );
  };
};

class AccountInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      apiErrorMsg: "",
      showError: false,
      signUp: null,
      countries: [],
      regions: [],
      states: [],
      schools: [],
      subjects: [],
      grades: [],
    };
  }

  componentDidMount() {
    const body = localStorage.getItem("signUpForm");
      if (JSON.parse(body) !== null && JSON.parse(body) !== undefined) {
          this.setState({
              signUp: JSON.parse(body),
          });
          this.handleSelectOptions("countrie");
          this.handleSelectOptions("grade");
          this.handleSelectOptions("subject");
      } else {
          window.location.href = "/signup";
      }
  }

  handleSelectOptions = (type) => {
    console.log(type);

    const { url, body } = constructSelectedItemApiParams(
      type,
      this.state.fields
    );

    const handleItemsLength = (items) => {
      if (type === "state" && items.length === 1) {
        let fields = this.state.fields;
        fields["state"] = {
          value: items[0].value,
          label: items[0].label,
        };
        this.setState({ fields });
        this.handleSelectOptions("school");
      }
      return items;
    };

    axios
      .post(url, body)
      .then((response) => extractItems(response))
      .then((items) => santizeSelectedResult(items))
      .then((items) => handleItemsLength(items))
      .then((items) => sortItemsByAlphabetical(items))
      .then((items) =>
        this.setState({
          [`${type}s`]: items,
        })
      );
  };

  contactSubmit(e) {
    this.setState({ loading: true });
    e.preventDefault();
    const errors = checkValidationErrors(
      this.state.fields,
      this.state.signUp?.SpecialityId
    );
    if (errors) {
      this.setState({ loading: false, errors });
      console.log("Form has errors.");
    } else {
      this.signUp();
    }
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  signUp = () => {
    const { fields, signUp } = this.state;
    let body = new FormData();
    body.append("email", signUp.Email);
    body.append("password", signUp.Password);
    body.append("firstName", signUp.FirstName);
    body.append("lastName", signUp.LastName);
    body.append("specialityId", signUp.SpecialityId);
    body.append("phoneNumber", fields["phone"]);
    body.append("gender", fields["gender"].value);
    body.append("birthDate", new Date(fields["birthDate"]).toLocaleString());
    body.append("schoolId", fields["school"].value);
    body.append("schoolRegionId", fields["region"].value);
    if (signUp?.SpecialityId === 3) {
      body.append("gradeId", fields["grade"].value);
    }
    if (signUp?.SpecialityId === 1) {
      fields["subject"].map((item) => {
        body.append("subjectIds", item.value);
      });
    }
    axios
      .post(`${process.env.REACT_APP_IDENTITY_URL}/api/User/SignUp`, body)
      .then(async (res) => {
        const errorState = {
          loading: false,
          showError: true,
        };

        if (res.data.accepted) {
          this.props.navigate("/email-send");
          return;
        } else {
          errorState.apiErrorMsg = getErrorMessage(res.data.errorMessages);
          this.setState(errorState);
        }
      });
  };

  render() {
    const {
      errors,
      fields,
      loading,
      showError,
      apiErrorMsg,
      signUp,
      states,
      countries,
      subjects,
      grades,
      regions,
      schools,
    } = this.state;
    const { handleChange, contactSubmit, setState, handleSelectOptions } = this;
    return (
      <Account onlyBG>
        <div className="account-content">
          <h2 className="account-welcome">Բարի գալուստ, {signUp?.firstName}</h2>
          <p className="account-fill">Եկեˊք, ամբողջականացնենք Ձեր հաշիվը։</p>
          <form className="account-form" onSubmit={contactSubmit.bind(this)}>
            <TextInput
              hasError={errors["phone"]}
              placeholder="Հեռախոսահամար *"
              type="number"
              onChange={handleChange.bind(this, "phone")}
              value={fields["phone"] || ""}
            />
            <SelectBox
              hasError={errors["gender"]}
              placeholder="Սեռը *"
              options={genderOptions}
              onChange={(e) => {
                fields["gender"] = e;
                this.setState({ fields });
              }}
              value={fields["gender"] || ""}
            />
            <MyDatePicker
              hasError={errors["birthDate"]}
              placeholder="Ծննդյան տարեթիվ *"
              onChange={(e) => {
                fields["birthDate"] = e;
                this.setState({ fields });
              }}
              value={fields["birthDate"] || null}
            />
            <SelectBox
              hasError={errors["country"]}
              placeholder="Երկիրը *"
              options={countries}
              onChange={(e) => {
                fields["country"] = e;
                this.setState({ fields });
                handleSelectOptions("region");
              }}
              value={fields["country"] || ""}
            />
            <SelectBox
              hasError={errors["region"]}
              placeholder="Մարզ *"
              options={regions}
              onChange={(e) => {
                fields["region"] = e;
                this.setState({ fields });
                handleSelectOptions("state");
              }}
              value={fields["region"] || ""}
            />
            {states.length > 1 && (
              <SelectBox
                hasError={errors["state"]}
                placeholder="Համայնք *"
                options={states}
                onChange={(e) => {
                  fields["state"] = e;
                  this.setState({ fields });
                  handleSelectOptions("school");
                }}
                value={fields["state"] || ""}
              />
            )}
            <SelectBox
              hasError={errors["school"]}
              placeholder="Դպրոց *"
              options={schools}
              onChange={(e) => {
                fields["school"] = e;
                this.setState({ fields });
              }}
              value={fields["school"] || ""}
            />
            {signUp?.SpecialityId === 3 && (
              <SelectBox
                hasError={errors["grade"]}
                placeholder="Դասարան *"
                options={grades}
                onChange={(e) => {
                  fields["grade"] = e;
                  this.setState({ fields });
                }}
                value={fields["grade"] || ""}
              />
            )}
            {signUp?.SpecialityId === 1 && (
              <SelectBox
                hasError={errors["subject"]}
                placeholder="Առարկա *"
                options={subjects}
                isMulti
                onChange={(e) => {
                  fields["subject"] = e;
                  this.setState({ fields });
                }}
                value={fields["subject"] || ""}
              />
            )}
            <Button
              className={"account-submit green"}
              text={"Պահել"}
              action={contactSubmit.bind(this)}
            />
          </form>
          {loading && <Loader />}

          {showError && (
            <ErrorAlert
              text={apiErrorMsg}
              close={() => setState({ showError: false })}
            />
          )}
        </div>
      </Account>
    );
  }
}

export default withNavigation(AccountInfo);
