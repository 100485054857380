import React, { useEffect } from "react";
import { connect } from "react-redux";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { isUserLoggedIn } from "../../store/selectors";

export const withNavigation = (Component: Component) => {
  return (props) => <Component {...props} navigate={useNavigate()} />;
};

const Home = (props) => {
  useEffect(() => {
    if (props.isLoggedIn) {
      window.location.href = "/account-page";
    } else {
      window.location.href = "/login";
    }
  }, [props.isLoggedIn]);

  return <div className="text" style={{ padding: 20 }}></div>;
};

const mapStateTopProps = (state) => ({
  isLoggedIn: isUserLoggedIn(state),
});

export default connect(mapStateTopProps, null)(withNavigation(Home));
