export const checkValidationErrors = (fields, specialityId) => {
  let errors = {};
  let hasError = false;

  //firstName
  if (!fields["phone"]) {
    hasError = true;
    errors["phone"] =
      "Հեռախոսահամարը սխալ է։ Խնդրում ենք մուտքագրել ճշգրիտ հեռախոսահամար։";
  }

  if (fields["phone"]?.search(/[0-9]/) == -1) {
    hasError = true;
    errors["phone"] =
      "Հեռախոսահամարը սխալ է։ Խնդրում ենք մուտքագրել ճշգրիտ հեռախոսահամար։";
  }

  if (fields["phone"]?.length > 11) {
    hasError = true;
    errors["phone"] =
      "Հեռախոսահամարը սխալ է։ Խնդրում ենք մուտքագրել ճշգրիտ հեռախոսահամար։";
  }

  //lastName
  if (!fields["gender"]?.value) {
    hasError = true;
    errors["gender"] = "Այս դաշտը պարտադիր է։ Խնդրում ենք լրացնել։";
  }

  //Email
  if (!fields["birthDate"]) {
    hasError = true;
    errors["birthDate"] = "Այս դաշտը պարտադիր է։ Խնդրում ենք լրացնել։";
  }

  if (!fields["country"]?.value) {
    hasError = true;
    errors["country"] = "Այս դաշտը պարտադիր է։ Խնդրում ենք լրացնել։";
  }

  if (!fields["region"]?.value) {
    hasError = true;
    errors["region"] = "Այս դաշտը պարտադիր է։ Խնդրում ենք լրացնել։";
  }
  if (!fields["state"]?.value) {
    hasError = true;
    errors["state"] = "Այս դաշտը պարտադիր է։ Խնդրում ենք լրացնել։";
  }
  if (!fields["school"]?.value) {
    hasError = true;
    errors["school"] = "Այս դաշտը պարտադիր է։ Խնդրում ենք լրացնել։";
  }
  if (specialityId === 3 && !fields["grade"]?.value) {
    hasError = true;
    errors["grade"] = "Այս դաշտը պարտադիր է։ Խնդրում ենք լրացնել։";
  }
  if (specialityId === 1 && fields["subject"]?.length === 0) {
    hasError = true;
    errors["subject"] = "Այս դաշտը պարտադիր է։ Խնդրում ենք լրացնել։";
  }

  return hasError ? errors : null;
};
