import React, { Component } from "react";
import Account from "../account";
import "./style.css";
import SuccessCheck from "../../components/UI/SuccessCheck";

class EmailCheck extends Component {
    componentDidMount() {
        let body = localStorage.getItem("signUpForm");
        console.log(body);
        if (JSON.parse(body) === null || JSON.parse(body) === undefined) {
            window.location.href = "/signup";
        }
    }
  render() {
    return (
      <Account>
        <div className="account-content">
          <SuccessCheck />
          <p className="check-text">
            ԽՆԴՐՈՒՄ ԵՆՔ ՍՏՈՒԳԵԼ ՁԵՐ ԷԼԵԿՏՐՈՆԱՅԻՆ ՀԱՍՑԵՆ ՆՈՐ ԳԱՂՏՆԱԲԱՌԻ
            ԱԿՏԻՎԱՑՄԱՆ ՀԱՄԱՐ
          </p>
        </div>
      </Account>
    );
  }
}

export default EmailCheck;
