import { CHANGE_MAP_STYLES } from '../constants'
import { getMapStyles } from '../../service/mapStyles'

const initialState = {
    data: [
        {
            id: 1,
            value: "physical",
            title: "Ֆիզիկական",
            style: "mapbox://styles/brainograph/ckyypimuc004114s0jxn3nytt",
            active: true,
        },
        {
            id: 2,
            value: "satellite",
            title: "Արբանյակային",
            style: "mapbox://styles/brainograph/ckyypqrvd000j15lb9osv5hda",
            active: false,
        },
        {
            id: 3,
            value: "politics",
            title: "Քաղաքական",
            style: "mapbox://styles/brainograph/ckyypjczl001w15qrx5woidl5",
            active: false,
        },
        {
            id: 4,
            value: "terrain",
            title: "Տեղանքային",
            style: "mapbox://styles/brainograph/cl3bx9ey8003b14rxe6nnha51",
            active: false,
        },
    ],
}

export const mapStylesReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_MAP_STYLES:
            return {
                ...state,
                data: getMapStyles(action.payload, initialState.data),
            }
        default:
            return state
    }
}
