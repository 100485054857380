import { Link } from "react-router-dom";
import AccountBoards from "../../../components/UI/AccountBoards";
import AccountPageIcon from "../../../components/UI/AccountPage";
import { LogOut } from "../../../helpers";
import LogOutIcon from "../../../components/UI/LogOut";
import React from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../../store/actions/user";

const TopBar = (props) => {
  return (
    <div className="top-btns">
      {window.innerWidth > 1024 && (
        <div className={`main__tab`}>
          <Link className="main__tab-btn" to="/account-boards">
            <AccountBoards />
          </Link>
        </div>
      )}
      <div className={`main__tab active`}>
        <Link className="main__tab-btn" to="/account-page">
          <AccountPageIcon />
        </Link>
      </div>
      <div className={`main__tab`}>
        <button
          className="main__tab-btn"
          type="button"
          onClick={props.logoutUser}
        >
          <LogOutIcon />
        </button>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  logoutUser,
};

export default connect(null, mapDispatchToProps)(TopBar);
