export const getTopics = (state) => state.topics
export const getTopicsData = (state) => getTopics(state).data
export const getIsTopicLoading = (state) => getTopics(state).isLoading

export const getGrades = (state) => state.grades
export const getGradesData = (state) => getGrades(state).data
export const getIsGradeLoading = (state) => getGrades(state).isLoading

export const getFilteredGrades = (state) => state.filteredGrades
export const getFilteredGradesData = (state) => getFilteredGrades(state).data
export const getIsFilteredGradeLoading = (state) =>
    getFilteredGrades(state).isLoading

export const getStates = (state) => state.states
export const getStatesData = (state) => getStates(state).data
export const getIsStateLoading = (state) => getStates(state).isLoading

export const getSchools = (state) => state.schools
export const getSchoolsData = (state) => getSchools(state).data
export const getIsSchoolLoading = (state) => getSchools(state).isLoading

export const getRegions = (state) => state.regions
export const getRegionsData = (state) => getRegions(state).data
export const getIsRegionLoading = (state) => getRegions(state).isLoading

export const getCountries = (state) => state.countries
export const getCountriesData = (state) => getCountries(state).data
export const getIsCountryLoading = (state) => getCountries(state).isLoading

export const getCategories = (state) => state.categories
export const getCategoriesData = (state) => getCategories(state).data

export const getFilteredCategories = (state) => state.filteredCategories
export const getFilteredCategoriesData = (state) =>
    getFilteredCategories(state).data

export const getSubjects = (state) => state.subjects
export const getSubjectsData = (state) => getSubjects(state).data
export const getIsSubjectLoading = (state) => getSubjects(state).isLoading

export const getLessons = (state) => state.lessons
export const getLessonsData = (state) => getLessons(state).data
export const getIsLessonLoading = (state) => getLessons(state).isLoading

export const getFilteredSubjects = (state) => state.filteredSubjects
export const getFilteredSubjectsData = (state) =>
    getFilteredSubjects(state).data
export const getIsFilteredSubjectLoading = (state) =>
    getFilteredSubjects(state).isLoading

export const getUser = (state) => state.user
export const getUserData = (state) => getUser(state).data

export const getMenu = (state) => state.menu
export const getMenuData = (state) => getMenu(state).data

export const getAbout = (state) => state.about
export const getAboutData = (state) => getAbout(state).data

export const getSearch = (state) => state.search
export const getSearchData = (state) => getSearch(state).data

export const getMapStyle = (state) => state.mapStyle
export const getMapStyleData = (state) => getMapStyle(state).data

export const getMapStyles = (state) => state.mapStyles
export const getMapStylesData = (state) => getMapStyles(state).data
export const getSelectedMapStyle = (state) =>
    getMapStyles(state).data.find((x) => x.active).id

export const getMapRequirements = (state) => state.mapRequirements
export const getMapRequirementsData = (state) => getMapRequirements(state).data
export const getSelectedMapRequirements = (state) =>
    getMapRequirements(state).data.find((x) => x.active).value

export const getHints = (state) => state.hints
export const getHintsData = (state) => getHints(state).data

export const getMapZoom = (state) => state.mapZoom
export const getMapZoomData = (state) => getMapZoom(state).data

export const getMapRequirement = (state) => state.mapRequirement
export const getMapRequirementData = (state) => getMapRequirement(state).data

export const getMenuExpend = (state) => state.menuExpend
export const getMenuExpendData = (state) => getMenuExpend(state).data

export const getTimeLineExpend = (state) => state.timeLineExpend
export const getTimeLineExpendData = (state) => getTimeLineExpend(state).data

export const getArticles = (state) => state.articles
export const getSelectedArticles = (state) => state.articles
export const getArticlesData = (state) => getArticles(state).data
export const getSelectedArticleData = (state) =>
    getSelectedArticles(state).selectedArticleData
export const getIsArticlesLoading = (state) => getArticles(state).isLoading

export const getLamp = (state) => state.lampArticles
export const getLampData = (state) => getLamp(state).data
export const getIsLampLoading = (state) => getLamp(state).isLoading

export const isUserLoggedIn = (state) => !!state.auth.data.token

export const getTimeLineOptions = (state) => state.timeLineOptions
export const getTimeLineOptionsData = (state) => getTimeLineOptions(state).data

export const getPrevLessonID = (state) => state.prevLessonId
export const getPrevLessonIDData = (state) => getPrevLessonID(state).data

export const getNextLessonID = (state) => state.nextLessonId
export const getNextLessonIDData = (state) => getNextLessonID(state).data

export const getTimeLineItems = (state) => state.timeLineItems
export const getTimeLineItemsData = (state) => getTimeLineItems(state).data
export const getTimeLineGroups = (state) => state.timeLineGroups
export const getTimeLineGroupsData = (state) => getTimeLineGroups(state).data
export const getIsTimeLineLoading = (state) => getTimeLineItems(state).isLoading

export const getSelectedLesson = (state) => state.selectedLesson
export const getSelectedLessonId = (state) => getSelectedLesson(state).data
