import React from "react";

const MapStyle = () => {
  return (
    <svg
      className="icon"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        d="M15.515,2.845c1.7.75,1.7,1.99,0,2.74L9.615,8.2a3.42,3.42,0,0,1-2.44,0l-5.9-2.62c-1.7-.75-1.7-1.99,0-2.74l5.9-2.62a3.42,3.42,0,0,1,2.44,0Z"
        transform="translate(3.395 2.695)"
        fill="none"
      />
      <path
        d="M0,0A2.562,2.562,0,0,0,1.4,2.15L8.19,5.17a1.988,1.988,0,0,0,1.62,0L16.6,2.15A2.562,2.562,0,0,0,18,0"
        transform="translate(3 11)"
        fill="none"
      />
      <path
        d="M0,0A2.357,2.357,0,0,0,1.4,2.15L8.19,5.17a1.988,1.988,0,0,0,1.62,0L16.6,2.15A2.357,2.357,0,0,0,18,0"
        transform="translate(3 16)"
        fill="none"
      />
      <path d="M24,0V24H0V0Z" fill="none" opacity="0" />
    </svg>
  );
};
export default MapStyle;
