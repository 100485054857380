import React, { Component } from "react"
import Account from "../account"
import { Link } from "react-router-dom"
import "./style.css"
import AccountDashboard from "../../components/accountDashboard"
import LeftNavigation from "../../components/leftNavigation"
import AccountFooter from "./../../components/accountFooter/index"
import { useNavigate } from "react-router-dom"
import { HandleToken, LogOut } from "../../helpers"
import { connect } from "react-redux"
import {
  loadingSubjects,
  setSubjects,
  getFilteredSubjectsFromAPI,
} from "../../store/actions/subjects"

import {
  constructSearchApiParams,
  constructSelectedItemApiParams,
} from "./utils"
import Logout from "../../components/UI/LogOut"
import AccountBoard from "../../components/UI/AccountBoards"
import AccountPage from "../../components/UI/AccountPage"
import {
  getUserData,
  getFilteredSubjectsData,
  getIsFilteredSubjectLoading,
  getFilteredGradesData,
  getIsFilteredGradeLoading,
  getTopicsData,
  getIsTopicLoading,
  getLessonsData,
  getIsLessonLoading,
} from "../../store/selectors"
import { getUserFromAPI } from "../../store/actions/user"
import { getFilteredGradesFromAPI } from "../../store/actions/grades"
import { getTopicsFromAPI } from "../../store/actions/topics"
import { getLessonsFromAPI } from "../../store/actions/lessons"

export const withNavigation = (Component: Component) => {
  return (props) => <Component {...props} navigate={useNavigate()} />
}

class AccountBoards extends Component {
  constructor() {
    super()
    this.state = {
      subjects: null,
      selectedSubjectID: null,
      grades: null,
      selectedGradeID: null,
      topics: null,
      selectedTopicID: null,
      lessons: null,
      selectedLessonID: null,
    }
  }

  componentDidMount() {
    HandleToken()
    const { subjects, isSubjectsLoading, user } = this.props
    if (!user) {
      this.props.getUserFromAPI()
    }
    if (subjects.length === 0 && isSubjectsLoading === false && user) {
      this.makeSubjectsCall()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.user && nextProps.user !== this.props.user) {
      const { subjects, isSubjectsLoading } = this.props
      if (subjects.length === 0 && isSubjectsLoading === false) {
        this.makeSubjectsCall()
      }
    }
  }

  makeSubjectsCall() {
    const { url, body } = constructSearchApiParams("subject")
    this.props.getFilteredSubjectsFromAPI(url, body)
  }

  handleSelectItem = (type) => (id) => {
    if (type === "lesson") {
      return this.setState({
        selectedLessonID: id,
      })
    }
    const { url, body } = constructSelectedItemApiParams(
      type,
      id,
      this.state.selectedSubjectID
    )
    if (type === "subject") {
      this.props.getFilteredGradesFromAPI(url, body)
      this.setState({
        selectedSubjectID: id,
        selectedGradeID: null,
        selectedTopicID: null,
        selectedLessonID: null,
      })
    }
    if (type === "grade") {
      this.props.getTopicsFromAPI(url, body)
      this.setState({
        selectedGradeID: id,
        selectedTopicID: null,
        selectedLessonID: null,
      })
    }
    if (type === "topic") {
      this.props.getLessonsFromAPI(url, body)
      this.setState({
        selectedTopicID: id,
        selectedLessonID: null,
      })
    }
  }

  searchFilter = (type) => (val) => {
    const { selectedTopicID, selectedSubjectID, selectedGradeID } = this.state
    const { url, body } = constructSearchApiParams(
      type,
      val,
      selectedTopicID,
      selectedSubjectID,
      selectedGradeID
    )

    if (type === "subject") {
      this.props.getFilteredSubjectsFromAPI(url, body)
    }
    if (type === "grade") {
      this.props.getFilteredGradesFromAPI(url, body)
    }
    if (type === "topic") {
      this.props.getTopicsFromAPI(url, body)
    }
    if (type === "lesson") {
      this.props.getLessonsFromAPI(url, body)
    }
  }

  handleGoToMapView = (type, id) =>
    window.open(`/map?type=${type}&${type}ID=${id}`, "_blank")

  render() {
    const {
      subjects,
      grades,
      topics,
      lessons,
      isSubjectsLoading,
      isGradesLoading,
      isTopicsLoading,
      isLessonLoading,
    } = this.props

    return (
      <Account onlyBG={true}>
        <LeftNavigation />
        <div className="top-btns">
          {window.innerWidth > 1024 ? (
            <div className={`main__tab active`}>
              <Link className="main__tab-btn" to="/account-boards">
                <AccountBoard />
              </Link>
            </div>
          ) : null}
          <div className={`main__tab`}>
            <Link className="main__tab-btn" to="/account-page">
              <AccountPage />
            </Link>
          </div>
          <div className={`main__tab`}>
            <button className="main__tab-btn" type="button" onClick={LogOut}>
              <Logout />
            </button>
          </div>
        </div>
        <div className="account-dashboards">
          <AccountDashboard
            width={"20%"}
            color={"#95d4f3"}
            heading={"Առարկա"}
            type={"subject"}
            isLoading={isSubjectsLoading}
            data={subjects}
            selectedItem={this.state.selectedSubjectID}
            selectItem={this.handleSelectItem("subject")}
            searchFilter={this.searchFilter("subject")}
          />
          {this.state.selectedSubjectID !== null ? (
            <AccountDashboard
              width={"18%"}
              color={"#f3bfda"}
              heading={"Դասարան"}
              type={"grade"}
              isLoading={isGradesLoading}
              data={grades}
              selectedItem={this.state.selectedGradeID}
              selectItem={this.handleSelectItem("grade")}
              searchFilter={this.searchFilter("grade")}
            />
          ) : null}
          {this.state.selectedGradeID !== null ? (
            <AccountDashboard
              width={"27%"}
              color={"#ffde5a"}
              heading={"Թեմա"}
              type={"topic"}
              isLoading={isTopicsLoading}
              data={topics}
              selectedItem={this.state.selectedTopicID}
              selectItem={this.handleSelectItem("topic")}
              searchFilter={this.searchFilter("topic")}
              goToMap={(type, id) => this.handleGoToMapView(type, id)}
            />
          ) : null}
          {this.state.selectedTopicID !== null ? (
            <AccountDashboard
              width={"27%"}
              color={"#42d3b0"}
              heading={"Դաս"}
              type={"lesson"}
              isLoading={isLessonLoading}
              data={lessons}
              selectedItem={this.state.selectedLessonID}
              selectItem={this.handleSelectItem("lesson")}
              searchFilter={this.searchFilter("lesson")}
              goToMap={(type, id) => this.handleGoToMapView(type, id)}
            />
          ) : null}
        </div>
        <AccountFooter />
      </Account>
    )
  }
}

const mapStateTopProps = (state) => ({
  subjects: getFilteredSubjectsData(state),
  grades: getFilteredGradesData(state),
  user: getUserData(state),
  topics: getTopicsData(state),
  lessons: getLessonsData(state),
  isSubjectsLoading: getIsFilteredSubjectLoading(state),
  isGradesLoading: getIsFilteredGradeLoading(state),
  isTopicsLoading: getIsTopicLoading(state),
  isLessonLoading: getIsLessonLoading(state),
})

const mapDispatchToProps = {
  getFilteredSubjectsFromAPI,
  getFilteredGradesFromAPI,
  getLessonsFromAPI,
  getTopicsFromAPI,
  getUserFromAPI,
  loadingSubjects,
  setSubjects,
}

export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withNavigation(AccountBoards))
