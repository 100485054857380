import { SET_LAMP, LOADING_LAMP } from '../constants'

const initialState = {
    data: [],
    isLoading: false,
}

export const lampReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING_LAMP: {
            return { ...state, isLoading: true }
        }
        case SET_LAMP:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            }
        default:
            return state
    }
}
