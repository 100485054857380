import GatewayAPI from "../server/gatewayAPI";

export default class ArticleDataService {

    // Topic
    // static getTopicList = body => HeadingAPI.post(`/api/Topic/DataProvider/1`, body)
    //
    // static createTopic = body => HeadingAPI.post('/api/Topic', body)
    //
    // static readTopic = id => HeadingAPI.get(`/api/Topic/${id}/Language/1`);
    //
    // static updateTopic = (id, item) => HeadingAPI.put(`/api/Topic/${id}`, item);
    //
    // static deleteTopic = id => HeadingAPI.delete(`/api/Topic/${id}`);

    // Lesson
    // static getLessonList = (topicId, body) => HeadingAPI.post(`/api/Lesson/DataProvider/${topicId}/1`, body)
    //
    // static getAllLessonList = body => HeadingAPI.post(`/api/Lesson/DataProvider/1`, body)
    //
    // static createLesson = body => HeadingAPI.post('/api/Lesson', body)
    //
    // static readLesson = id => HeadingAPI.get(`/api/Lesson/${id}/Language/1`);
    //
    // static updateLesson = (id, item) => HeadingAPI.put(`/api/Lesson/${id}`, item);
    //
    // static deleteLesson = id => HeadingAPI.delete(`/api/Lesson/${id}`);

    // Map

    static getMapPreview = (body) => GatewayAPI.get(`/api/Map/Preview${body ? `?${body}` : ``}`)
    static getMapTime = (body) => GatewayAPI.get(`/api/Map${body ? `?${body}` : ``}`)
    static getMapPreviewTime = (body) => GatewayAPI.get(`/api/Map/PreviewMap${body ? `?${body}` : ``}`)
    static getMapLesson = lessonID => GatewayAPI.get(`/api/Map/Lesson/${lessonID}`)
    static getMapTopic = topicID => GatewayAPI.get(`/api/Map/Topic/${topicID}`)
    static getMapGrade = (subjectID, gradeID) => GatewayAPI.get(`/api/Map/${gradeID}/${subjectID}`)
    static getMapArticle = (articleID) => GatewayAPI.get(`/api/Map/Article/${articleID}`)

    // Category
    static getCategoryList = body => GatewayAPI.post(`/api/Category/DataProvider/1`, body)

    static createCategory = body => GatewayAPI.post('/api/Category', body)

    static readCategory = id => GatewayAPI.get(`/api/Category/${id}/Language/1`);

    static updateCategory = (id, item) => GatewayAPI.put(`/api/Category/${id}`, item);

    static deleteCategory = id => GatewayAPI.delete(`/api/Category/${id}`);

    // SubCategory
    static getSubCategoryList = body => GatewayAPI.post(`/api/SubCategory/DataProvider/1`, body)

    static createSubCategory = body => GatewayAPI.post('/api/SubCategory', body)

    static readSubCategory = id => GatewayAPI.get(`/api/SubCategory/${id}/Language/1`);

    static updateSubCategory = (id, item) => GatewayAPI.put(`/api/SubCategory/${id}`, item);

    static deleteSubCategory = id => GatewayAPI.delete(`/api/SubCategory/${id}`);

    // Article
    static create = body => GatewayAPI.post('/api/Article', body)

    static getArticleList = body => GatewayAPI.post(`/api/Article/DataProvider/1`, body)

    // static getArticleListByLesson = (id, body) => HeadingAPI.post(`/api/Lesson/${id}/DataProvider`, body)
    //
    // static assignArticle = (lessonId, body) => HeadingAPI.post(`/api/Lesson/${lessonId}/Article`, body)

    static readArticle = id => GatewayAPI.get(`/api/Article/${id}/Language/1?IncludeAll=true`);

    static updateArticleInner = (id, data) => GatewayAPI.put(`/api/Article/${id}`, data);

    static deleteArticleInner = id => GatewayAPI.delete(`/api/Article/${id}`);

    // static updateArticle = (lessonId, item) => HeadingAPI.put(`/api/Lesson/${lessonId}/Article`, item);
    //
    // static deleteArticle = (lessonId, articleId) => HeadingAPI.delete(`/api/Lesson/${lessonId}/Article/${articleId}`);

    // Style
    static getStyleList = body => GatewayAPI.post('/api/Style/DataProvider', body)

    static createStyle = body => GatewayAPI.post('/api/Style', body)

    static readStyle = id => GatewayAPI.get(`/api/Style/${id}`);

}
