import {
    LOADING_TIMELINE,
    SET_TIMELINE_ITEMS,
    SET_TIMELINE_GROUPS,
} from '../constants'
import { processTimeLineTopicItemsRendering } from '../../pages/map/utils/processTimeLineTopicItemsRendering'
import { processTimeLineLessonGroupRendering } from '../../pages/map/utils/processTimeLineLessonGroupRendering'
import { processTimeLineLessonItemsRendering } from '../../pages/map/utils/processTimeLineLessonItemsRendering'

export const loadingTimeLine = () => ({
    type: LOADING_TIMELINE,
})

export const setTimeLineItems = (items) => ({
    type: SET_TIMELINE_ITEMS,
    payload: items,
})

export const setTimeLineGroups = (items) => ({
    type: SET_TIMELINE_GROUPS,
    payload: items,
})

export const setTimeLineTopicsData = (data) => (dispatch) => {
    dispatch(loadingTimeLine())
    const items = processTimeLineTopicItemsRendering(data)
    dispatch(setTimeLineItems(items))
}

export const setTimeLineLessonData =
    (dataId, lessonId, data, fromTopic) => (dispatch, getState) => {
        const state = getState()
        const categories = state.categories.data
        dispatch(loadingTimeLine())
        const groupItems = processTimeLineLessonGroupRendering(data, categories)
        processTimeLineLessonItemsRendering(
            dataId,
            lessonId,
            data,
            fromTopic,
            groupItems,
            categories
        ).then((items) => {
            dispatch(setTimeLineGroups(groupItems))
            dispatch(setTimeLineItems(items))
        })
    }

const _toggleGroup = (groups, items, id) => {
    const groupIndex = groups.findIndex((x) => x.catId === id)

    groups[groupIndex].show = !groups[groupIndex].show
    items
        .filter((item) => item.catId === id)
        .forEach((item) => {
            item.show = groups[groupIndex].show
        })
}

export const updateTimeLineGroupToggle = (id) => (dispatch, getState) => {
    const state = getState()
    const groups = [...state.timeLineGroups.data]
    const items = [...state.timeLineItems.data]
    if (groups.length > 0) {
        _toggleGroup(groups, items, id)
        dispatch(setTimeLineGroups(groups))
        dispatch(setTimeLineItems(items))
    }

}
