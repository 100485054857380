import { Provider } from "react-redux"
import { store } from "./store"
import Navigation from "./navigation"
import "./App.css"

function App() {
  return (
    <Provider store={store}>
      <Navigation />
    </Provider>
  )
}

export default App
