import React from "react";

const PhoneNumberIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <g transform="translate(1.833 1.833)">
        <path
          d="M18.077,15.968a3.782,3.782,0,0,1-.623.935,4.133,4.133,0,0,1-1.5,1.082,4.6,4.6,0,0,1-1.787.348,7.639,7.639,0,0,1-2.988-.669,16.1,16.1,0,0,1-3.153-1.815,26.354,26.354,0,0,1-3.007-2.567,26.046,26.046,0,0,1-2.557-3A16.342,16.342,0,0,1,.66,7.159a7.721,7.721,0,0,1-.66-3A4.742,4.742,0,0,1,.33,2.393,4.219,4.219,0,0,1,1.384.862,2.686,2.686,0,0,1,3.291,0a1.723,1.723,0,0,1,.742.165,1.494,1.494,0,0,1,.614.513l2.127,3a3.137,3.137,0,0,1,.367.642,1.449,1.449,0,0,1,.128.559,1.244,1.244,0,0,1-.193.651,3.118,3.118,0,0,1-.513.651l-.7.724a.491.491,0,0,0-.147.367.832.832,0,0,0,.028.211c.028.073.055.128.073.183a7.607,7.607,0,0,0,.852,1.173c.412.477.852.963,1.329,1.448.495.486.972.935,1.458,1.347a7.035,7.035,0,0,0,1.182.843c.046.018.1.046.165.073a.633.633,0,0,0,.229.037.5.5,0,0,0,.376-.156l.7-.687a2.813,2.813,0,0,1,.66-.513,1.221,1.221,0,0,1,.651-.193,1.463,1.463,0,0,1,.559.119,3.545,3.545,0,0,1,.642.358l3.034,2.154a1.39,1.39,0,0,1,.5.587,1.881,1.881,0,0,1,.147.715A2.333,2.333,0,0,1,18.077,15.968Z"
          fill="none"
          stroke="#292d32"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          strokeDasharray="0 0"
        />
      </g>
      <path d="M22,0V22H0V0Z" fill="none" opacity="0" />
    </svg>
  );
};
export default PhoneNumberIcon;
