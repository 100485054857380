import axios from 'axios'
import React, { useState } from 'react'
import './style.css'
import LeftNavigation from '../leftNavigation'
import {
    getCategoriesData,
    getMapStyleData,
    getMapStylesData,
    getMenuData,
    getSearchData,
} from '../../store/selectors'
import { connect } from 'react-redux'
import Search from '../UI/Search'
import CategoryFilter from '../UI/CategoryFilter'
import MapStyle from '../UI/MapStyle'
import { setMenu } from '../../store/actions/menu'
import { changeMapStyles } from '../../store/actions/mapStyles'
import {setMapStyle} from "../../store/actions/mapStyle";
import {getArticleFromAPI} from "../../store/actions/articles";
import {setSearch} from "../../store/actions/search";

const debounce = (fn, wait) => {
    let timeoutID
    // call as many times as you wish!
    // I'll do my job after `wait` miliseconds
    return (...arg) => {
        if (timeoutID) clearTimeout(timeoutID)
        timeoutID = setTimeout(() => fn(...arg), wait)
    }
}
const debeounced400 = debounce((fn) => fn(), 400)

const MapHeader = (props) => {
    const [searchResult, setSearchResult] = useState([])
    const [selectedCategoryId, setSelectedCategoryId] = useState(null)
    const [searchTerm, setSearchTerm] = useState('')
    const [loadMore, setLoadMore] = useState(false)
    const [pageNumber, setPageNumber] = useState(0)

    const handleSearch = (value) => {
        setSearchTerm(value)
        if (value.length > 0) {
            debeounced400(() => {
                let queryParams = {
                    Term: value,
                    CategoryId: selectedCategoryId,
                    PageSize: 10,
                    PageNumber: pageNumber,
                }
                axios
                    .get(
                        `${process.env.REACT_APP_INDEXING_URL}/api/Map/Search`,
                        {
                            params: queryParams,
                        }
                    )
                    .then((response) => {
                        if (response.data.data[0].total > 10) {
                            setLoadMore(true)
                        }
                        setSearchResult(response.data.data[0].items)
                    })
            })
        } else {
            setSearchResult([])
        }
    }

    const loadMoreResult = () => {
        let newPageNumber = pageNumber + 1
        setPageNumber(newPageNumber)
        let queryParams = {
            Term: searchTerm,
            CategoryId: selectedCategoryId,
            PageSize: 10,
            PageNumber: newPageNumber,
        }
        axios
            .get(`${process.env.REACT_APP_INDEXING_URL}/api/Map/Search`, {
                params: queryParams,
            })
            .then((response) => {
                if (response.data.data[0].total > 10 * pageNumber) {
                    setLoadMore(true)
                }
                let data = response.data.data[0].items
                let newData = [...searchResult, ...data]
                setSearchResult(newData)
            })
    }

    const changeCategory = (id) => {
        setSelectedCategoryId(id)
        handleSearch()
    }

    const {
        mapStyle,
        setMapStyle,
        search,
        setSearch,
        mapStyles,
        setMapStyles,
        categories,
        getArticleFromAPI,
    } = props

    return (
        <header className="header">
            <LeftNavigation />
            <div className="header__right">
                <div
                    className={`right-side__search main__tab ${
                        search ? 'active' : ''
                    }`}
                >
                    <button
                        className="right-side__search-btn main__tab-btn"
                        type="button"
                        onClick={() => {
                            setSearchTerm('')
                            setSearch(!search)
                            setSearchResult([])
                        }}
                    >
                        <span className="main__tab-icon icon-wrapper">
                            <Search />
                        </span>
                    </button>

                    <div className="right-side__search-content">
                        <div className="right-side__search-field">
                            <div>
                                <div>
                                    <input
                                        autoComplete="off"
                                        value={searchTerm}
                                        name="search"
                                        className="right-side__search-input"
                                        placeholder="Փնտրիր քեզ հետաքրքրողն այստեղ"
                                        onInput={(e) =>
                                            handleSearch(e.target.value)
                                        }
                                    />
                                </div>
                                <span className="right-side__search-icon icon-wrapper">
                                    <Search />
                                </span>
                            </div>
                        </div>

                        <ul className="right-side__search-filters right-side__filters custom-scroll2">
                            <li
                                className={
                                    selectedCategoryId
                                        ? 'right-side__filters-item categories'
                                        : 'right-side__filters-item categories active'
                                }
                            >
                                <button
                                    onClick={(e) => changeCategory(null)}
                                    className="right-side__filters-btn "
                                    type="button"
                                >
                                    <CategoryFilter />
                                    <span>Բոլորը</span>
                                </button>
                            </li>
                            {categories.map((item, index) => {
                                return (
                                    <li
                                        key={item.id}
                                        className={`right-side__filters-item categories ${
                                            selectedCategoryId === item.id
                                                ? 'active'
                                                : ''
                                        }`}
                                    >
                                        <button
                                            onClick={(e) =>
                                                changeCategory(item.id)
                                            }
                                            className="right-side__filters-btn "
                                            type="button"
                                        >
                                            <img
                                                src={item.iconPath}
                                                style={{
                                                    marginRight: '5px',
                                                }}
                                            />
                                            <span>{item.title}</span>
                                        </button>
                                    </li>
                                )
                            })}
                        </ul>
                        {searchResult.length > 0 && (
                            <>
                                <div className="right-side__search-response">
                                    <ul className="response main__subitem custom-scroll">
                                        {searchResult.map((item) => {
                                            return (
                                                <li
                                                    key={item.id}
                                                    className="right-side__filters-item"
                                                >
                                                    <button
                                                        className="right-side__filters-btn with-svg"
                                                        onClick={() => {
                                                            setSearch(!search)
                                                            getArticleFromAPI(
                                                                item.id
                                                            )
                                                        }}
                                                    >
                                                        <img
                                                            src={
                                                                categories?.find(
                                                                    (x) =>
                                                                        x.id ===
                                                                        item
                                                                            .category[0]
                                                                            .categoryId
                                                                )?.iconPath
                                                            }
                                                        />
                                                        <span
                                                            className={
                                                                'search-text'
                                                            }
                                                        >
                                                            {
                                                                item.content[0]
                                                                    .mainTitle
                                                            }
                                                        </span>
                                                    </button>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                                {loadMore && (
                                    <button
                                        style={{
                                            width: '100%',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            display: 'flex',
                                            marginBottom: '20px',
                                            fontSize: '11px',
                                        }}
                                        onClick={() => loadMoreResult()}
                                    >
                                        Ավելին
                                    </button>
                                )}
                            </>
                        )}
                        { searchTerm.length > 0 && searchResult.length === 0 && (
                            <div className="right-side__search-response">
                                <button
                                    style={{
                                        width: '100%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: 'flex',
                                        marginBottom: '20px',
                                        fontSize: '11px',
                                    }}
                                >
                                    Ոչինչ չի գտնվել
                                </button>
                            </div>
                        )}
                    </div>
                </div>

                <div
                    className={`right-side__filters filters main__tab ${
                        mapStyle ? 'active' : ''
                    }`}
                    onMouseLeave={() => setMapStyle(false)}
                >
                    <button
                        className="filters__tab main__tab-btn"
                        type="button"
                        onClick={() => setMapStyle(!mapStyle)}
                    >
                        <span className="main__tab-map icon-wrapper">
                            <MapStyle />
                        </span>
                    </button>
                    <div className="filters__content">
                        <h2 className="filters__title">Բազային քարտեզ</h2>
                        <fieldset className="filters__radio">
                            {mapStyles?.map((item, index) => {
                                return (
                                    <label
                                        className="filters__radio-label"
                                        key={item.id}
                                    >
                                        <input
                                            type="radio"
                                            name="mapStyle"
                                            onChange={() =>
                                                setMapStyles(item.id)
                                            }
                                            checked={item.active}
                                        />
                                        <span
                                            className={`filters__radio-text map${item.id}`}
                                        >
                                            {item.title}
                                        </span>
                                    </label>
                                )
                            })}
                        </fieldset>
                    </div>
                </div>
            </div>
        </header>
    )
}

const mapStateTopProps = (state) => ({
    categories: getCategoriesData(state),
    menu: getMenuData(state),
    search: getSearchData(state),
    mapStyle: getMapStyleData(state),
    mapStyles: getMapStylesData(state),
})

const mapDispatchToProps = {
    setMenu: setMenu,
    setSearch: setSearch,
    setMapStyle: setMapStyle,
    setMapStyles: changeMapStyles,
    getArticleFromAPI: getArticleFromAPI,
}

export default connect(mapStateTopProps, mapDispatchToProps)(MapHeader)
