import axios from "axios";
import {
  genderOptions,
  specialityOptions,
  userInfoURI,
} from "../shared/mockData";
import {
  getGradeByID,
  getSchoolByID,
  getSubjectByID,
} from "../pages/accountPage/utils";

const sanitizeUserInfo = async (data) => {
  const userData = {
    avatar: data.userInfo.avatar,
    mediaId: data.userInfo.mediaId,
    firstName: data.userInfo?.firstName,
    lastName: data.userInfo?.lastName,
    speciality: specialityOptions.find(
      (x) => x.text === data.userInfo?.userType
    ),
    phone: data.phoneNumber,
    email: data.email,
    gender: genderOptions.find((x) => x.value === data.userInfo?.gender),
    birthDate: data.userInfo?.birthDate,
    subject: [],
  };
  if (userData.speciality.text === "Student") {
    await getGradeByID(data.userInfo.gradeId).then((gradeResult) => {
      userData.grade = {
        value: gradeResult.data.data[0]?.id,
        label: gradeResult.data.data[0]?.title,
      };
    });
  }

  if (userData.speciality.text === "Teacher") {
    data.userInfo?.subjectIds.forEach((subjectID) => {
      getSubjectByID(subjectID).then((subjectResult) => {
        userData.subject.push({
          value: subjectResult.data.data[0]?.id,
          label: subjectResult.data.data[0]?.title,
        });
      });
    });
  }
  await getSchoolByID(data.userInfo.schoolId).then((response) => {
    const result = response.data.data[0];
    userData.country = {
      value: result?.city?.state?.countryId,
      label: result?.city?.state?.country?.title,
    };
    userData.region = {
      value: result?.city?.stateId,
      label: result?.city?.state?.title,
    };
    userData.state = {
      value: result?.cityId,
      label: result?.city?.title,
    };
    userData.school = {
      value: result?.id,
      label: result?.title,
    };
  });
  return userData;
};

export const getUserAPI = () => {
  return axios
    .get(userInfoURI)
    .then((res) => {
      if (!res.data.accepted) {
        return [];
      }
      return res.data.data[0];
    })
    .then(sanitizeUserInfo)
    .catch(console.log);
};
