import React from 'react'
import MiniGallery from '../miniGallery'
import AnimationGallery from "../animationGallery";
import Plus from '../UI/Plus'
import Minus from '../UI/Minus'
import CloseDark from '../UI/CloseDark'
import AudioPlayer from '../audioPlayer'
import { getCategoriesData } from '../../store/selectors'
import { connect } from 'react-redux'
import {
    closeArticlePopup,
    collapsArticlePopup,
} from '../../store/actions/articles'
import galleryImgIcon from '../../assets/imgs/gallery-img-icon.svg'
import galleryVideoIcon from '../../assets/imgs/gallery-video-icon.svg'
import gallery360Icon from '../../assets/imgs/gallery-360-icon.svg'

const ArticleItem = (props) => {
    const {
        article,
        categories,
        collapsArticlePopup,
        closeArticlePopup,
        toggleGallery,
    } = props
    const galleryContainItems = {
        video:false,
        image:false,
        audio:false,
        R360:false
    }
        article.articleData?.gallery?.forEach((value, index, self)=>{
        if(self.findIndex(el=>el.type === value.type) === index){
            switch (value.type){
                case 0:
                    galleryContainItems.image = true
                    break
                case 1:
                    galleryContainItems.image = true
                    break
                case 2:
                    galleryContainItems.R360 = true
                    break
                case 3:
                    galleryContainItems.video = true
                    break
                case 4:
                    galleryContainItems.audio = true
                    break
            }

        }
    });

    const gallery =
        article?.articleData?.content[0]?.featuredImageResized !== null
            ? [
                  ...[
                      {
                          mediaId: null,
                          path: article?.articleData?.content[0]
                              .featuredImageResized,
                      },
                  ],
                  ...article?.articleData?.gallery?.filter((x) => x.type === 0),
              ]
            : article?.articleData?.gallery?.filter((x) => x.type === 0)

    return (
        <div
            key={article.id}
            className={`hero ${!article?.show ? 'minimized' : ''}`}
        >
            <div className="hero__info-wrapper">
                <div className="hero__header">
                    <h2 className="hero__title">

                        {
                            !article?.show
                            ?article?.articleData?.content[0].shortTitle
                            :article?.articleData?.content[0].mainTitle
                        }
                    </h2>
                    <div className="hero__btns">
                        <button
                            className="hero__collapse"
                            type="button"
                            onClick={() => collapsArticlePopup(article.id)}
                        >
                            {!article.show ? <Plus /> : <Minus />}
                        </button>
                        <button
                            className="hero__close"
                            type="button"
                            onClick={() => closeArticlePopup(article.id)}
                        >
                            <CloseDark />
                        </button>
                    </div>
                </div>
                <div className="hero__media-wrapper">
                    {
                        gallery.length > 0
                        ? <AnimationGallery
                                gallery={gallery}
                                openLightBox={(id) => toggleGallery(id)}
                                isMinimized = {!article.show}
                            />
                        :     <div className="hero__media_animation__box">
                                <button
                                    className="hero__media"
                                    id="lightgallery"
                                    type="button"
                                >
                                    <img
                                        src={require('../../assets/imgs/image_placeholder.png')}
                                        alt="image_placeholder"
                                    />
                                </button>
                            </div>
                    }

                    {/*{article.show && gallery.length > 0 ? (*/}
                    {/*    <AnimationGallery*/}
                    {/*        gallery={gallery}*/}
                    {/*        openLightBox={(id) => toggleGallery(id)}*/}
                    {/*    />*/}
                    {/*) : (*/}
                    {/*    <div className="hero__media_animation__box">*/}
                    {/*        <button*/}
                    {/*            className="hero__media"*/}
                    {/*            id="lightgallery"*/}
                    {/*            type="button"*/}
                    {/*        >*/}
                    {/*            {gallery.length > 0 ? (*/}
                    {/*                <img*/}
                    {/*                    src={gallery[0].path}*/}
                    {/*                    alt="image_placeholder"*/}
                    {/*                />*/}
                    {/*            ) : (*/}
                    {/*                <img*/}
                    {/*                    src={require('../../assets/imgs/image_placeholder.png')}*/}
                    {/*                    alt="image_placeholder"*/}
                    {/*                />*/}
                    {/*            )}*/}
                    {/*        </button>*/}
                    {/*        <div className="hero__media__liner-gradien"></div>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                    {
                        <div className={"hero__media_icons"} >
                            {galleryContainItems.image && <span><img style={{'width':'20px','height':'20px'}} src={galleryImgIcon} alt="galleryImgIcon"/></span>}
                            {galleryContainItems.video && <span><img style={{'width':'20px','height':'20px'}} src={galleryVideoIcon} alt="galleryVideoIcon"/></span>}
                            {galleryContainItems.R360 && <span><img style={{'width':'20px','height':'20px'}} src={gallery360Icon} alt="galleryVideoIcon"/></span>}
                        </div>
                    }
                    {/*<p className="hero__category">*/}
                    {/*    {*/}
                    {/*        categories.find(*/}
                    {/*            (x) =>*/}
                    {/*                x.id ===*/}
                    {/*                article.articleData?.category[0].categoryId*/}
                    {/*        )?.title*/}
                    {/*    }*/}
                    {/*</p>*/}
                    <div className="hero__media-items"></div>
                    {article?.articleData?.content[0].externalUrl !== null ? (
                        <a
                            className="hero__more"
                            target={'_blank'}
                            href={article?.articleData?.content[0].externalUrl}
                            rel="noreferrer"
                        >
                            ԱՎԵԼԻՆ
                        </a>
                    ) : null}
                </div>
                <div className="hero__info">
                    <div className="hero__text">
                        <div className="hero__descr">
                            {article?.articleData?.content[0].introText}
                        </div>
                    </div>
                </div>
            </div>
            {article?.articleData?.gallery?.find((x) => x.type === 4) && (
                <AudioPlayer />
            )}
        </div>
    )
}
const mapStateTopProps = (state) => ({
    categories: getCategoriesData(state),
})

const mapDispatchToProps = {
    collapsArticlePopup,
    closeArticlePopup,
}

export default connect(mapStateTopProps, mapDispatchToProps)(ArticleItem)
