import React, { Component } from "react";
import Account from "./../account";
import "./style.css";
import { getErrorMessage } from "../../helpers";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/UI/Loader";
import ErrorAlert from "../../components/errorAlert";
import axios from "axios";
import { TextInput } from "../../components/UI/TextInput";
import { Button } from "../../components/UI/Button";
import { checkValidationErrors } from "../forgot/form-validation";

export const withNavigation = (Component: Component) => {
  return (props) => <Component {...props} navigate={useNavigate()} />;
};

class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      fields: {},
      errors: {},
      apiErrorMsg: "",
      showError: false,
    };
  }

  async componentDidMount() {
    const token = localStorage.getItem("accessToken");
    if (token) {
      window.location.href = "/account-page";
    }
  }

  contactSubmit(e) {
    this.setState({ loading: true });
    e.preventDefault();
    const errors = checkValidationErrors(this.state.fields);
    if (errors) {
      this.setState({ loading: false, errors });
      console.log("Form has errors.");
    } else {
      this.forgotPassEmail();
    }
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  forgotPassEmail = () => {
    axios
      .get(
        `${process.env.REACT_APP_IDENTITY_URL}/api/User/ResetPassword/${this.state.fields["email"]}`
      )
      .then(async (res) => {
        const errorState = {
          loading: false,
          showError: true,
        };

        if (res.data.accepted) {
          localStorage.setItem(
            "forgotPassEmail",
            JSON.stringify(this.state.fields["email"])
          );
          this.props.navigate("/resetPass");
          return;
        } else {
          errorState.apiErrorMsg = getErrorMessage(res.data.errorMessages);
          this.setState(errorState);
        }
      });
  };

  render() {
    const { errors, fields, loading, showError, apiErrorMsg } = this.state;
    const { handleChange, contactSubmit, setState } = this;

    return (
      <Account>
        <div className="account-content">
          <h2 className="forgot-subtitle">Մոռացե՞լ եք գաղտնաբառը:</h2>
          <p className="forgot-descr">
            Լրացնելուց հետո Ձեր էլ․ հասցեին կգա գաղտնաբառը վերականգնելու հղումը:
          </p>
          <form className="account-form" onSubmit={contactSubmit.bind(this)}>
            <p className="forgot-email">Նշե՛ք Ձեր Էլեկտրոնային Հասցեն</p>
            <TextInput
              hasError={errors["email"]}
              placeholder="էլ․ հասցե *"
              onChange={handleChange.bind(this, "email")}
              value={fields["email"] || ""}
            />
            <Button text={"ուղարկել"} action={contactSubmit.bind(this)} />
          </form>
          {loading && <Loader />}

          {showError && (
            <ErrorAlert
              text={apiErrorMsg}
              close={() => setState({ showError: false })}
            />
          )}
        </div>
      </Account>
    );
  }
}

export default withNavigation(ForgotPassword);
