import React from "react";
import {SplideSlide} from "@splidejs/react-splide";
import './style.css'
import {connect} from "react-redux";
import {getArticlesData, getCategoriesData, getIsArticlesLoading} from "../../store/selectors";
import {setMapZoomIn, setMapZoomOut} from "../../store/actions/zoom";
import {closeArticlePopup, collapsArticlePopup} from "../../store/actions/articles";

const AnimationGallery = (props)=>{
    const {
        gallery,
        openLightBox,
        galleryContainItems,
        isMinimized
    } = props
    return(<>
            <div className="hero__media_animation__box">
                <button
                    className="hero__media_animation"
                    id="lightgallery"
                    type="button"
                    onClick={(e) => {
                        if(!isMinimized){
                            openLightBox(gallery[0].mediaId)
                        }
                    }}
                >
                    <img src={gallery[0].path} alt="hero" />
                </button>
                <div className="hero__media__liner-gradien"></div>
            </div>
    </>)
}
const mapStateTopProps = (state) => ({
    categories: getCategoriesData(state),
    articleData: getArticlesData(state),
    articleLoading: getIsArticlesLoading(state),
})

const mapDispatchToProps = {
    setMapZoomIn,
    setMapZoomOut,
    collapsArticlePopup,
    closeArticlePopup,
}
export default connect(mapStateTopProps, mapDispatchToProps)(AnimationGallery)
