import React from "react";

const EmailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <path
        d="M0,4.583C0,1.375,1.833,0,4.583,0H13.75c2.75,0,4.583,1.375,4.583,4.583V11c0,3.208-1.833,4.583-4.583,4.583H4.583"
        transform="translate(1.833 3.208)"
        fill="none"
        stroke="#292d32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        strokeDasharray="0 0"
      />
      <path
        d="M9.167,0,6.3,2.292a2.9,2.9,0,0,1-3.438,0L0,0"
        transform="translate(6.417 8.25)"
        fill="none"
        stroke="#292d32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        strokeDasharray="0 0"
      />
      <path
        d="M0,0H5.5"
        transform="translate(1.833 15.125)"
        fill="none"
        stroke="#292d32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        strokeDasharray="0 0"
      />
      <path
        d="M0,0H2.75"
        transform="translate(1.833 11.458)"
        fill="none"
        stroke="#292d32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        strokeDasharray="0 0"
      />
      <path d="M22,0V22H0V0Z" fill="none" opacity="0" />
    </svg>
  );
};
export default EmailIcon;
