import React, { Component, useEffect, useState } from 'react'
import '@splidejs/splide/dist/css/splide.min.css'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'
import Eye from '../UI/Eye'
import Expend from '../UI/Expend'
import Close from '../UI/Close'
import ArrowBottom from '../UI/ArrowBottom'
import Requirements from '../UI/Requirements'
import Lamp from '../UI/Lamp'
import { CircularProgress } from '@mui/material'
import {
    getFilteredCategoriesData,
    getHintsData,
    getLampData,
    getMapRequirementData,
    getMapRequirementsData,
    getMenuExpendData,
} from '../../store/selectors'
import { connect } from 'react-redux'
import { setHints } from '../../store/actions/hints'
import { setMapRequirement } from '../../store/actions/mapRequirement'
import { setMenuExpend } from '../../store/actions/menuExpend'
import { getArticleFromAPI } from '../../store/actions/articles'
import { changeMapRequirements } from '../../store/actions/mapRequirements'

export const LeftSide = (props) => {
    const [selectedCategoryId, setSelectedCategoryId] = useState(null)
    const [selectedSubCategoryId, setSelectedSubCategoryId] = useState([])

    const openCategory = (id) => {
        setSelectedCategoryId(id)
    }

    const openSubCategory = (id) => {
        const subCategories = selectedSubCategoryId
        if (!subCategories.includes(id)) {
            subCategories.push(id)
        } else {
            subCategories.splice(subCategories.indexOf(id), 1)
        }
        setSelectedSubCategoryId([])
        setTimeout(() => {
            setSelectedSubCategoryId(subCategories)
        }, 1)
    }

    const {
        menuExpend,
        filteredCategories,
        mapRequirements,
        setMenuExpend,
        toggleItemsFromMap,
        lampData,
        hints,
        mapRequirement,
        setHints,
        setMapRequirement,
        getArticleFromAPI,
        setMapRequirements,
    } = props

    return (
        <div className="main__left left-side">
            <div
                className={`categories-collapse main__tab ${
                    menuExpend ? 'active' : ''
                }`}
            >
                <button
                    className="main__tab-btn"
                    type="button"
                    onClick={() => setMenuExpend(!menuExpend)}
                >
                    <Expend />
                    {lampData.length > 0 && !menuExpend ? (
                        <div
                            className={'lampIcon'}
                            style={{
                                position: 'absolute',
                                top: -10,
                                right: -10,
                                background: '#fbde5a',
                                borderRadius: 40,
                                padding: '3px 9px',
                            }}
                        >
                            <Lamp length={lampData.length > 0} />
                        </div>
                    ) : null}
                </button>
            </div>
            <div className={`main__tabs-wrapper ${menuExpend ? 'active' : ''}`}>
                <ul className="main__tabs left-side__tabs">
                    {filteredCategories.length > 0 &&
                        filteredCategories?.map((item, index) => {
                            return (
                                <li
                                    key={item.id}
                                    className={`main__tab persons ${
                                        selectedCategoryId === item.id &&
                                        'active'
                                    }`}
                                >
                                    <button
                                        className="main__tab-btn"
                                        type="button"
                                        style={{
                                            backgroundColor:
                                                selectedCategoryId === item?.id
                                                    ? item?.color
                                                    : 'transparent',
                                        }}
                                        onClick={() => {
                                            openCategory(item?.id)
                                            setMapRequirement(false)
                                            setHints(false)
                                        }}
                                    >
                                        <span className="main__tab-icon icon-wrapper">
                                            <img
                                                src={item?.iconPath}
                                                alt={item?.title}
                                            />
                                        </span>
                                    </button>
                                    {item?.subCategories !== null &&
                                    item?.subCategories.length > 0 ? (
                                        <div
                                            className="main__subitem"
                                            key={item.id}
                                        >
                                            <div className="main__subitem-heading">
                                                <span
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                        left: 0,
                                                        backgroundColor:
                                                            item.color,
                                                    }}
                                                />
                                                <h2 className="main__subitem-title ">
                                                    {item.title}
                                                </h2>
                                                <label
                                                    className="main__subitem-label"
                                                    key={item.id}
                                                >
                                                    <input
                                                        className="main__subitem-checkbox show-checkbox"
                                                        type="checkbox"
                                                        onChange={() =>
                                                            toggleItemsFromMap(
                                                                item.id,
                                                                null,
                                                                'category'
                                                            )
                                                        }
                                                        checked={item.show}
                                                    />
                                                    <Eye show={item.show} />
                                                </label>
                                                <button
                                                    className="main__subitem-close"
                                                    type="button"
                                                    onClick={() =>
                                                        openCategory(null)
                                                    }
                                                >
                                                    <span>
                                                        <Close />
                                                    </span>
                                                </button>
                                            </div>
                                            <div className="main__subitem-itemswrapper">
                                                <ul className="main__subitem-items custom-scroll">
                                                    {item.subCategories.map(
                                                        (subItem, subIndex) => {
                                                            return (
                                                                <li
                                                                    key={`cat_${item.id}_${subIndex}_${subItem.id}`}
                                                                    className={`main__subitem-item ${
                                                                        selectedSubCategoryId?.find(
                                                                            (
                                                                                x
                                                                            ) =>
                                                                                x ===
                                                                                subItem.id
                                                                        ) &&
                                                                        'active'
                                                                    }`}
                                                                >
                                                                    <div className="main__subitem-initem">
                                                                        <p className="with-svg">
                                                                            <img
                                                                                src={
                                                                                    subItem.iconPath
                                                                                }
                                                                                alt={
                                                                                    subItem.title
                                                                                }
                                                                            />
                                                                        </p>
                                                                        <button
                                                                            className="main__subitem-btn"
                                                                            type="button"
                                                                            onClick={() =>
                                                                                openSubCategory(
                                                                                    subItem.id
                                                                                )
                                                                            }
                                                                        >
                                                                            {
                                                                                subItem.title
                                                                            }
                                                                        </button>
                                                                        <label className="main__subitem-label">
                                                                            <input
                                                                                className="main__subitem-checkbox show-checkbox"
                                                                                type="checkbox"
                                                                                onChange={() =>
                                                                                    toggleItemsFromMap(
                                                                                        item.id,
                                                                                        subItem.id,
                                                                                        'subCategory'
                                                                                    )
                                                                                }
                                                                                checked={
                                                                                    subItem.show
                                                                                }
                                                                            />
                                                                            <Eye
                                                                                show={
                                                                                    subItem.show
                                                                                }
                                                                            />
                                                                        </label>
                                                                        <button
                                                                            className="main__subitem-arrow"
                                                                            type="button"
                                                                            onClick={() =>
                                                                                openSubCategory(
                                                                                    subItem.id
                                                                                )
                                                                            }
                                                                        >
                                                                            <ArrowBottom />
                                                                        </button>
                                                                    </div>
                                                                    <ul className="main__subitem-list person">
                                                                        {subItem?.articles ===
                                                                        'undefined' ? (
                                                                            <CircularProgress />
                                                                        ) : (
                                                                            subItem?.articles?.map(
                                                                                (
                                                                                    articleItem,
                                                                                    artIndex
                                                                                ) => {
                                                                                    return (
                                                                                        <li
                                                                                            key={`cat_${item.id}_${subIndex}_${subItem.id}_${artIndex}_${articleItem.id}`}
                                                                                            className="main__subitem-li"
                                                                                        >
                                                                                            <button
                                                                                                className="main__subitem-libtn"
                                                                                                type="button"
                                                                                                onClick={() =>
                                                                                                    getArticleFromAPI(
                                                                                                        articleItem.id
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    articleItem.title
                                                                                                }
                                                                                            </button>
                                                                                        </li>
                                                                                    )
                                                                                }
                                                                            )
                                                                        )}
                                                                    </ul>
                                                                </li>
                                                            )
                                                        }
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    ) : null}
                                </li>
                            )
                        })}
                </ul>
                <div className="left-side__bottom">
                    <div
                        className={`filters ${mapRequirement ? 'active' : ''}`}
                    >
                        <button
                            className={`filters__btn main__tab ${
                                mapRequirement ? 'active' : ''
                            }`}
                            type="button"
                            onClick={() => {
                                setMapRequirement(!mapRequirement)
                                setSelectedCategoryId(null)
                            }}
                        >
                            <Requirements />
                        </button>
                        <div className="filters__content">
                            <h2 className="filters__title">ցույց տալ</h2>
                            <fieldset className="filters__radio">
                                {mapRequirements?.map((item) => {
                                    return (
                                        <label
                                            className="filters__radio-label"
                                            key={item.id}
                                        >
                                            <input
                                                type="radio"
                                                name="filter"
                                                onChange={() =>
                                                    setMapRequirements(
                                                        item.value
                                                    )
                                                }
                                                checked={item.active}
                                            />
                                            <span className="filters__radio-text">
                                                {item.title}
                                            </span>
                                        </label>
                                    )
                                })}
                            </fieldset>
                        </div>
                    </div>

                    <div
                        className={`left-side__hints hints ${
                            hints ? 'active' : ''
                        }`}
                    >
                        <button
                            className={`hints__btn main__tab ${
                                hints ? 'active' : ''
                            }`}
                            type="button"
                            onClick={() => {
                                if (lampData.length > 0) {
                                    setHints(!hints)
                                    setSelectedCategoryId(null)
                                }
                            }}
                        >
                            <Lamp length={lampData.length > 0} />
                        </button>
                        <div className="hints__content">
                            <Splide
                                options={{
                                    pagination: false,
                                    infinite: false,
                                }}
                                className="hints__content-items"
                            >
                                {lampData.length > 0
                                    ? lampData.map((item, index) => {
                                          return (
                                              <SplideSlide
                                                  key={`${item.id}_${index}`}
                                              >
                                                  <div
                                                      className="hints__content-item"
                                                      onClick={() =>
                                                          getArticleFromAPI(
                                                              item.id
                                                          )
                                                      }
                                                  >
                                                      <button
                                                          className="hints__content-img"
                                                          type="button"
                                                      >
                                                          {item?.content[0]
                                                              ?.featuredImageResized !==
                                                          null ? (
                                                              <img
                                                                  src={
                                                                      item
                                                                          ?.content[0]
                                                                          ?.featuredImageResized
                                                                  }
                                                                  alt={
                                                                      item
                                                                          ?.content[0]
                                                                          ?.mainTitle
                                                                  }
                                                              />
                                                          ) : item?.gallery?.filter(
                                                                (x) =>
                                                                    x.type === 0
                                                            ).length > 0 ? (
                                                              <img
                                                                  src={
                                                                      item?.gallery?.filter(
                                                                          (x) =>
                                                                              x.type ===
                                                                              0
                                                                      )[0].path
                                                                  }
                                                                  alt="image_placeholder"
                                                              />
                                                          ) : (
                                                              <img
                                                                  src={require('../../assets/imgs/image_placeholder.png')}
                                                                  alt="image_placeholder"
                                                              />
                                                          )}
                                                      </button>
                                                      <div className="hints__content-text">
                                                          <button
                                                              className="hints__content-name"
                                                              type="button"
                                                          >
                                                              {
                                                                  item
                                                                      ?.content[0]
                                                                      ?.mainTitle
                                                              }
                                                          </button>
                                                      </div>
                                                  </div>
                                                  <div className="hints__content-bottom">
                                                      <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          width="23.5"
                                                          height="21.426"
                                                          viewBox="0 0 23.5 21.426"
                                                      >
                                                          <g transform="translate(0.75 0.766)">
                                                              <path
                                                                  d="M19.37,3.66a1.083,1.083,0,0,1,.63.93V7.91a1,1,0,0,1-1,1H1a1,1,0,0,1-1-1V4.59a1.083,1.083,0,0,1,.63-.93l9-3.6a1.171,1.171,0,0,1,.74,0Z"
                                                                  transform="translate(1)"
                                                                  fill="none"
                                                              />
                                                              <path
                                                                  d="M0,4V1A1,1,0,0,1,1,0H19a1,1,0,0,1,1,1V4Z"
                                                                  transform="translate(1 15.91)"
                                                                  fill="none"
                                                              />
                                                              <path
                                                                  d="M0,7V0"
                                                                  transform="translate(3 8.91)"
                                                                  fill="none"
                                                              />
                                                              <path
                                                                  d="M0,7V0"
                                                                  transform="translate(7 8.91)"
                                                                  fill="none"
                                                              />
                                                              <path
                                                                  d="M0,7V0"
                                                                  transform="translate(11 8.91)"
                                                                  fill="none"
                                                              />
                                                              <path
                                                                  d="M0,7V0"
                                                                  transform="translate(15 8.91)"
                                                                  fill="none"
                                                              />
                                                              <path
                                                                  d="M0,7V0"
                                                                  transform="translate(19 8.91)"
                                                                  fill="none"
                                                              />
                                                              <path
                                                                  d="M0,0H22"
                                                                  transform="translate(0 19.91)"
                                                                  fill="none"
                                                              />
                                                              <path
                                                                  d="M1.5,3A1.5,1.5,0,1,1,3,1.5,1.5,1.5,0,0,1,1.5,3Z"
                                                                  transform="translate(9.5 3.41)"
                                                                  fill="none"
                                                              />
                                                          </g>
                                                      </svg>
                                                      <span>

                                                             {
                                                                 ((item.startTimeIsBC && item.endTimeIsBC) || (!item.startTimeIsBC && !item.endTimeIsBC)) &&  (item.startYear === item.endYear)
                                                                     ?(item.startTimeIsBC
                                                                         ? `Ք․ա ${item.startYear}`
                                                                         : item.startYear)
                                                                     :(
                                                                         (item.startTimeIsBC
                                                                             ? `Ք․ա ${item.startYear}`
                                                                             : item.startYear)
                                                                         + '-' +
                                                                         (item.endTimeIsBC
                                                                             ? `Ք․ա ${item.endYear}`
                                                                             : item.endYear)
                                                                     )
                                                             }


                                                      </span>
                                                  </div>
                                              </SplideSlide>
                                          )
                                      })
                                    : null}
                            </Splide>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateTopProps = (state) => ({
    filteredCategories: getFilteredCategoriesData(state),
    hints: getHintsData(state),
    mapRequirement: getMapRequirementData(state),
    menuExpend: getMenuExpendData(state),
    lampData: getLampData(state),
    mapRequirements: getMapRequirementsData(state),
})

const mapDispatchToProps = {
    setHints,
    setMenuExpend,
    setMapRequirement,
    getArticleFromAPI,
    setMapRequirements: changeMapRequirements,
}

export default connect(mapStateTopProps, mapDispatchToProps)(LeftSide)
