const Reset = ({length}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" width="22.121" height="22.121" viewBox="0 0 46 46" version="1.1">
            <g id="Design" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="19--Map---basic-map-Copy" transform="translate(-3753.000000, -1785.000000)">
                    <g id="Timeline" transform="translate(-2.666667, 1743.666667)">
                        <g id="Group-10" transform="translate(3714.666667, 0.000000)">
                            <g id="Group" transform="translate(39.000000, 39.000000)">
                                <g id="refresh-2" transform="translate(0.000000, -0.000000)">
                                    <path d="M6.47916645,34.2500011 L6.47916645,44.6666667 M45.8333333,25 C45.8333333,36.5 36.5,45.8333333 25,45.8333333 C17.3333334,45.8333333 11.1597222,41.9722226 6.47916645,34.2500011 L15.8958336,34.2500011 M4.16666667,25 C4.16666667,13.5 13.4166668,4.16666667 25,4.16666667 C34.263889,4.16666667 41.2083334,8.02777774 45.8333333,15.7499999 M45.8333333,15.7499999 L45.8333333,5.33333321 M45.8333333,15.7499999 L36.5833322,15.7499999" id="Vector" stroke="#2C476C" stroke-width="3.15" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0"/>
                                    <path d="M50,0 L50,50 L0,50 L0,0 L50,0 Z" id="Vector" opacity="0" transform="translate(25.000000, 25.000000) rotate(-180.000000) translate(-25.000000, -25.000000) "/>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
export default Reset;
