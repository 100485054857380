const Enter = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" width="19px" height="19px" viewBox="0 0 19 19" version="1.1">
            <g id="Comments" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Artboard" transform="translate(-894.000000, -341.000000)">
                    <g id="Group" transform="translate(894.000000, 341.000000)">
                        <g id="vuesax/linear/import" transform="translate(9.500000, 9.500000) rotate(-180.000000) translate(-9.500000, -9.500000) ">
                            <g transform="translate(0.000000, -0.000000)" id="import">
                                <line x1="17.4167052" y1="1.58333333" x2="10.9250387" y2="8.07499985" id="Vector" stroke="#2C476C" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0"/>
                                <path d="M10.2916667,4.88461812 L10.2916667,8.70836806 L14.1154166,8.70836806" id="Vector" stroke="#2C476C" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0" fill-rule="nonzero"/>
                                <path d="M8.70833333,1.58333333 L7.125,1.58333333 C3.16666667,1.58333333 1.58333333,3.16666667 1.58333333,7.125 L1.58333333,11.875 C1.58333333,15.8333333 3.16666667,17.4166667 7.125,17.4166667 L11.875,17.4166667 C15.8333333,17.4166667 17.4166667,15.8333333 17.4166667,11.875 L17.4166667,10.2916667" id="Vector" stroke="#2C476C" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0" fill-rule="nonzero"/>
                                <path d="M19,0 L19,19 L0,19 L0,0 L19,0 Z" id="Vector" opacity="0" transform="translate(9.500000, 9.500000) rotate(-180.000000) translate(-9.500000, -9.500000) "/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
export default Enter;
