import axios from "axios";

export const loginUser = (url, body) => {
  return axios.post(url, body);
};

export const saveUserToken = (data) => {
  localStorage.setItem("accessToken", data.data[0].accessToken);
  localStorage.setItem("refreshToken", data.data[0].refreshToken);

  return data;
};

export const navigateToAccountPage = (navigate) => {
  if (window.innerWidth < 1024) {
    navigate("/account-page");
  } else {
    navigate("/account-boards");
  }
};
