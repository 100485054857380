const Lamp = ({length}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16.942" height="24" viewBox="0 0 16.942 24">
            <path d="M14.493,2.515a8.471,8.471,0,0,0-12.334,11.6,5.292,5.292,0,0,1,1.37,3.528v1.529a2,2,0,0,0,1.412,1.912v.818A1.972,1.972,0,0,0,6.758,24h3.426A1.972,1.972,0,0,0,12,21.906v-.818a2,2,0,0,0,1.412-1.912V17.647a5.292,5.292,0,0,1,1.37-3.528A8.468,8.468,0,0,0,14.493,2.515Z" fill="#2c476c"/>
            <path d="M3.831,1.412H.4c-.187,0-.4-.3-.4-.682V0H4.235V.729C4.235,1.114,4.018,1.412,3.831,1.412Z" transform="translate(6.353 21.176)" fill="#ffde5a"/>
            <path d="M7.059.824a.589.589,0,0,1-.588.588H.588A.589.589,0,0,1,0,.824V0H7.059Z" transform="translate(4.941 18.353)" fill="#ffde5a"/>
            <path d="M10.627,15.529H3.491A6.734,6.734,0,0,0,1.8,11.766a7.059,7.059,0,1,1,10.52,0A6.734,6.734,0,0,0,10.627,15.529Z" transform="translate(1.412 1.412)" fill={length ? '#ffde5a' : 'white'}/>
        </svg>
    )
}
export default Lamp;
