import React, { Component } from "react"
import Account from "./../account"
import ErrorMsg from "../../components/errorMsg"
import { useNavigate, Link, useParams } from "react-router-dom"
import Select from "react-select"
import Loader from "../../components/UI/Loader"
import ErrorAlert from "../../components/errorAlert"
import axios from "axios"
import { getErrorMessage } from "../../helpers"
import { checkValidationErrors } from "./form-validation"
import { specialityOptions, dropDownCustomStyles } from "../../shared/mockData"
import { Button } from "../../components/UI/Button"
import { TextInput } from "../../components/UI/TextInput"
import TermsOfUse from '../termsOfUse'
import PrivacyPolicy from '../privacyPolicy'

export const withNavigation = (Component: Component) => {
  return (props) => (
    <Component {...props} params={useParams()} navigate={useNavigate()} />
  )
}

class Signup extends Component {
  constructor() {
    super()
    this.state = {
      fields: {},
      errors: {},
      apiErrorMsg: "",
      showError: false,
        termsAndUsePopup: false,
        privacyPolicyPopup: false,
        termsAndPoliceChecked: true,
    }
  }

    handleShowTermsAndUsePopup = () => {
        this.setState({ termsAndUsePopup: true })
    }
    handleShowPrivacyPolicyPopup = () => {
        this.setState({ privacyPolicyPopup: true })
    }

  contactSubmit(e) {
    this.setState({ loading: true })
    e.preventDefault()
    const errors = checkValidationErrors(this.state.fields)
    if (errors) {
      this.setState({ loading: false, errors })
      console.log("Form has errors.")
    } else {
      this.signUp()
    }
  }

  handleChange(field, e) {
    let fields = this.state.fields
    fields[field] = e.target.value
    this.setState({ fields })
  }

  signUp = () => {
    const { fields } = this.state
    const body = {
      FirstName: fields["firstName"],
      LastName: fields["lastName"],
      SpecialityId: fields["speciality"].value,
      Email: fields["email"],
      Password: fields["password"],
    }
    axios
      .get(
        `${process.env.REACT_APP_IDENTITY_URL}/api/User/EmailAvailable/${fields["email"]}`
      )
      .then((res) => {
        const errorState = {
          loading: false,
          showError: true,
        }

        if (res.data.accepted) {
          if (res.data.data[0]) {
            localStorage.setItem("signUpForm", JSON.stringify(body))
            this.props.navigate("/account-info")
            return
          } else {
            errorState.apiErrorMsg =
              "Այս էլեկտրոնային հասցեով օգտատեր արդեն գրանցվել է։"
            this.setState(errorState)
          }
        } else {
          errorState.apiErrorMsg = getErrorMessage(res.data.errorMessages)
          this.setState(errorState)
        }
      })
  }

  render() {
    const { errors, fields, loading, showError, apiErrorMsg } = this.state
    const { handleChange, contactSubmit, setState } = this

    return (
      <Account onlyBG={true}>
        <div className="account-content">
          <h2 className="page-title">Գրանցվել</h2>
          <form className="account-form" onSubmit={contactSubmit.bind(this)}>
            <TextInput
              hasError={errors["firstName"]}
              placeholder="Անուն *"
              onChange={handleChange.bind(this, "firstName")}
              value={fields["firstName"] || ""}
            />
            <TextInput
              hasError={errors["lastName"]}
              placeholder="Ազգանուն *"
              onChange={handleChange.bind(this, "lastName")}
              value={fields["lastName"] || ""}
            />
            <TextInput
              hasError={errors["email"]}
              placeholder="Էլ․ հասցե *"
              onChange={handleChange.bind(this, "email")}
              value={fields["email"] || ""}
            />
            <div
              className={`account-input input-wrapper ${
                errors["speciality"] ? "error" : ""
              }`}
            >
              <Select
                className={"select"}
                options={specialityOptions}
                value={fields["speciality"]}
                isSearchable={false}
                autoFocus={false}
                placeholder="Օգտատեր *"
                styles={dropDownCustomStyles}
                onChange={(e) => {
                  fields["speciality"] = e
                  this.setState({ fields })
                }}
                noOptionsMessage={() => "Տարբերակ չկա"}
              />
              {errors["speciality"] ? (
                <>
                  <button className="error-btn" type="button" />
                  <ErrorMsg text={errors["speciality"]} />
                </>
              ) : null}
            </div>
            <TextInput
              hasError={errors["password"]}
              placeholder="Գաղտնաբառ *"
              type="password"
              onChange={handleChange.bind(this, "password")}
              value={fields["password"] || ""}
            />
            <TextInput
              hasError={errors["cPassword"]}
              placeholder="Կրկնեք գաղտնաբառը *"
              type="password"
              onChange={handleChange.bind(this, "cPassword")}
              value={fields["cPassword"] || ""}
            />
              <div
                  style={{
                      display: 'flex',
                      gap: '10px',
                      marginBottom: '20px',
                  }}
                  className={`input-wrapper`}
              >
                  {/*<div>*/}
                  {/*    <label className="container-terms-policy">*/}
                  {/*        <input*/}
                  {/*            type="checkbox"*/}
                  {/*            ref="termsAndPliceRef"*/}
                  {/*            data-active={*/}
                  {/*                this.state.termsAndPoliceChecked*/}
                  {/*            }*/}
                  {/*        />*/}
                  {/*        <span*/}
                  {/*            className="container-terms-policy__checkmark"*/}
                  {/*            data-error={*/}
                  {/*                !!this.state.errors[*/}
                  {/*                    'termsAndPolice'*/}
                  {/*                ]*/}
                  {/*            }*/}
                  {/*            onClick={*/}
                  {/*                this.handleChangeTermsAndPoliceState*/}
                  {/*            }*/}
                  {/*        ></span>*/}
                  {/*    </label>*/}
                  {/*</div>*/}
                  {/*<div*/}
                  {/*    style={{*/}
                  {/*        letterSpacing: '1px',*/}
                  {/*        fontFamily: 'sans-serif',*/}
                  {/*    }}*/}
                  {/*>*/}
                  {/*    <span style={{ color: '#fff' }}>*/}
                  {/*        I have have read and accepted the*/}
                  {/*    </span>*/}
                  {/*    <span*/}
                  {/*        className="terms-police-title"*/}
                  {/*        style={{ margin: '0rem 0.2rem' }}*/}
                  {/*        onClick={this.handleShowTermsAndUsePopup}*/}
                  {/*    >*/}
                  {/*        Terms of Use*/}
                  {/*    </span>*/}
                  {/*    <span style={{ color: '#fff' }}> and the</span>*/}
                  {/*    <span*/}
                  {/*        className="terms-police-title"*/}
                  {/*        style={{ margin: '0rem 0.2rem' }}*/}
                  {/*        onClick={this.handleShowPrivacyPolicyPopup}*/}
                  {/*    >*/}
                  {/*        Privacy Policy*/}
                  {/*    </span>*/}
                  {/*</div>*/}
              </div>
            <Button text={"Գրանցվել"} action={contactSubmit.bind(this)} />
          </form>
          {loading && <Loader />}
          {showError && (
            <ErrorAlert
              text={apiErrorMsg}
              close={() => setState({ showError: false })}
            />
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <span style={{ color: "#fff" }}>Արդեն ունե՞ք հաշիվ։</span>
            <Link className="reg" to="/login">
              Մուտք գործեք
            </Link>
          </div>
        </div>
          {this.state.termsAndUsePopup ? (
              <TermsOfUse
                  handleTerms={() => {
                      this.setState({
                          termsAndUsePopup: !this.state.termsAndUsePopup,
                      })
                  }}
              />
          ) : null}
          {this.state.privacyPolicyPopup ? (
              <PrivacyPolicy
                  handlePolicy={() => {
                      this.setState({
                          privacyPolicyPopup:
                              !this.state.privacyPolicyPopup,
                      })
                  }}
              />
          ) : null}
      </Account>
    )
  }
}

export default withNavigation(Signup)
