import React, { Component } from "react"
import {
  getErrorMessage,
  handleImageUpload,
  uploadFile,
} from "../../../helpers"
import Loader from "../../../components/UI/Loader"
import UploadPhotoIcon from "../../../components/UI/UploadPhotoIcon"
import { TextInput } from "../../../components/UI/TextInput"
import { Button } from "../../../components/UI/Button"
import ErrorAlert from "../../../components/errorAlert"
import { specialityOptions, updateUserInfoURI } from "../../../shared/mockData"
import PhoneNumberIcon from "../../../components/UI/PhoneNumberIcon"
import EmailIcon from "../../../components/UI/EmailIcon"
import { checkAccountValidationErrors } from "../form-validation"
import { getUserUpdateApiParams, updateUserAccount } from "../utils"
import { getUserData } from "../../../store/selectors"
import { connect } from "react-redux"
import { getUserFromAPI } from "../../../store/actions/user"

const resetState = {
  loading: false,
  editAccountInfo: false,
  showError: false,
  uploadedFile: {
    id: null,
    url: null,
  },
}

class LeftSideBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editAccountInfo: false,
      loading: false,
      fields: props.user,
      errors: {},
      apiErrorMsg: "",
      loadImage: false,
      showError: false,
      uploadedFile: {
        id: null,
        url: null,
      },
    }
  }

  contactSubmit(e) {
    this.setState({ loading: true })
    e.preventDefault()
    const errors = checkAccountValidationErrors(this.state.fields)
    if (errors) {
      this.setState({ loading: false, errors })
      console.log("Form has errors.")
    } else {
      this.updateAccount()
    }
  }

  handleChange(field, e) {
    let fields = this.state.fields
    fields[field] = e.target.value
    this.setState({ fields })
  }

  uploadFile = (e) => {
    const handlerState = {
      loadImage: false,
      uploadedFile: {
        id: null,
        url: null,
      },
    }
    uploadFile(e.target.files[0])
      .then((res) => {
        if (res.data.accepted === false) {
          return Promise.reject(res.data.errorMessages)
        }
        return res.data
      })
      .then(handleImageUpload)
      .then((data) => {
        handlerState.uploadedFile = data
        this.setState(handlerState)
      })
      .catch((error) => {
        this.setState({
          ...handlerState,
          showError: true,
          apiErrorMsg: getErrorMessage(error),
        })
      })
  }

  handleRemoveAvatar = (e) => {
    e.preventDefault();
    this.props.user.avatar = null;
    this.props.user.mediaId = null;
    this.setState({
      uploadedFile: { id: null, url: null },
    });
  };
  updateAccount = () => {
    const { fields, uploadedFile } = this.state
    const body = getUserUpdateApiParams(fields,this.props.user, uploadedFile)
    updateUserAccount(updateUserInfoURI, body)
      .then((res) => {
        if (res.data.accepted === false) {
          return Promise.reject(res.data.errorMessages)
        }
        return res.data
      })
      .then(this.props.getUserFromAPI)
      .then(() => this.setState(resetState))
      .catch((error) => {
        this.setState({
          ...resetState,
          showError: true,
          apiErrorMsg: getErrorMessage(error),
        })
      })
  }

  render() {
    const {
      editAccountInfo,
      loading,
      showError,
      apiErrorMsg,
      fields,
      errors,
      uploadedFile,
      loadImage,
    } = this.state
    const { contactSubmit, handleChange, handleRemoveAvatar } = this
    const { user } = this.props

    return (
      <div className="user-left">
        {editAccountInfo ? (
          <>
            <form className="account-info-edit">
              {uploadedFile.id !== null ? (
                <div className="user-photo uploaded">
                  {loadImage && (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        background: "rgba(0,0,0,0.4)",
                        position: "absolute",
                      }}
                    >
                      <Loader />
                    </div>
                  )}
                  <img src={uploadedFile.url} alt="user photo" />
                  <label className="photo-upload-label">
                    <UploadPhotoIcon />
                    <input
                      type="file"
                      accept={"image/jpeg"}
                      onInput={(e) => this.uploadFile(e)}
                    />
                  </label>
                  {uploadedFile.url !== null && (
                    <button
                      className="photo-remove-label"
                      onClick={(e) => handleRemoveAvatar(e)}
                    >
                      X
                    </button>
                  )}
                </div>
              ) : user.avatar !== null ? (
                <div className="user-photo ">
                  {loadImage && (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        background: "rgba(0,0,0,0.4)",
                        position: "absolute",
                      }}
                    >
                      <Loader />
                    </div>
                  )}
                  <img src={user.avatar} alt="user photo" />
                  <label className="photo-upload-label">
                    <UploadPhotoIcon />
                    <input
                      type="file"
                      accept={"image/jpeg"}
                      onInput={(e) => this.uploadFile(e)}
                    />
                  </label>
                  {user.avatar !== null && (
                    <button
                      className="photo-remove-label"
                      onClick={(e) => handleRemoveAvatar(e)}
                    >
                      X
                    </button>
                  )}
                </div>
              ) : (
                <div className="user-photo">
                  {loadImage && (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        background: "rgba(0,0,0,0.4)",
                        position: "absolute",
                      }}
                    >
                      <Loader />
                    </div>
                  )}
                  <label className="photo-upload-label">
                    <UploadPhotoIcon />
                    <input
                      type="file"
                      accept={"image/jpeg"}
                      onInput={(e) => this.uploadFile(e)}
                    />
                  </label>
                </div>
              )}
              <p className="user-info">Անձնական տվյալներ</p>
              <div className="account-info-content">
                <div className="account-info">
                  <span>Անուն</span>
                  <TextInput
                    contentClassName="user-input input-wrapper"
                    className={""}
                    hasError={errors["firstName"]}
                    placeholder="Անուն *"
                    onChange={handleChange.bind(this, "firstName")}
                    value={fields["firstName"] || ""}
                  />
                </div>
                <div className="account-info">
                  <span>Ազգանուն</span>
                  <TextInput
                    contentClassName="user-input input-wrapper"
                    className={""}
                    hasError={errors["lastName"]}
                    placeholder="Ազգանուն *"
                    onChange={handleChange.bind(this, "lastName")}
                    value={fields["lastName"] || ""}
                  />
                </div>
                <div className="account-info">
                  <span>Հեռախոսահամար</span>
                  <TextInput
                    contentClassName="user-input input-wrapper"
                    className={""}
                    hasError={errors["phone"]}
                    placeholder="Հեռախոսահամար *"
                    type="number"
                    onChange={handleChange.bind(this, "phone")}
                    value={fields["phone"] || ""}
                  />
                </div>
                <div className="user-form-btns">
                  <Button
                    className="user-form-btn link"
                    action={() => {
                      this.setState({ editAccountInfo: false })
                    }}
                    text="Չեղարկել"
                  />
                  <Button
                    className="user-form-btn save"
                    action={contactSubmit.bind(this)}
                    text="Պահպանել"
                  />
                </div>
              </div>
            </form>
            {loading && <Loader />}
            {showError && (
              <ErrorAlert
                text={apiErrorMsg}
                close={() => this.setState({ showError: false })}
              />
            )}
          </>
        ) : (
          <>
            <div className="user-photo">
              {user.avatar !== null ? (
                <div className="user-photo uploaded">
                  <img
                    src={user.avatar}
                    alt={user.firstName + " " + user.lastName}
                  />
                </div>
              ) : (
                <div className="user-photo" />
              )}
            </div>
            <p className="user-info">Անձնական տվյալներ</p>
            <div className="user-description">
              <p className="user-name">
                {user.firstName + " " + user.lastName}
              </p>
              <p className="user-role">
                {
                  specialityOptions.find((x) => x.text === user.speciality.text)
                    ?.label
                }
              </p>
              <a className="user-contacts" href="tel:+37494123456">
                <PhoneNumberIcon />
                {user.phone}
              </a>
              <a
                className="user-contacts"
                href="mailto:edgarsimonyanash@gmail.com"
              >
                <EmailIcon />
                {user.email}
              </a>
              <div className="user-form-btns">
                <Button
                  className="user-form-btn"
                  action={() => this.setState({ editAccountInfo: true })}
                  text="Խմբագրել"
                />
              </div>
            </div>
          </>
        )}
      </div>
    )
  }
}

const mapStateTopProps = (state) => ({
  user: getUserData(state),
})

const mapDispatchToProps = {
  getUserFromAPI,
}

export default connect(mapStateTopProps, mapDispatchToProps)(LeftSideBar)
