export const checkValidationErrors = (fields) => {
  let errors = {};
  let hasError = false;

  //Password
  if (!fields["token"]) {
    hasError = true;
    errors["token"] = "Այս դաշտը պարտադիր է։ Խնդրում ենք լրացնել։";
  }

  //Password
  if (!fields["password"]) {
    hasError = true;
    errors["password"] = "Այս դաշտը պարտադիր է։ Խնդրում ենք լրացնել։";
  }

  if (fields["password"]?.length < 6) {
    hasError = true;
    errors["password"] =
      "Խնդրում ենք մուտքագրել վավեր գաղտնաբառ։ Ձեր գաղտնաբառը պետք է լինի առնվազն 6 նիշ" +
      " երկարությամբ:";
  }

  //CPassword
  if (!fields["cPassword"]) {
    hasError = true;
    errors["cPassword"] = "Այս դաշտը պարտադիր է։ Խնդրում ենք լրացնել։";
  }

  if (fields["password"] !== fields["cPassword"]) {
    hasError = true;
    errors["cPassword"] = "Խնդրում ենք մուտքագրել վավեր գաղտնաբառ։";
  }

  return hasError ? errors : null;
};
