import React from 'react'

const Plus = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <path
                d="M0,0H12"
                transform="translate(6 12)"
                fill="none"
                stroke="#292d32"
                strokeWidth="1.5"
                strokeDasharray="0 0"
            />
            <path
                d="M0,12V0"
                transform="translate(12 6)"
                fill="none"
                stroke="#292d32"
                strokeWidth="1.5"
                strokeDasharray="0 0"
            />
            <path d="M24,0V24H0V0Z" fill="none" opacity="0" />
        </svg>
    )
}
export default Plus
