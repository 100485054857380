import { getLessonsAPI } from "../../service/lessons";
import { SET_LESSONS, LOADING_LESSONS } from "../constants";

export const loadingLessons = () => ({
  type: LOADING_LESSONS,
});

export const setLessons = (topics) => ({
  type: SET_LESSONS,
  payload: topics,
});

export const getLessonsFromAPI = (url, body) => (dispatch, getState) => {
  dispatch(loadingLessons());
  const state = getState();

  getLessonsAPI(url, body, state).then((lessons) => {
    dispatch(setLessons(lessons));
  });
};
