import React from "react";

const SuccessCheck = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="68"
      height="68"
      viewBox="0 0 68 68"
    >
      <g transform="translate(-1086.654 -831.655)">
        <circle
          cx="34"
          cy="34"
          r="34"
          transform="translate(1086.654 831.655)"
          fill="#f3bfda"
        />
        <path
          d="M-4069.654-2353.6c.64-.643,1.2-1.207,1.761-1.768l13.5-13.5c.547-.547.852-.551,1.389-.014q2.013,2.01,4.022,4.023c.491.492.488.841-.011,1.34q-9.965,9.968-19.931,19.935c-.531.531-.851.527-1.39-.013l-10.874-10.876c-.524-.525-.527-.85-.013-1.365q2.024-2.026,4.048-4.05c.483-.483.824-.482,1.314.008q2.96,2.958,5.916,5.919A3.687,3.687,0,0,1-4069.654-2353.6Z"
          transform="translate(5185.963 3222.252)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
export default SuccessCheck;
