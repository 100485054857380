import React, { Component } from "react";
import Account from "./../account";
import { Link } from "react-router-dom";
import ErrorAlert from "../../components/errorAlert";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/UI/Loader";
import { getErrorMessage, HandleToken } from "../../helpers";
import { checkValidationErrors } from "./form-validation";
import { TextInput } from "../../components/UI/TextInput";
import { Button } from "../../components/UI/Button";
import { connect } from "react-redux";
import { getUserFromAPI, setUser } from "../../store/actions/user";
import { loginUser, navigateToAccountPage, saveUserToken } from "./utils";
import { loginURI } from "../../shared/mockData";
import { isUserLoggedIn } from "../../store/selectors";

export const withNavigation = (Component: Component) => {
  return (props) => <Component {...props} navigate={useNavigate()} />;
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      fields: {},
      errors: {},
      apiErrorMsg: "",
      showError: false,
    };
  }

  async componentDidMount() {
    if (this.props.isLoggedIn) {
      window.location.href = "/account-page";
    }
  }

  contactSubmit(e) {
    this.setState({ loading: true });
    e.preventDefault();
    const errors = checkValidationErrors(this.state.fields);
    if (errors) {
      this.setState({ loading: false, errors });
      console.log("Form has errors.");
    } else {
      this.signIn();
    }
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  signIn = () => {
    const body = {
      email: this.state.fields["email"],
      password: this.state.fields["password"],
    };
    const errorState = {
      loading: false,
      showError: true,
    };
    loginUser(loginURI, body)
      .then((res) => {
        if (res.data.accepted === false) {
          return Promise.reject(res.data.errorMessages);
        }
        return res.data;
      })
      .then(saveUserToken)
      .then(this.props.getUserFromAPI)
      .then(() => navigateToAccountPage(this.props.navigate))
      .catch((error) => {
        errorState.apiErrorMsg = getErrorMessage(error);
        this.setState(errorState);
      });
  };

  render() {
    const { errors, fields, loading, showError, apiErrorMsg } = this.state;
    const { handleChange, contactSubmit, setState } = this;

    return (
      <Account>
        <div className="account-content">
          <h2 className="account-subtitle">
            Ճանաչել զիմաստութիւն եւ զխրատ, իմանալ զբանս հանճարոյ
          </h2>
          <form className="account-form" onSubmit={contactSubmit.bind(this)}>
            <TextInput
              hasError={errors["email"]}
              placeholder="մուտքանուն"
              onChange={handleChange.bind(this, "email")}
              value={fields["email"] || ""}
            />
            <TextInput
              hasError={errors["password"]}
              placeholder="գաղտնաբառ"
              type={"password"}
              onChange={handleChange.bind(this, "password")}
              value={fields["password"] || ""}
            />
            <Button text={"մուտք"} action={contactSubmit.bind(this)} />
          </form>
          {loading && <Loader />}

          {showError && (
            <ErrorAlert
              text={apiErrorMsg}
              close={() => setState({ showError: false })}
            />
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginBottom: "30px",
            }}
          >
            <span style={{ color: "#fff" }}>Մոռացե՞լ եք գաղտնաբառը։</span>{" "}
            <Link className="reg" to="/forgot">
              Վերականգնել
            </Link>
          </div>
          <Link className="reg" to="/signup">
            գրանցվել
          </Link>
        </div>
      </Account>
    );
  }
}

const mapStateTopProps = (state) => ({
  isLoggedIn: isUserLoggedIn(state),
});
const mapDispatchToProps = {
  getUserFromAPI,
  setUser,
};

export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withNavigation(Login));
