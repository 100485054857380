import { SET_CATEGORIES, SET_FILTERED_CATEGORIES } from "../constants"
import {
  addArticlesToCategories,
  filterCategories,
  getCategoriesAPI,
} from "../../service/categories"
import { getCategoriesAPIParams } from "../../shared/mockData"

export const setCategories = (categories) => ({
  type: SET_CATEGORIES,
  payload: categories,
})

export const setFilteredCategories = (filteredCategories) => ({
  type: SET_FILTERED_CATEGORIES,
  payload: filteredCategories,
})

export const getCategoriesFromAPI = () => (dispatch) => {
  const { url, body } = getCategoriesAPIParams()

  getCategoriesAPI(url, body).then((categories) => {
    dispatch(setCategories(categories))
  })
}

const _toggleCategory = (categories, categoryId) => {
  const categoryIndex = categories.findIndex((x) => x.id === categoryId)

  categories[categoryIndex].show = !categories[categoryIndex].show
  categories[categoryIndex].subCategories.forEach((item) => {
    item.show = categories[categoryIndex].show
  })
}

const _toggleSubCategory = (categories, categoryId, subCategoryId) => {
  const categoryIndex = categories.findIndex((x) => x.id === categoryId)

  const subCategoryIndex = categories[categoryIndex].subCategories.findIndex(
    (x) => x.id === subCategoryId
  )

  categories[categoryIndex].subCategories[subCategoryIndex].show =
    !categories[categoryIndex].subCategories[subCategoryIndex].show

  const falseCount = categories[categoryIndex].subCategories.filter(
    (x) => x.show === false
  )

  categories[categoryIndex].show =
    falseCount.length !== categories[categoryIndex].subCategories.length
}

export const updateCategoriesToggle =
  (categoryId, subCategoryId, type) => (dispatch, getState) => {
    const state = getState()
    const categories = [...state.filteredCategories.data]

    if (type === "category") {
      _toggleCategory(categories, categoryId)
    }

    if (type === "subCategory") {
      _toggleSubCategory(categories, categoryId, subCategoryId)
    }
    dispatch(setFilteredCategories(categories))
  }

export const getFilteredCategoriesFromAPI =
  (data, categoryIds, type) => (dispatch, getState) => {
    const categories = getState().categories.data
    let articles = []

    if (type === "lesson") {
      articles = data.articles
    }
    if (type === "topic") {
      const lesson = data.lessons
      lesson.forEach((item) => {
        item.articles.forEach((article) => {
          articles.push(article)
        })
      })
    }
    if (type === "article") {
      articles = [data]
    }

    filterCategories(categoryIds, categories)
      .then((categories) => addArticlesToCategories(categories, articles))
      .then((filteredCategories) => {
        dispatch(setFilteredCategories(filteredCategories))
      })
  }
