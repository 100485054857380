import ErrorMsg from "../errorMsg";
import React, {useState} from "react";

export const TextInput = ({
  contentClassName = "input-wrapper",
  className = "account-input",
  type = "text",
  placeholder,
  onChange,
  value,
  hasError,
  profileZone
}) => {
  const [inputType, setInputType] = useState(true);

  const passwordIcon = !inputType
      ? require("../../assets/imgs/icons/password-show-icon.png")
      : require("../../assets/imgs/icons/password-hide-icon.png");

  return (
    <div className={`${contentClassName} ${hasError ? "error" : ""}`}>
      <input
        className={className}
        type={inputType ? type : "text"}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
      {hasError ? (
        <>
          <button className="error-btn" type="button" />
          <ErrorMsg text={hasError} />
        </>
      ) : null}
      {type=== 'password' && (
          <div
              className={
                profileZone
                    ? "profile_password_icon_container"
                    : "password_icon_container"
              }
              onClick={() =>  setInputType(!inputType)}
          >
            <img src={passwordIcon} width="100%" alt="" />
          </div>
      )}
    </div>
  );
};
