import { getRegionsAPI } from "../../service/regions";
import { SET_REGIONS, LOADING_REGIONS } from "../constants";
import { constructSelectedItemApiParams } from "../../pages/accountPage/utils";

export const loadingRegions = () => ({
  type: LOADING_REGIONS,
});

export const setRegions = (regions) => ({
  type: SET_REGIONS,
  payload: regions,
});

export const getRegionsFromAPI = () => (dispatch, getState) => {
  const state = getState();
  const { url, body } = constructSelectedItemApiParams(
    "region",
    state.user.data
  );
  dispatch(loadingRegions());

  getRegionsAPI(url, body).then((regions) => {
    dispatch(setRegions(regions));
  });
};
