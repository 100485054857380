import { CHANGE_MAP_ZOOM } from '../constants'

const initialState = {
    data: 1,
}

export const zoomReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_MAP_ZOOM:
            return {
                ...state,
                data: action.payload,
            }
        default:
            return state
    }
}
